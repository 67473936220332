import React from 'react'

import ProfileTrainings from 'components/profile/ProfileTrainings'
import ProfileEducations from 'components/profile/ProfileEducations'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  paper: {
    marginTop: 40
  },
})

const ApplicationsRegistration = ({
  documentLink, educations, trainings, foreignEducation, foreignNumber
}) => {
  const classes = useStyles()
  return (
    <div className={classes.paper}>
      <ProfileEducations
        educations={educations}
        foreignEducation={foreignEducation}
        foreignNumber={foreignNumber}
      />
      {trainings.length > 0 && (
        <ProfileTrainings trainings={trainings} />
      )}
      {documentLink && (
        <Button
          variant="outlined"
          href={documentLink}
          color="primary"
        >
          Скачать отчет
        </Button>
      )}
    </div>
  )
}

export default ApplicationsRegistration
