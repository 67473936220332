
import React from 'react'

import Paper from '@material-ui/core/Paper'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  checkbox: {
    '& svg': {
      fill: '#c62828'
    }
  },
  title: {
    padding: 24
  },
  subtitle: {
    paddingBottom: 8
  },
  upload: {
    width: 223,
    marginTop: 10
  },
  paper: {
    padding: 40,
    marginTop: 40
  },
  listItem: {
    padding: 8
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30
  },
  documentLink: {
    marginTop: '15px'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  grade: {
    marginBottom: 17,
  }
})

const ApplicationsAuto = ({
  modules, toggleModules, isAdmin, currentStatusId, documentLink, onGradeChange
}) => {
  const classes = useStyles()
  const notApproved = currentStatusId !== 4
  return (
    <Paper elevation={3} className={classes.paper}>
      <Typography
        variant="h5"
        className={classes.subtitle}
      >
        Выбрать модуль базового уровня для зачета:
      </Typography>
      <FormGroup>
        {modules && modules.map(item => (
          <div className={classes.wrapper}>
            <FormControlLabel
              key={item.id}
              control={(
                <Checkbox
                  color="primary"
                  className={classes.checkbox}
                  onChange={e => (isAdmin && notApproved) && toggleModules(e, item.id)}
                  checked={item.checked}
                />
              )}
              label={item.name}
              className={classes.listItem}
            />
            <TextField
              label="Оценка"
              className={classes.grade}
              InputProps={{
                readOnly: !item.checked || !notApproved,
              }}
              InputLabelProps={{
                shrink: item.checked,
                focused: !item.checked
              }}
              value={item.checked ? item.grade : null}
              onChange={e => (isAdmin && notApproved) && onGradeChange(e, item.id)}
            />
          </div>
        ))}
      </FormGroup>
      {documentLink && (
        <Button
          variant="outlined"
          href={documentLink}
          color="primary"
        >
          Скачать документ
        </Button>
      )}
    </Paper>
  )
}

export default ApplicationsAuto
