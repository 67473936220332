import React from 'react'

import { useQueryWithStore, Loading, Error } from 'react-admin'

import getById from 'utils/getById'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Grid from "@material-ui/core/Grid";
import CustomTextField from "../../common/MuiCustomTextField";
import Card from "@material-ui/core/Card";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  mt2: {
    marginTop: '15px'
  },
  card: {
    marginTop: '15px',
    padding: '15px 20px'
  }
})

const ApplicationsСertificate = ({ info, selectedModules }) => {
  const classes = useStyles()

  const { loaded, error, data: modules } = useQueryWithStore({
    type: 'getList',
    resource: 'modules',
  })

  if (!loaded) { return <Loading /> }
  if (error) { return <Error /> }

  return (
      <>
        <Card className={classes.card}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <CustomTextField
                  value={info.postCode}
                  label="Почтовый индекс"
                  fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <CustomTextField
                  value={info.address}
                  label="Почтовый адрес"
                  fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                  value={info.comment}
                  label="Дополнительная информация"
                  fullWidth
              />
            </Grid>
          </Grid>
          {info.level1All === 1 && (
              <div>
                <FormControlLabel
                    label="Свидетельство о сдаче базового уровня"
                    control={(
                        <Checkbox
                            disabled
                            color="primary"
                            checked={true}
                        />
                    )}
                />
              </div>
          )}
          {info.level2All === 1 && (
              <div>
                <FormControlLabel
                    label="Свидетельство о сдаче основного уровня"
                    control={(
                        <Checkbox
                            disabled
                            color="primary"
                            checked={true}
                        />
                    )}
                />
              </div>
          )}
        </Card>
        <TableContainer component={Paper} className={classes.mt2}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Модуль</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedModules && selectedModules.map(item => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                      {item.name}
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
  )
}

export default ApplicationsСertificate
