import React from 'react'
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  useRedirect,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import {
  CustomShowButton,
  Empty,
  FullName,
  PageSubtitle,
  Status,
  FormattedDateField
} from 'common'

const useStyles = makeStyles({
  row: {
    height: 60,
  },
  subtitle: {
    marginTop: 26,
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    height: 44,
    marginTop: 26,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  }
})

const UsersTable = ({ filter, RAListProps, isManagerTraining }) => {
  const classes = useStyles()
  const redirectTo = useRedirect()

  return (
    <>
      <div className={classes.wrap}>
        <PageSubtitle title="Список пользователей" className={classes.subtitle} />
        <Button
          className={classes.button}
          color="primary"
          onClick={() => redirectTo('/users')}
        >
          Показать всех
        </Button>
      </div>
      <List
        {...RAListProps}
        actions={null}
        basePath="users"
        bulkActionButtons={false}
        empty={<Empty title="Пользователи отсутствуют" />}
        filter={filter}
        hasCreate
        hasEdit={false}
        hasList={false}
        hasShow={false}
        pagination={null}
        perPage={5}
        resource="users"
        title=" "
      >
        <Datagrid classes={{ row: classes.row }}>
          <TextField
            label="Рег. №"
            sortBy="regNumber"
            source="applicant.regNumber"
          />
          <FunctionField
            label="ФИО"
            render={record => <FullName data={record} />}
            sortBy="surname"
          />
          <TextField
            source="email"
            label="Электронная почта"
            sortBy="email"
          />
          <FunctionField
            label="Зарегистрирован"
            sortBy="createdAt"
            render={({ createdAt }) => <FormattedDateField date={createdAt} formatting="dd MMMM yyyy" />}
          />
          <FunctionField
            label="Последняя активность"
            sortBy="updatedAt"
            render={({ createdAt }) => <FormattedDateField date={createdAt} formatting="dd MMMM yyyy" />}
          />
          <FunctionField
            label="Статус пользователя"
            render={record => <Status status={record.status} />}
            sortBy="status"
          />
          {isManagerTraining && (
            <FunctionField
              render={({ id: userId }) => (
                <Link
                  className={classes.link}
                  to={{
                    pathname: '/record-book',
                    state: { userId }
                  }}
                >
                  Зачетная книжка
                </Link>
              )}
            />
          )}
          <FunctionField
            label=""
            render={record => <CustomShowButton {...record} />}
          />
        </Datagrid>
      </List>
    </>
  )
}


export default UsersTable
