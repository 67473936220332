import React from 'react'
import { TextInput, useDataProvider, useNotify } from 'react-admin'
import { Form } from 'react-final-form'
import * as Yup from 'yup'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import validate from 'utils/validate'

const TrainingManagerActionButtons = ({ setIsOpenDialog, handleCloseDialog }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const onSubmit = async email => {
    try {
      await dataProvider.create('applicant/invite', {
        data: email
      })
      notify('Пользователю отправлено приглашение на указанную почту')
    } catch (error) {
      notify(`Ошибка: ${error.message}`, 'warning')
    }
    setIsOpenDialog(false)
  }

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Введите корректный e-mail')
      .required('Обязательное поле'),
  })

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => validate(values, schema)}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
        >
          <TextInput
            fullWidth
            label="Электронный адрес"
            source="email"
          />
          <DialogActions>
            <Button color="primary" onClick={handleCloseDialog}>
              Отменить
            </Button>
            <Button color="primary" onClick={handleSubmit}>
              Отправить
            </Button>
          </DialogActions>
        </form>
      )}
    />
  )
}

export default TrainingManagerActionButtons
