import React, { useState, useEffect } from 'react'

import { useDataProvider } from 'react-admin'

import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Checkbox from '@material-ui/core/Checkbox'

const headCells = [
  { id: 'checkbox', label: '' },
  { id: 'number', label: '', sortable: false },
  { id: 'search', label: 'ФИО', sortable: true },
  { id: 'regNumber', label: 'Рег. номер', sortable: true },
  { id: 'moduleId', label: 'Модуль', sortable: true },
  { id: 'status', label: 'Статус', sortable: true },
  { id: 'grade', label: 'Оценка', sortable: false },
  { id: 'button', label: '', sortable: false }
]

const getStatus = status => {
  let text

  let color

  switch (status) {
    case 'exam':
      text = 'На экзамене'
      color = 'rgba(137, 196, 244, 0.5)'
      break
    case 'late':
      text = 'Опоздал'
      color = 'rgba(254, 241, 96, 0.5)'
      break
    case 'not-appear':
      text = 'Не явился'
      color = 'rgba(190, 144, 212, 0.5)'
      break
    case 'complete':
      text = 'Экзамен завершен'
      color = 'rgba(49, 211, 54, 0.5)'
      break
    case 'fail':
      text = 'Нарушение'
      color = 'rgba(240, 52, 52, 0.5)'
      break
    case 'tech-fail':
      text = 'Технический сбой'
      color = 'rgba(240, 52, 52, 0.5)'
      break
    default:
      text = 'Не определен'
      color = 'rgba(192, 192, 192, 0.5)'
      break
  }

  return <Chip label={text} style={{ backgroundColor: color }} />
}

const useStyles = makeStyles({
  name: {
    fontWeight: 600
  }
})

const GroupApplicants = ({ groupId, aplicantsList, setAplicantsList }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('search')
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [applicants, setApplicants] = useState([])
  const [totalApplicants, setTotal] = useState(0)

  const requestApplicants = async () => {
    const { data, total } = await dataProvider.getList(`exam-groups/${groupId}/members`)
    setApplicants(data)
    setTotal(total)
  }

  const requestApplicantsSort = async (perPage = 100, page = 0, order, property) => {
    await dataProvider.getOne(
      `exam-groups/${groupId}/members?per-page=${perPage}&page=${page + 1}&sort=${order === 'desc' ? '' : '-'}${property}`
    ).then(({ data }) => setApplicants(data.models))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    requestApplicantsSort(perPage, newPage, order, orderBy)
  }

  const handleChangeRowsPerPage = event => {
    setPerPage(event.target.value)
    setOrder('desc')
    setOrderBy('surname')
    setPage(0)
    requestApplicantsSort(event.target.value, page, 'desc', event.target.value)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    requestApplicantsSort(perPage, page, isAsc ? 'desc' : 'asc', property)
  }


  useEffect(() => {
    requestApplicants()
  }, [])

  const selectApplications = (e, id) => {
    const { checked } = e.target
    if (checked) {
      setAplicantsList([id, ...aplicantsList])
    } else {
      const currentApplicationsList = aplicantsList.filter(apId => apId !== id)
      setAplicantsList(currentApplicationsList)
    }
  }

  const selectApplicationsAll = e => {
    const { checked } = e.target;

    if (checked) {
      const newSelecteds = applicants.map(applicant => applicant.id);

      setAplicantsList(newSelecteds);
    } else {
      setAplicantsList([]);
    }
  }

  const isSelected = id => aplicantsList.includes(id);

  const EnhancedTableHead = ({ numSelected, rowCount, handleSelectAll }) => {
    const createSortHandler = property => event => {
      handleRequestSort(event, property)
    }

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            !headCell.sortable ? (
              headCell.id === 'checkbox' ? (
                <TableCell
                  key={headCell.id}
                  padding="checkbox"
                >
                  <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={handleSelectAll}
                  />
                </TableCell>
              ) : (
                <TableCell key={headCell.id}>{headCell.label}</TableCell>
              )
            ) : (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            )
          ))}
        </TableRow>
      </TableHead>
    )
  }

  const getRows = () => applicants && applicants.map(({
    id, applicant, status, module, mark, application
  }, index) => {
    const { regNumber, user, id: appId } = applicant
    const isChecked = isSelected(id)

    return (
      <TableRow key={id}>
        <TableCell padding="checkbox">
          <Checkbox
            onChange={e => {
              selectApplications(e, id)
            }}
            checked={isChecked}
          />
        </TableCell>
        <TableCell>{index + 1}</TableCell>
        <TableCell className={classes.name}>{`${user.surname} ${user.name} ${user.middleName}`}</TableCell>
        <TableCell>{regNumber}</TableCell>
        <TableCell>{module.name}</TableCell>
        <TableCell>{getStatus(status)}</TableCell>
        <TableCell>{mark}</TableCell>
        <TableCell>
          <Link to={`/user/${appId}`}>
            <InfoOutlinedIcon style={{ fill: '#c62828' }} />
          </Link>
        </TableCell>
      </TableRow>
    )
  })


  return (
    <Paper>
      <TableContainer>
        <Table>
          <EnhancedTableHead 
            numSelected={aplicantsList.length}
            rowCount={applicants.length}
            handleSelectAll={selectApplicationsAll}
          />
          <TableBody>
            {getRows()}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={totalApplicants}
        rowsPerPage={perPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default GroupApplicants
