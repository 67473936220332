import React from 'react'

import {
  useQueryWithStore,
  Loading,
  Error,
} from 'react-admin'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import ExamApplication from 'components/record-book/ExamApplication'
import ExamApplicationApply from 'components/record-book/ExamApplicationApply'

const useStyles = makeStyles({
  subtitle: {
    padding: 25
  },
  subtitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20
  },
})

const LevelExamApplication = ({
  name,
  levelId,
  buttonAutopass,
  applicantId,
  isOldRegNumber,
  userId
}) => {
  const classes = useStyles()
  const baseLevel = 1
  let passedExamsList = []

  const { loaded, error, data: applicantInfo } = useQueryWithStore({
    type: 'getList',
    resource: 'applicant/info',
    payload: {
      data: {
        levelId,
        userId: applicantId
      },
    }
  })

  if (levelId === baseLevel) passedExamsList = applicantInfo && applicantInfo.filter(({ status }) => status.type === 'success')

  if (!loaded) return <Loading />
  if (error) return <Error />

  return (
    <>
      <div className={classes.subtitleWrapper}>
        <Typography
          variant="h4"
          className={classes.subtitle}
        >
          {name}
        </Typography>
        {buttonAutopass && (
          <ExamApplicationApply applicantId={applicantId} isOldRegNumber={isOldRegNumber} passedExamsList={passedExamsList} />
        )}
      </div>
      {applicantInfo && applicantInfo.map(({ id, ...otherApplicantInfo }) => (
        <ExamApplication userId={userId} key={id} applicantInfo={otherApplicantInfo} moduleId={id} levelId={levelId} />
      ))}
    </>
  )
}

export default LevelExamApplication
