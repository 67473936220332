import React from 'react'
import NotificationsTable from './NotificationsTable'
import UserInfoManager from './UserInfoManager'
import UsersTable from './UsersTable'

const AdminPage = ({ profileData, RAListProps }) => (
  <>
    <UserInfoManager {...profileData} />
    <NotificationsTable RAListProps={RAListProps} />
    <UsersTable profileData={profileData} RAListProps={RAListProps} />
  </>
)

export default AdminPage
