import React from 'react'
import {
  Filter,
  DateInput,
  SelectInput,
  SelectArrayInput,
  ReferenceInput,
  TextInput,
  AutocompleteInput
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  helperText: {
    display: 'none'
  },
  search: {
    width: 500
  }
})

const GroupsFilter = props => {
  const classes = useStyles()

  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        label="Поиск по названию группы и супервайзеру"
        source="search"
        className={classes.search}
        resettable
      />
      <ReferenceInput label="Уровень" source="levelId" reference="exam-levels">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Модуль"
        source="modules"
        reference="modules"
        allowEmpty
        FormHelperTextProps={{ classes: { root: classes.helperText } }}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceInput>
      <DateInput source="examDateFrom" label="Дата экзамена с" />
      <DateInput source="examDateTo" label="Дата экзамена по" />
      <ReferenceInput label="Город" source="cityId" reference="cities" filterToQuery={searchText => ({ name: searchText })}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Помещение" source="locationId" reference="locations">
        <SelectInput optionText="place" resettable />
      </ReferenceInput>
    </Filter>
  )
}

export default GroupsFilter
