import React, { useState } from 'react'
import * as Yup from 'yup'
import CustomTextField from 'common/MuiCustomTextField'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import { Form, Field } from 'react-final-form'
import validate from 'utils/validate'
import { useMutation, useNotify } from 'react-admin'
import Tooltip from '@material-ui/core/Tooltip'
import { MaskedPhoneInput } from 'common'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.09)'
    }
  },
  done: {
    fill: 'green'
  },
  cancel: {
    fill: 'red'
  },
  buttonsWrapper: {
    marginLeft: 20
  }
})


const PhoneEdit = ({ phone }) => {
  const classes = useStyles()

  const [editActive, toggleEditActive] = useState(false)
  const [phoneValue, setPhoneValue] = useState(phone)
  const schema = Yup.object().shape({
    phone: Yup.string()
      .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11}(\s*)?$/, 'Номер телефона должен состоять из 10 цифр')
  })

  const [mutate, { loading }] = useMutation()
  const notify = useNotify()

  const onSubmit = value => {
    const { newPhone } = value

    mutate({
      type: 'update',
      resource: 'applicant/update',
      payload: { data: value }
    },
      {
        onSuccess: () => {
          notify('Номер телефона успешно изменен')
          setPhoneValue(newPhone)
          toggleEditActive(false)
        },
        onFailure: error => notify(`Ошибка: ${error.message}`, 'warning'),
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => validate(values, schema)}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="phone"
            defaultValue={editActive ? '' : phoneValue}
          >
            {({ meta: { error, touched }, input }) => (
              <CustomTextField
                {...input}
                error={!!(touched && error) && editActive}
                helperText={touched && error}
                onChange={value => input.onChange(value)}
                label="Телефон"
                className={classes.phone}
                InputProps={{
                  disableUnderline: !editActive,
                  readOnly: !editActive,
                  classes: { root: classes.root },
                  inputComponent: MaskedPhoneInput,
                  endAdornment:
                    <InputAdornment position="end">
                      {editActive ? (
                        <div className={classes.buttonsWrapper}>
                          <Tooltip title="Изменить номер телефона">
                            <IconButton type="submit" disabled={loading}>
                              <DoneIcon className={classes.done} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Отмена">
                            <IconButton onClick={() => toggleEditActive(false)}>
                              <ClearIcon className={classes.cancel} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                          <Tooltip title="Редактировать">
                            <IconButton onClick={() => toggleEditActive(true)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                    </InputAdornment>
                }}
              />
            )}
          </Field>
        </form>
      )}
    />
  )
}

export default PhoneEdit
