import React from 'react'
import { useRedirect } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { MuiCustomTextField, PageSubtitle } from 'common'
import getFullName from 'utils/getFullName'

const useStyles = makeStyles({
  paper: {
    padding: 24
  },
  field: {
    display: 'block',
    width: 283,
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const UserInfoApplicant = ({
  applicant: { regNumber },
  email,
  middleName,
  name,
  phone,
  surname,
}) => {
  const classes = useStyles()
  const redirectTo = useRedirect()

  return (
    <>
      <div className={classes.wrap}>
        <PageSubtitle title="Пользователь" />
        <Button
          className={classes.button}
          color="primary"
          onClick={() => redirectTo('/profile')}
        >
          Перейти в профиль
        </Button>
      </div>

      <Paper className={classes.paper}>
        <MuiCustomTextField
          className={classes.field}
          label="ФИО"
          value={getFullName(surname, name, middleName)}
        />
        <MuiCustomTextField
          className={classes.field}
          label="Регистрационный номер"
          value={regNumber}
        />
        <MuiCustomTextField
          className={classes.field}
          label="Электронная почта"
          value={email}
        />
        <MuiCustomTextField
          className={classes.field}
          label="Телефон"
          value={phone}
        />
      </Paper>
    </>
  )
}

export default UserInfoApplicant
