import React from 'react'
import NotificationsTable from './NotificationsTable'
import RecentApplicationsTable from './RecentApplicationsTable'
import UserInfoApplicant from './UserInfoApplicant'

const UserPage = ({ profileData, RAListProps }) => {
  const { applicant: { id: applicantId }, id: userId } = profileData

  return (
    <>
      <UserInfoApplicant {...profileData} />
      <RecentApplicationsTable filter={{ applicantId }} RAListProps={RAListProps} />
      <NotificationsTable filter={{ userId }} RAListProps={RAListProps} />
    </>
  )
}

export default UserPage
