import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {
  ReferenceInput,
  SelectInput,
  TextInput
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ButtonBlock from './ButtonBlock'

const useStyles = makeStyles({
  title: {
    paddingBottom: 24
  },
  fieldLong: {
    display: 'block'
  },
  addButton: {
    marginTop: 25
  },
  other: {
    display: 'block'
  }
})

const FormEditTrainings = ({ setIsEditable, trainings, onSubmit }) => {
  const classes = useStyles()

  return (
    <>
      <Form
        initialValues={{ trainings }}
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators
        }}
        render={({
          handleSubmit,
          form: {
            mutators: { push }
          },
        }) => (
            <form onSubmit={handleSubmit}>
              <Typography
                variant="h6"
                className={classes.title}
              >
                Редактирование сведений о подготовке к экзамену
            </Typography>

              <FieldArray name="trainings">
                {({ fields }) => fields.map((name, i) => (
                  <div key={name}>
                    <ReferenceInput
                      className={classes.fieldLong}
                      fullWidth
                      name={`${name}.centerId`}
                      label="Наименование учебно методического центра (УМЦ)"
                      reference="training-centers"
                    >
                      <SelectInput />
                    </ReferenceInput>
                    {fields.value[i] && fields.value[i].centerId === 12 && <TextInput className={classes.other} multiline fullWidth label="Другой УМЦ" name={`${name}.other`} />}
                  </div>
                ))}
              </FieldArray>
              <Button
                color="primary"
                onClick={() => push('trainings', undefined)}
                className={classes.addButton}
              >
                Добавить доп. образование
            </Button>
              <ButtonBlock handleClickAccept={handleSubmit} handleClickCancel={() => setIsEditable(false)} />
            </form>
          )}
      />
    </>
  )
}

export default FormEditTrainings
