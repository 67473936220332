
import axios from 'axios'
import { HttpError } from 'react-admin'
import { SERVER_URL } from '../utils/constants'

class Request {
  constructor() {
    this.setToken()
    this.service = axios.create({
      baseURL: SERVER_URL,
    })
  }

  setToken(token) {
    const currentToken = token || localStorage.getItem('token')
    if (currentToken) this.token = currentToken
  }

  removeToken() {
    localStorage.removeItem('userId')
    localStorage.removeItem('token')
    localStorage.removeItem('role')
    localStorage.removeItem('status')
    localStorage.removeItem('applicant')
    localStorage.removeItem('applicantStatus')
    localStorage.removeItem('email')
    this.token = null
  }

  send(method, url, data, headers) {
    let settings = { method, url, responseType: 'json' }
    let commonHeaders = headers || {}

    if (data) {
      settings = { ...settings, data }
    }

    if (this.token) {
      commonHeaders = {
        ...headers,
        Authorization: `Bearer ${this.token}`,
      }
    }

    return this.service.request({
      ...settings,
      headers: commonHeaders,
    }).catch(error => {
      const {
        response: {
          status: statusCode
        }
      } = error

      if (statusCode === 401) {
        this.removeToken()
      }

      if (statusCode === 422) {
        throw new HttpError(error.response)
      }
    })
  }
}

export default new Request()
