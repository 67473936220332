import React, { useState } from 'react'
import {
  Create,
  TextInput,
  ReferenceInput,
  RadioButtonGroupInput,
  useDataProvider,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  required,
  number,
  minValue,
  AutocompleteInput
} from 'react-admin'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { PageTitle } from 'common'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

const useStyles = makeStyles({
  paper: {
    padding: 24
  },
  addButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    backgroundColor: 'transparent'
  },
  city: {
    width: 228,
    display: 'flex'
  },
  place: {
    width: '100%'
  },
  address: {
    width: '100%'
  },
  room: {
    width: 200,
    marginRight: 20
  },
  uploads: {
    display: 'flex'
  },
  uploadWrapper: {
    position: 'relative',
    width: '100%',
  },
  image: {
    width: '100%',
    maxHeight: '100%',
  },
  wrapper: {
    display: 'flex',
    '&:first-child': {
      marginBottom: 20,
    }
  },
  infoWrapper: {
    width: '49%',
    marginRight: 20
  },
  imageWrapper: {
    width: '50%'
  },
  radiobuttonsWrapper: {
    width: 221,
    paddingTop: 10,
    paddingBottom: 10
  },
  fileUpload: {
    width: 379
  }
})


const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
}

const AddLocationForm = ({ record, save, version }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()

  const [image, setImage] = useState(false)
  const [file, setFile] = useState(false)

  const choices = [
    { value: 'approve', text: 'Принятое' },
    { value: 'cancel', text: 'Отклоненное' }
  ]

  const saveFile = async (url, upload, name) => {
    const fileObj = {}
    const response = await dataProvider.uploadFile(url, { payload: upload })

    fileObj[name] = response.data
    return fileObj
  }

  const saveData = async values => {
    let allFormValues = {
      ...values
    }
    const promiseList = []

    if (image) promiseList.push(saveFile('upload/image', image, 'image'))
    if (file) promiseList.push(saveFile('upload/file', file, 'dockLink'))

    Promise.all(promiseList).then(results => {
      if (results) {
        results.forEach(item => {
          allFormValues = { ...allFormValues, ...item }
        })
      }
      save(allFormValues)
    }, () => save(allFormValues))
  }

  return (
    <Form
      initialValues={record}
      onSubmit={saveData}
      mutators={{ ...arrayMutators }}
      subscription={defaultSubscription}
      key={version}
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Paper className={classes.paper}>
              <div className={classes.wrapper}>
                <div className={classes.infoWrapper}>
                  <ReferenceInput
                    resource="location"
                    source="cityId"
                    reference="cities"
                    label="Город"
                    className={classes.city}
                    filterToQuery={searchText => ({ name: searchText })}
                  >
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>
                  <TextInput
                    resource="locations"
                    source="place"
                    label="Место проведения"
                    className={classes.place}
                    margin="normal"
                    validate={required()}
                  />
                  <TextInput
                    resource="locations"
                    source="address"
                    label="Адрес"
                    className={classes.address}
                    margin="normal"
                    validate={required()}
                  />
                  <TextInput
                    resource="locations"
                    source="room"
                    label="Аудитория"
                    className={classes.room}
                    margin="normal"
                    validate={required()}
                  />
                  <TextInput
                    resource="locations"
                    source="countPlaces"
                    label="Количество мест"
                    margin="normal"
                    validate={[required(), number(), minValue(1)]}
                  />
                </div>
                <div className={classes.imageWrapper}>
                  <div className={classes.uploadWrapper}>
                    <ImageInput
                      onChange={img => setImage(img)}
                      source="image"
                      label="Изображение карты"
                      accept="image/jpeg,image/png"
                      maxSize={5242880}
                      placeholder={<p>Нажмите, чтобы выбрать изображение</p>}
                    >
                      <ImageField source="src" classes={classes} />
                    </ImageInput>
                  </div>
                </div>
              </div>
              <Divider />
              <div className={classes.wrapper}>
                <div className={classes.radiobuttonsWrapper}>
                  <RadioButtonGroupInput
                    resource="locations"
                    source="status"
                    choices={choices}
                    optionText="text"
                    optionValue="value"
                    label="Статус помещения"
                    defaultValue="cancel"
                    row={false}
                    margin="normal"
                  />
                </div>
              </div>
              <Divider />
              <div className={classes.fileUpload}>
                <div className={classes.uploadWrapper}>
                  <FileInput
                    source="dockLink"
                    onChange={upload => setFile(upload)}
                    label="Отчет"
                    accept=".pdf,.doc,.docx,.xls,.xlsx"
                    placeholder={<p>Нажмите, чтобы выбрать файл</p>}
                    margin="normal"
                  >
                    <FileField source="src" title="title" />
                  </FileInput>
                </div>
              </div>
            </Paper>
            <div className={classes.addButtonWrapper}>
              <Button
                label="Добавить"
                type="submit"
                variant="contained"
                color="primary"
                className={classes.addButton}
              >
                Добавить
              </Button>
            </div>
          </form>
        </>
      )}
    />
  )
}

const AddLocationPage = props => (
  <>
    <PageTitle title="Добавить помещение" />
    <Create
      title=" "
      component="div"
      {...props}
    >
      <AddLocationForm />
    </Create>
  </>
)

export default AddLocationPage
