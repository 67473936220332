import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { FormattedDateField } from 'common'

const headCells = [
  { id: 'checkbox', label: '' },
  { id: 'id', label: '' },
  { id: 'regNumber', label: 'Рег. №' },
  { id: 'surname', label: 'ФИО' },
  { id: 'createdAt', label: 'Зарегистрирован' },
  { id: 'payment', label: 'Статус оплаты' },
]

const useStyles = makeStyles({
  textBold: {
    fontWeight: 'bold',
  },
  text: {
    padding: 20,
  },
})

const ExamMembersTable = ({
  members,
  isMove,
  moveMemberIdList,
  setMoveMemberIdList,
  requestMembersSort,
  totalMembers
}) => {
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('createdAt')
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(1000)


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    requestMembersSort(perPage, page, isAsc ? 'desc' : 'asc', property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    requestMembersSort(perPage, newPage, order, orderBy)
  }

  const handleChangeRowsPerPage = event => {
    setPerPage(event.target.value)
    setOrder('desc')
    setOrderBy('createdAt')
    setPage(0)
    requestMembersSort(event.target.value)
  }

  const moveMembers = (e, id) => {
    const { checked } = e.target
    if (checked) {
      setMoveMemberIdList([...moveMemberIdList, id])
    } else {
      const index = moveMemberIdList.indexOf(id)
      moveMemberIdList.splice(index, 1)
      setMoveMemberIdList([...moveMemberIdList])
    }
  }

  const EnhancedTableHead = () => {
    const createSortHandler = property => event => {
      handleRequestSort(event, property)
    }

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => {
            if (headCell.id === 'checkbox' && !isMove) {
              return null
            }
            return (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }

  const getRowsMembers = () => members && members.map((row, index) => {
    const {
      application: {
        id,
        payment,
        createdAt,
        applicant: {
          regNumber
        }
      },
      name,
      middleName,
      surname,
    } = row

    return (
      <TableRow
        key={id}
      >
        {isMove && (
          <TableCell padding="checkbox">
            <Checkbox
              disabled={!payment}
              color="primary"
              checked={moveMemberIdList.includes(id)}
              onChange={e => moveMembers(e, id)}
            />
          </TableCell>
        )}

        <TableCell>{index + 1}</TableCell>
        <TableCell>{regNumber}</TableCell>
        <TableCell className={classes.textBold}>{`${surname} ${name} ${middleName}`}</TableCell>
        <TableCell>
          <FormattedDateField date={createdAt} formatting="dd.MM.yyyy" />
        </TableCell>
        <TableCell>{payment ? 'Оплачен' : 'Не оплачен'}</TableCell>
      </TableRow>
    )
  })

  return members && members.length ? (
    <>
      <TableContainer>
        <Table>
          <EnhancedTableHead />
          <TableBody>
            {getRowsMembers()}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 1000]}
        component="div"
        count={totalMembers}
        rowsPerPage={perPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  ) : (
      <p className={classes.text}>Список претендентов пуст</p>
    )
}

export default ExamMembersTable
