import React from 'react'
import { useDataProvider, useNotify} from 'react-admin'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as Yup from 'yup'
import validate from 'utils/validate'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { CustomTextInput } from 'common'
import PageSubtitle from 'common/PageSubtitle'

const schema = Yup.object().shape({
  title: Yup.string()
    .required('Укажите тему сообщения'),
  text: Yup.string()
    .required('Введите сообщение'),
})

const useStyles = makeStyles({
  paper: {
    padding: 20
  },
  subtitle: {
    padding: 24
  },
  form: {
    maxWidth: '600px',
    '& .MuiTextField-root': {
      display: 'block',
      marginBottom: 20
    },
    '& .MuiFilledInput-root': {
      display: 'block',
      '&::before': {
        display: 'none'
      }
    },
  },
  button: {
    width: 300
  },
})

const GroupMessageForm = ({ 
  aplicantsList, 
  setAplicantsList,
  loadingSendMessage,
  setLoadingSendMessage
}) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const onSubmit = async values => {
    if (aplicantsList.length === 0) {
      notify('Выберите претендентов', 'warning')

      return { [FORM_ERROR]: 'Претенденты не выбраны' }
    }

    setLoadingSendMessage(true)

    try {
      const data = { ...values, members: aplicantsList }

      await dataProvider.create('exam-groups/send-message', { data })

      setAplicantsList([])
      setLoadingSendMessage(false)
      notify('Сообщение успешно отправлено')
    } catch (error) {
      setLoadingSendMessage(false)
      notify('Упс, что-то пошло не так, повторите запрос.', 'warning')

      return { FORM_ERROR }
    }
  }

  const TextFieldMessage = props => <CustomTextInput multiline rows={5} {...props} />

  return (
    <>
      <PageSubtitle title="Отправить сообщение" className={classes.subtitle} />
      <Paper className={classes.paper}>
        <Form
          onSubmit={onSubmit}
          validate={values => validate(values, schema)}
          render={({ handleSubmit, form, submitSucceeded }) => {
            if (submitSucceeded) {
              form.reset()
            }

            return (
              <form
                onSubmit={handleSubmit}
                className={classes.form}
              >
                <Field
                  name="title"
                  component={CustomTextInput}
                  label="Тема сообщения"
                  variant="filled"
                />

                <Field
                  name="text"
                  component={TextFieldMessage}
                  label="Сообщение"
                  variant="filled"
                />

                <div className={classes.submitBtnWrap}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary"
                    className={classes.button}
                    disabled={loadingSendMessage}
                  >
                    Отправить
                  </Button>
                </div>
              </form>
            )
          }}
        />
      </Paper>
    </>
  )
}

GroupMessageForm.defaultProps = {
  aplicantsList: [],
  loadingSendMessage: [],
  setAplicantsList: () => {},
  setLoadingSendMessage: () => {}
}

export default GroupMessageForm