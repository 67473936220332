import React from 'react'
import PageTitle from 'common/PageTitle'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  ShowButton,
  useAuthenticated,
  CreateButton
} from 'react-admin'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Status } from 'common'

const useStyles = makeStyles({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 25
  },
  infoButton: {
    width: 50,
    paddingRight: 0,
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  tableHeader: {
    paddingTop: 20,
    paddingBottom: 20,
    color: 'rgba(0, 0, 0, 0.56)'
  },
  tableRow: {
    paddingTop: 16,
    paddingBottom: 16,
  }
})

const LocationsPage = props => {
  useAuthenticated()

  const classes = useStyles()
  const role = localStorage.getItem('role')

  return (
    <>
      <PageTitle title="Помещения" />
      <List
        bulkActionButtons={false}
        exporter={false}
        actions={null}
        sort={{ field: 'cityName', order: 'ASC' }}
        title=" "
        {...props}
      >
        <Datagrid classes={{ headerCell: classes.tableHeader, rowCell: classes.tableRow }}>
          <TextField
            source="city.name"
            label="Город"
            sortBy="cityName"
          />
          <TextField
            source="place"
            label="Помещение"
            sortable={false}
          />
          <TextField
            source="address"
            label="Адрес"
            sortable={false}
          />
          <TextField
            source="room"
            label="Аудитория"
            sortable={false}
          />
          <FunctionField
            source="status"
            label="Статус"
            render={record => <Status status={record.status} />}
            sortable={false}
          />
          <ShowButton
            icon={<InfoOutlinedIcon />}
            label=""
            className={classes.infoButton}
            disableRipple
          />
        </Datagrid>
      </List>
      <div className={classes.buttonWrapper}>
        {role !== 'viewer' && (
          <CreateButton
            basePath="locations"
            label="Добавить помещение"
            variant="contained"
          />
        )}
      </div>
    </>
  )
}

export default LocationsPage
