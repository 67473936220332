const statusList = [
  {
    id: 1,
    name: 'Не опубликован'
  },
  {
    id: 2,
    name: 'Опубликован'
  },
  {
    id: 3,
    name: 'Архивный'
  }
]

export default statusList
