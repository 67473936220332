import React from 'react'
import { usePermissions, useQueryWithStore, useRedirect } from 'react-admin'
import CircularProgress from '@material-ui/core/CircularProgress'
import { PageTitle } from 'common'
import ManagerTrainingsPage from '../components/dashboard/ManagerTrainingsPage'
import AdminPage from '../components/dashboard/AdminPage'
import UserPage from '../components/dashboard/UserPage'
import ViewerPage from "../components/dashboard/ViewerPage";

const Dashboard = props => {
  const redirectTo = useRedirect()
  const { permissions, permissions: { applicant } = {} } = usePermissions()
  const { data: profileData } = useQueryWithStore({
    type: 'getOne',
    resource: 'applicant/profile',
  })

  const getPage = role => {
    switch (role) {
      case 'user': {
        if (applicant) {
          return <UserPage profileData={profileData} RAListProps={props} />
        }
        return redirectTo('/applicant-form')
      }
      case 'admin': return <AdminPage profileData={profileData} RAListProps={props} />
      case 'manager': return <AdminPage profileData={profileData} RAListProps={props} />
      case 'managerTraining': return <ManagerTrainingsPage profileData={profileData} RAListProps={props} />
      case 'viewer': return <ViewerPage profileData={profileData} RAListProps={props} />
      default: return null
    }
  }

  if (permissions && profileData) {
    const { role } = permissions

    return (
      <>
        <PageTitle title="Домашняя страница" />
        {getPage(role)}
      </>
    )
  }
  return <CircularProgress />
}

export default Dashboard
