import React, { useState } from 'react'
import { useDataProvider, useRedirect, TextInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Form } from 'react-final-form'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import validate from 'utils/validate'
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography'
import Alert from './Alert'

const useStyles = makeStyles({
  inputField: {
    marginBottom: 20,
    height: 56,
  },
  form: {
    width: 312,
  },
  button: {
    padding: '11px 0',
    height: 44,
    marginTop: 16,
  },
  divider: {
    marginTop: '32px',
    background: 'rgba(0, 0, 0, 0.36)'
  },
  paragraph: {
    marginTop: 20,
    textAlign: 'center'
  },
  link: {
    color: '#2196f3',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
})

const schema = Yup.object().shape({
  password: Yup.string()
    .oneOf([Yup.ref('passwordRepeat'), null], 'Пароли не совпадают')
    .required('Обязательное поле'),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Пароли не совпадают')
    .required('Обязательное поле'),
  email: Yup.string()
    .email('Введите корректный e-mail')
    .required('Обязательное поле'),
})


const RegisterForm = () => {
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const redirectTo = useRedirect()
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const onSubmit = async values => {
    try {
      const { email } = values
      await dataProvider.create('site/registration', { data: values })
      setSuccessMessage(
        'На указанный вами электронный адрес было выслано письмо. Для активации аккаунта перейдите по ссылке, указанной в письме.'
      )
      dataProvider.create('site/send-verify', { data: { email } })
      return setTimeout(() => redirectTo('/login'), 5000)
    } catch ({ message }) {
      if (message.data[0].message) {
        setErrorMessage(message.data[0].message)
      } else {
        setErrorMessage('Упс, что-то пошло не так, повторите запрос.')
      }
    }
  }


  const handleClose = typeMessage => {
    if (typeMessage === 'error') {
      return setErrorMessage('')
    }
    return setSuccessMessage('')
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={values => validate(values, schema)}
        render={({ handleSubmit, invalid }) => (
          <form
            onSubmit={handleSubmit}
            className={classes.form}
          >
            <TextInput
              className={classes.inputField}
              fullWidth
              label="Электронный адрес"
              name="email"
              source="email"
            />
            <TextInput
              className={classes.inputField}
              fullWidth
              label="Пароль"
              name="password"
              source="password"
              type="password"
            />
            <TextInput
              name="passwordRepeat"
              className={classes.inputField}
              fullWidth
              label="Повторите пароль"
              source="passwordRepeat"
              type="password"
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              color="primary"
              className={classes.button}
              disabled={invalid}
            >
              Зарегистрироваться
            </Button>
            <Divider className={classes.divider} variant="middle" />
            <Typography className={classes.paragraph}>
              Есть аккаунт?
              &nbsp;
              <span className={classes.link} onClick={() => redirectTo('/login')}>Войти</span>
            </Typography>

          </form>
        )}
      />
      {errorMessage && <Alert type="error" message={errorMessage} handleClose={handleClose} />}
      {successMessage && <Alert type="info" message={successMessage} handleClose={handleClose} duration={20000} />}
    </>
  )
}

export default RegisterForm
