import React, { Fragment } from 'react'

import {
  Link
} from 'react-router-dom'

import {
  useRedirect, usePermissions, useQuery, useAuthenticated
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { PageTitle, PageSubtitle } from 'common'
import ExamScheduleTable from '../components/exam-schedule/ExamScheduleTable'

const useStyles = makeStyles({
  subtitle: {
    paddingTop: 24,
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    height: 44,
    marginTop: 30,
  },
  buttonViewAll: {
    height: 44,
    marginTop: 24,
  },
  link: {
    textDecoration: 'none',
    color: '#c62828'
  }
})

const ExamSchedulePage = () => {
  useAuthenticated()
  const classes = useStyles()
  const redirectTo = useRedirect()
  const { permissions: { role } = {} } = usePermissions()

  const { data } = useQuery({
    type: 'getList',
    resource: 'exam-levels'
  })

  const isAdmin = role === 'admin' || role === 'manager'
  const isUser = role === 'user'
  const statusId = isAdmin ? '' : ',"statusId": "2"'

  const handleCreateExam = () => redirectTo('/exam-creation')

  const ButtonViewAll = ({ levelId }) => (
    <Link
      className={classes.link}
      to={{
        pathname: '/groups',
        search: `filter={"levelId": "${levelId}"${statusId}}`,
      }}
    >
      <Button
        color="primary"
        className={classes.buttonViewAll}
      >
        Показать все
      </Button>
    </Link>
  )

  const getTables = () => (
    data && data.map(el => {
      const { id, name } = el
      return (
        <Fragment key={id}>
          <div className={classes.wrap}>
            <PageSubtitle title={name} className={classes.subtitle} />
            <ButtonViewAll levelId={id} />
          </div>
          <ExamScheduleTable levelId={id} perPage={5} />
        </Fragment>
      )
    })
  )

  return (
    <>
      <div className={classes.wrap}>
        <PageTitle title="Расписание экзаменов" />
        {isAdmin && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={handleCreateExam}
          >
            Создать новый экзамен
          </Button>
        )}
      </div>
      {getTables()}
    </>
  )
}

export default ExamSchedulePage
