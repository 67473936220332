import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import { makeStyles } from '@material-ui/core/styles'
import PetsIcon from '@material-ui/icons/Pets'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles({
  icon: {
    display: 'block',
    margin: '0 auto',
    marginBottom: 5
  }
})

const ValidationErrorPopup = ({ open, onClose }) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <PetsIcon className={classes.icon} />
        <Typography
          variant="h6"
          align="center"
        >
          Создание группы возможно только из экзаменов с одинаковыми полями даты, времени начала, места, уровня, помещения, города.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={onClose}
        >
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ValidationErrorPopup
