import React, { useState, useEffect } from 'react'
import { useInput } from 'react-admin'
import { format } from 'date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import RuLocalizedUtils from 'utils/dateFnsLocalization'
import ruLocale from 'date-fns/locale/ru'

const useStyles = makeStyles({
  field: {
    marginTop: 8,
    marginBottom: 4,
    '& > label': {
      top: -3,
    }
  },
  input: {
    height: 48,
  }
})

const DatePickerForFilter = props => {
  const classes = useStyles()
  const { input: { onChange } } = useInput(props)
  const [value, setValue] = useState(null)
  const { flagClearDate } = props

  const handleChange = e => {
    const formatDate = format(e, 'yyyy-MM-dd')

    onChange(formatDate)
    return setValue(e)
  }

  useEffect(() => {
    setValue(null)
  }, [flagClearDate])

  return (
    <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale} alwaysOn>
      <DatePicker
        className={classes.field}
        inputVariant="filled"
        format="dd.MM.yyyy"
        label={props.label}
        cancelLabel="Отмена"
        onChange={e => handleChange(e)}
        value={value}
        InputProps={{ className: classes.input }}
      />
    </MuiPickersUtilsProvider>
  )
}


export default DatePickerForFilter
