import React from 'react'
import { useRedirect } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles({
  button: {
    cursor: 'pointer',
    minWidth: 64,
    padding: '5px 0',
    marginTop: 6,
    '&:hover': {
      backgroundColor: 'rgba(198, 40, 40, 0.04)',
    },
  },
})

const Button = ({ url }) => {
  const classes = useStyles()
  const redirectTo = useRedirect()

  return (
    <InfoOutlinedIcon
      color="primary"
      fontSize="small"
      className={classes.button}
      onClick={() => redirectTo(url)}
    />
  )
}

const CustomShowButton = ({ applicant, id }) => (applicant ? <Button url={`/user/${applicant.id}`} /> : <Button url={`/applicant-form/${id}`} />)

export default CustomShowButton
