import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { PageTitle } from 'common'
import ApplicationsExam from 'components/applications/ApplicationsExam'
import ApplicantsList from 'components/group-application/ApplicantsList'

import {
  useQueryWithStore,
  useDataProvider,
  useNotify,
  Loading,
  Error,
} from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import withPermission from '../common/withPermission'

const useStyles = makeStyles({
  message: {
    margin: 16,
  },
  date: {
    fontWeight: 'bold',
  },
  editCell: {
    padding: 0,
  },
  textRed: {
    color: 'rgba(255, 0, 0, 0.87)',
  },
  textGray: {
    color: 'rgba(0, 0, 0, 0.56)',
  },
  buttonEdit: {
    marginTop: 20,
  },
  button: {
    marginTop: 20,
    marginLeft: 20,
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between'
  },
})

const GroupApplicationPage = ({ permissions }) => {
  const { userId: managerId } = permissions
  const classes = useStyles()
  const { groupId } = useParams()
  const dataProvider = useDataProvider()
  const [addList, setAddList] = useState([])
  const notify = useNotify()

  const { loaded, error: errorGroup, data: appExam } = useQueryWithStore({
    type: 'getOne',
    resource: 'groups',
    payload: {
      id: groupId
    }
  })

  const sendApplicants = async () => {

    await dataProvider.create('exam/group', {
      data: {
        groupId,
        applicants: addList
      }
    }).then(() => {
      setAddList([])
      notify('Заявка отправлена', 'info')
    }).catch(error => {
      const { message } = error.message.data[0]
      notify(message, 'warning')
    })
  }

  if (!loaded) { return <Loading /> }
  if (errorGroup) { return <Error /> }

  return (
    <>
      <ApplicationsExam title="Групповая заявка" {...appExam} />
      <PageTitle title="Претенденты на экзамен" />
      <ApplicantsList
        addList={addList}
        setAddList={setAddList}
        groupId={groupId}
        managerId={managerId}

      />
      <div className={classes.wrap}>
        <Button
          variant="outlined"
          className={classes.buttonEdit}
          color="primary"
        >
          Отменить
        </Button>
        <Button
          variant="contained"
          className={classes.buttonEdit}
          color="primary"
          onClick={sendApplicants}
        >
          Подать групповую заявку
        </Button>
      </div>
    </>
  )
}

export default withPermission(['managerTraining'])(GroupApplicationPage)
