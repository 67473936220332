import React, { useState } from 'react'
import { useDataProvider, useNotify, TextInput } from 'react-admin'
import { Form } from 'react-final-form'
import validate from 'utils/validate'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Alert from './Alert'

const useStyles = makeStyles({
  form: {
    width: 312,
    height: 325,
  },
  text: {
    marginTop: 40,
    marginBottom: 15
  },
  button: {
    marginBottom: 15,
    padding: '16px 0'
  },
  inputField: {
    marginBottom: 60
  },
})

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Некорректный адрес электронной почты')
    .required('Обязательное поле'),
})

const PasswordRecoveryForm = ({ setPassFormVisible }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [successMessage, setSuccessMessage] = useState('')

  const onSubmit = async data => {
    try {
      await dataProvider.create('site/reset-password-request', { data })
      return setSuccessMessage('На вашу почту отправлено письмо со ссылкой для восстановления пароля.')
    } catch (error) {
      notify(error.message.data[0].message, 'warning')
    }
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={values => validate(values, schema)}
        render={({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className={classes.form}
          >
            <Typography
              variant="body1"
              className={classes.text}
            >
              Введите email, на который была зарегистрирована Ваша учетная запись:
            </Typography>
            <TextInput
              className={classes.inputField}
              fullWidth
              label="Электронная почта"
              name="email"
              source="email"
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              className={classes.button}
            >
              Отправить
            </Button>
            <Button
              fullWidth
              onClick={() => setPassFormVisible(false)}
              className={classes.button}
            >
              Отмена
            </Button>
          </form>
        )}
      />
      {successMessage && (<Alert type="info" message={successMessage} handleClose={() => setSuccessMessage('')} />)}
    </>
  )
}
export default PasswordRecoveryForm
