import React from 'react'
import { AppBar as RAAppBar, useLogout, usePermissions } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExitIcon from '@material-ui/icons/PowerSettingsNew'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  linkWrapper: {
    display: 'flex',
    marginRight: 20
  },
  accountIcon: {
    fill: 'white',
    marginRight: 10
  },
  logoutIcon: {
    fill: 'white'
  },
  link: {
    color: 'white',
    marginLeft: 'auto',
    textDecoration: 'none'
  },
  userName: {
    marginRight: 10
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  }
})

const AppBar = () => {
  const userName = localStorage.getItem('userName')
  const logout = useLogout()
  const classes = useStyles()
  const { permissions: { role, applicantStatus } = {} } = usePermissions()
  const isUser = role === 'user'
  const isManager = role === 'managerTraining'
  const isAdmin = role === 'admin' || role === 'manager'

  return (
    <RAAppBar
      style={{ zIndex: 1300 }}
      color="primary"
    >
      <div className={classes.wrapper}>
        {isUser && applicantStatus && applicantStatus === 'approve' && (
          <Link
            to="/profile"
            className={classes.link}
          >
            <div className={classes.linkWrapper}>
              <AccountCircleIcon className={classes.accountIcon} />
              <Tooltip title="Профиль">
                <Typography>{userName}</Typography>
              </Tooltip>
            </div>
          </Link>
        )}
        {(isManager || isAdmin) && (
          <Typography className={classes.userName}>{userName}</Typography>
        )}
        <Tooltip title="Выйти">
          <IconButton onClick={() => logout()}>
            <ExitIcon className={classes.logoutIcon} />
          </IconButton>
        </Tooltip>
      </div>
    </RAAppBar>
  )
}

export default AppBar
