import React from 'react'

import { useParams } from 'react-router-dom'

import {
  usePermissions,
  useQueryWithStore,
  Loading,
  Error,
  NotFound,
} from 'react-admin'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import LevelExamApplication from 'components/record-book/LevelExamApplication'

const useStyles = makeStyles({
  title: {
    margin: 0,
    padding: 25
  },
})

const RecordBookPage = () => {
  const { userId: applicantId } = useParams()
  const classes = useStyles()
  const { permissions: { role } = {} } = usePermissions()
  const isAdmin = role === 'admin' || role === 'manager'
  let isOldRegNumber

  const { loaded, error, data: levelExamList } = useQueryWithStore({
    type: 'getList',
    resource: 'exam-levels',
  })

  const { data, error: errorProfile } = useQueryWithStore({
    type: 'getOne',
    resource: applicantId ? `applicant/${applicantId}` : 'applicant/profile'
  })

  const userId = data?.userId


  if (!loaded) return <Loading />
  if (error) return <Error />
  if ((isAdmin && !applicantId) || errorProfile) return <NotFound />

  if (applicantId) {
    isOldRegNumber = data?.checkOldRegNumber && data?.oldRegNumber
  } else {
    isOldRegNumber = data?.applicant?.checkOldRegNumber && data?.applicant?.oldRegNumber
  }

  return (
    <>
      <Typography
        variant="h3"
        className={classes.title}
      >
        Зачетная книжка
      </Typography>
      {levelExamList && levelExamList.map(({ id, name }) => (
        <LevelExamApplication
          applicantId={applicantId}
          userId={userId}
          key={id}
          buttonAutopass={id === 1}
          name={name}
          levelId={id}
          isOldRegNumber={isOldRegNumber}
        />
      ))}
    </>
  )
}

export default RecordBookPage
