import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import {
  useNotify, useDataProvider, Loading, NotFound
} from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import ApplicationsExamCreate from 'components/applications/ApplicationsExamCreate'
import ApplicationsExamList from 'components/applications/ApplicationsExamList'

import HouseIcon from '@material-ui/icons/House'
import withPermission from '../common/withPermission'

const useStyles = makeStyles({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 0'
  },
  noLocationWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 20,
    marginBottom: 20
  },
  houseIcon: {
    marginLeft: 20,
    marginRight: 10
  }
})

const ExamBookingPage = ({ permissions: { role } }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const { examId } = useParams()
  const history = useHistory()
  const notify = useNotify()
  const [currentGroup, setCurrentGroup] = useState()
  const [loading, setLoading] = useState(true)
  const [onClickLoading, setOnClickLoading] = useState(false)
  const [errorLoading, setErrorLoading] = useState()
  const isManager = role === 'managerTraining'

  useEffect(() => {
    dataProvider.getOne('groups', { id: examId })
      .then(({ data: group }) => {
        setCurrentGroup(group)
        setLoading(false)
      })
      .catch(error => {
        setErrorLoading(true)
        setLoading(false)
      })
  }, [examId])

  const signUpExam = async () => {
    setOnClickLoading(true)
    const {
      levelId,
      moduleId,
      cityId
    } = currentGroup

    try {
      const response = await dataProvider.create('applicant/exam', {
        data: {
          levelId,
          moduleId,
          examId: parseInt(examId, 0),
          cityId
        }
      })

      if (response) {
        notify('Вы записаны  на экзамен')
        setOnClickLoading(false)
      }
    } catch ({ message }) {
      notify(message.data[0].message, 'warning')
      setOnClickLoading(false)
    }
  }

  const redirectToGroupApplicants = () => history.push(`/group-application/${examId}`)

  const backExamSchedule = () => history.push('/exam-schedule')

  if (loading) return <Loading />
  if (errorLoading) return <NotFound />
  if (!currentGroup) return null

  const { moduleId, ...examData } = currentGroup

  return (
    <>
      {examData.location ? (
        <ApplicationsExamCreate {...examData} />
      ) : (
          <>
            <div className={classes.noLocationWrapper}>
              <HouseIcon className={classes.houseIcon} />
              <Typography
                variant="h6"
              >
                Помещение для проведения экзамена пока не определено.
            </Typography>
            </div>
            <Divider />
          </>
        )}
      <ApplicationsExamList
        moduleId={moduleId}
        examId={examId}
      />
      <div className={classes.buttonsWrapper}>
        <Button
          variant="outlined"
          color="primary"
          onClick={backExamSchedule}
        >
          Назад
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={isManager ? redirectToGroupApplicants : signUpExam}
          disabled={onClickLoading}
        >
          Записаться на экзамен
        </Button>
      </div>
    </>
  )
}

export default withPermission(['user', 'managerTraining'])(ExamBookingPage)
