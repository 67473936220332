import React from 'react'

import { parse } from 'query-string'

import { useQueryWithStore, usePermissions } from 'react-admin'
import { PageTitle } from 'common'
import Typography from '@material-ui/core/Typography'
import getById from '../utils/getById'
import ExamGroupTable from '../components/exam-group/ExamGroupTable'

const ExamGroupPage = props => {
  const { filter } = parse(props.location.search)
  const { levelId } = JSON.parse(filter)
  const { permissions: { role } = {} } = usePermissions()
  const isUser = role === 'user'

  const { data: examLevels } = useQueryWithStore({
    type: 'getList',
    resource: 'exam-levels'
  })

  const levelName = examLevels ? getById(examLevels, levelId).name : ''

  return (
    <>
      <PageTitle title={`Экзамены: ${levelName}`} />
      {levelId == 1 && isUser && (
        <Typography style={{ marginBottom: 10, marginLeft: 24 }}>
          * Начало экзаменов по местному времени. В первый день сессии можно сдать не более 3 модулей, во второй день сессии — не более 2.
        </Typography>
      )}
      {levelId == 2 && isUser && (
        <Typography style={{ marginBottom: 10, marginLeft: 24 }}>
          * Начало экзаменов по московскому времени.
        </Typography>
      )}
      {levelId == 3 && isUser && (
        <Typography style={{ marginBottom: 10, marginLeft: 24 }}>
          * Начало экзаменов по московскому времени.
        </Typography>
      )}
      <ExamGroupTable {...props} levelId={levelId} />
    </>
  )
}

export default ExamGroupPage
