
import React from 'react'

import {
  ArrayInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  maxLength
} from 'react-admin'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import CustomSimpleFormIterator from 'common/CustomSimpleFormIterator'

const useStyles = makeStyles({
  wrap: {
    marginBottom: 5,
    width: '80%'
  },
  layout: {
    overflowX: 'hidden',
    marginBottom: 30
  },
  addIcon: {
    marginRight: 8
  },
  addButton: {
    marginTop: 10
  }
})

const ApplicantAdditionallyRasping = () => {
  const classes = useStyles()
  const idOthertTraings = 12

  return (
    <Grid className={classes.layout} container spacing={3}>
      <ArrayInput source="trainings" label="">
        <CustomSimpleFormIterator buttonLabel="Добавить УМЦ">
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => (
              <div className={classes.wrap}>
                <ReferenceInput
                  fullWidth
                  label="Наименование учебно методического центра (УМЦ)"
                  reference="training-centers"
                  source={getSource('centerId')}
                  validate={required('Наименование учебно методического центра (УМЦ)')}
                >
                  <SelectInput />
                </ReferenceInput>
                {scopedFormData && scopedFormData.centerId === idOthertTraings
                  && (
                    <TextInput
                      fullWidth
                      multiline
                      label="Другой УМЦ"
                      source={getSource('other')}
                      validate={[required(), maxLength(4096, 'Превышено максимальное количество знаков')]}
                    />
                  )}
              </div>
            )}
          </FormDataConsumer>
        </CustomSimpleFormIterator>
      </ArrayInput>
    </Grid>
  )
}

export default ApplicantAdditionallyRasping
