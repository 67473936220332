
import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import Button from '@material-ui/core/Button'

const ExamDeleteDialog = ({
  isOpenDialog,
  handleCloseDialog,
  className,
  deleteExam
}) => {
  return (
    <Dialog
      open={isOpenDialog}
      onClose={handleCloseDialog}
    >
      <DialogContent className={className}>
        <DialogContentText>
          Удалить экзамен?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCloseDialog}>
          Отмена
        </Button>
        <Button color="primary" onClick={deleteExam}>
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExamDeleteDialog
