import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { useDataProvider, useAuthenticated, useNotify } from 'react-admin'

import { format } from 'date-fns'
import { PageTitle } from 'common'
import ExamTable from '../components/exam-creation/ExamTable'
import ExamCreationForm from '../components/exam-creation/ExamCreationForm'

const ExamCreationPage = props => {
  useAuthenticated()
  const { examId } = useParams()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [applicants, setApplicants] = useState()
  const [members, setMembers] = useState([])
  const [totalMembers, setTotalMembers] = useState()
  const [examData, setExamData] = useState()

  const normalizeValues = values => {
    const { examTimeStart, examTimeEnd } = values
    const timeStartNormalized = examTimeStart && { examTimeStart: format(examTimeStart, 'HH:mm:00') }
    const timeEndNormalized = examTimeEnd && { examTimeEnd: format(examTimeEnd, 'HH:mm:00') }
    return { ...values, ...timeStartNormalized, ...timeEndNormalized }
  }

  const normalizeData = data => {
    const { examTimeStart, examTimeEnd } = data
    const timeStartNormalized = examTimeStart && { examTimeStart: new Date(`${new Date().toDateString()} ${examTimeStart}`) }
    const timeEndNormalized = examTimeEnd && { examTimeEnd: new Date(`${new Date().toDateString()} ${examTimeEnd}`) }
    return { ...data, ...timeStartNormalized, ...timeEndNormalized }
  }

  const error = response => {
    if (response && response.status === 422) {
      const { message } = response.data[0]
      return notify(message, 'warning')
    }
    return notify('Упс, что-то пошло не так, повторите запрос.', 'warning')
  }

  const requestChangeMembers = async (ids, type) => {
    try {
      await dataProvider[type](`group-members/${examData.id}`, { data: { applicationId: ids } })
      const { data: { models } } = await dataProvider.getOne(`group-members/${examData.id}?filter={"inGroup":1}&per-page=${1000}&page=${1}`)
      return setMembers(models)
    } catch ({ response }) {
      return error(response)
    }
  }

  const requestMembers = async id => {
    const { data: { models, total } } = await dataProvider.getOne(`group-members/${id}?filter={"inGroup":1}&per-page=${1000}&page=${1}`)
    setTotalMembers(total)
    return setMembers(models)
  }

  const requestApplicants = async id => {
    const { data: applicantsData } = await dataProvider.getOne(`group-members/${id}?per-page=${1000}&page=${1}`)
    return setApplicants(applicantsData)
  }

  const requestMembersSort = async (perPage = 1000, page = 0, order, property) => {
    console.log('requestMembersSort')
    const { data: { models } } = await dataProvider.getOne(
      `group-members/${examId}?filter={"inGroup":1}&per-page=${perPage}&page=${page + 1}&sort=${order === 'desc' ? '' : '-'}${property}`
    )
    return setMembers(models)
  }

  const requestApplicantsSort = async (perPage = 1000, page = 0, order, property) => {
    console.log('requestApplicantsSort')
    const { data } = await dataProvider.getOne(
      `group-members/${examData.id}?per-page=${perPage}&page=${page + 1}&sort=${order === 'desc' ? '' : '-'}${property}`
    )
    return setApplicants(data)
  }

  const onSubmitForm = async values => {
    try {
      if (examData) {
        const { id } = examData
        const { data } = await dataProvider.update(`groups/${id}`, { data: { ...normalizeValues(values) } })
        setExamData(normalizeData(data))
        return notify('Экзамен сохранен', 'success')
      }

      const { data } = await dataProvider.create('groups', { data: { ...normalizeValues(values) } })
      setExamData(normalizeData(data))
      requestApplicants(data.id)
      return notify('Экзамен создан', 'success')
    } catch ({ response }) {
      return error(response)
    }
  }

  const initState = (examId, group) => {
    requestApplicants(examId)
    requestMembers(examId)

    if (group !== undefined) {
      setExamData(normalizeData(group))
    }
  }

  useEffect(() => {
    const { location } = props

    if (location && location.state) {
      const { location: { state: { examData: examDataFromProps } } } = props
      initState(examId, examDataFromProps)
    } else if (examId) {
      dataProvider.getOne('groups', { id: examId })
        .then(({ data: group }) => {
          initState(examId, group)
        })
    }
  }, [])

  return (
    <>
      <PageTitle title={`${examId ? 'Редактирование' : 'Создание'} экзамена`} />
      <ExamCreationForm
        examData={examData}
        onSubmitForm={onSubmitForm}
      />
      {examData && applicants
        && (
          <ExamTable
            applicants={applicants}
            countPlaces={examData && examData.location && examData.location.countPlaces}
            members={members}
            totalMembers={totalMembers}
            requestApplicantsSort={requestApplicantsSort}
            requestChangeMembers={requestChangeMembers}
            requestMembers={requestMembers}
            requestMembersSort={requestMembersSort}
            examId={examData && examData.id}
            moduleId={examData.moduleId}
          />
        )}
    </>
  )
}

export default ExamCreationPage
