import React from 'react'
import {
  TextInput,
  SelectInput,
  required,
  useQueryWithStore,
  useRedirect,
  useNotify,
  useMutation,
  useUnselectAll
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import { Form } from 'react-final-form'

const useStyles = makeStyles({
  title: {
    padding: 0,
    paddingBottom: 15
  },
  name: {
    display: 'block',
    width: 450
  },
  divider: {
    marginTop: 25,
    border: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.2)'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 25
  },
  content: {
    width: 550,
    padding: '20px 24px'
  }
})

const ExamDeleteDialog = ({ onClose, open, exams }) => {
  const classes = useStyles()
  const redirect = useRedirect()
  const notify = useNotify()
  const [mutate, { loading }] = useMutation()
  const unselectAll = useUnselectAll()

  const { data: supervisors } = useQueryWithStore({
    type: 'getList',
    resource: 'users',
    payload: {
      filter: { role: 'supervisor' },
      pagination: { perPage: 150 }
    }
  })

  const formNewGroup = async formValues => {
    mutate({
      type: 'create',
      resource: 'exam-groups',
      payload: { data: { ...formValues, exams } }
    },
      {
        onSuccess: ({ data }) => {
          notify('Группа создана')
          redirect(`exam-groups/${data.id}`)
          unselectAll('scheduled-exams') // снять чекбоксы в таблице назначенных экзаменов
        },
        onFailure: err => {
          onClose()
          notify(err.message.data[0].message, 'warning')
        }
      })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}

    >
      <DialogContent className={classes.content}>
        <DialogTitle className={classes.title}>
          Создание новой группы
        </DialogTitle>
        <Form
          onSubmit={formNewGroup}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                source="name"
                label="Название группы"
                validate={required()}
                className={classes.name}
                fullWidth
                InputProps={{ disableUnderline: true }}
              />
              <SelectInput
                allowEmpty
                source="supervisorId"
                clasName={classes.supervisor}
                label="Супервайзер"
                optionValue="id"
                choices={supervisors}
                optionText={choice => `${choice.surname} ${choice.name} ${choice.middleName}`}
                fullWidth
                InputProps={{ disableUnderline: true }}
              />
              <hr className={classes.divider} />
              <div className={classes.actions}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Cформировать новую группу
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onClose}
                >
                  Закрыть
                </Button>
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}

export default ExamDeleteDialog
