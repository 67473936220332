import React, { useState } from 'react'
import {
  ListView, Datagrid, TextField, Pagination, useListController, FunctionField
} from 'react-admin'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { PageTitle } from 'common'
import formatDate from 'utils/formatDate'
import IconButton from '@material-ui/core/IconButton'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ScheduledExamsFilter from 'components/scheduled-exams/ScheduledExamsFilter'
import NewGroupFormPopup from 'components/scheduled-exams/NewGroupFormPopup'
import ValidationErrorPopup from 'components/scheduled-exams/ValidationErrorPopup'
import ScheduledExamInfo from 'components/scheduled-exams/ScheduledExamInfo'
import withPermission from '../common/withPermission'

const PUBLISHED_EXAMS_ID = 2
const BASIC_LEVEL_ID = 1

const useStyles = makeStyles({
  row: {
    height: '60px'
  },
  module: {
    fontWeight: 500
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 25
  },
})

const ExamsPagination = props => <Pagination rowsPerPageOptions={[10, 20, 50]} {...props} />

const ScheduledExamsList = props => {
  const classes = useStyles()
  const [validationError, setValidationError] = useState(false)
  const [formDialog, setOpenDialog] = useState(false)
  const [currentRecord, setCurrentRecord] = useState()
  const [infoDialog, setInfoDialog] = useState(false)

  const controlledProps = useListController({ ...props, filter: { statusId: PUBLISHED_EXAMS_ID, withoutGroups: 1 } })
  const { data, selectedIds } = controlledProps

  const validateSelectedItems = () => {
    let isValid = true
    if (selectedIds.length > 1) { // если выбранных экзаменов больше одного, проверим, что из них можно создать группу
      // listController дает все data-items на странице в виде объекта типа { 124: { id: 124, statusId: 4 }, 125: {id: 125, statusId: 1}, ...}
      const allDataItems = Object.values(data) // преобразуем этот объект в массив
      isValid = allDataItems.filter(item => selectedIds.includes(item.id)) // отфильтруем массив и выберем только checked items
        .every(({ // проверим, что у всех выбранных объектов значения некоторых свойств одинаковые
          examDate, locationId, examTimeStart, levelId
        }, i, array) => examDate === array[0].examDate // одинаковая дата экзамена
        && examTimeStart === array[0].examTimeStart // одинаковое время начала экзамена
        && locationId === array[0].locationId // одинаковый locationId
          && levelId === BASIC_LEVEL_ID // группу можно составить, только если все экзамены базового уровня
        )
    }

    if (isValid) {
      setOpenDialog(true)
    } else {
      setValidationError(true)
    }
  }

  const handleInfoClick = record => {
    setInfoDialog(true)
    setCurrentRecord(record)
  }

  return (
    <>
      <PageTitle title="Назначенные экзамены" />
      <ListView
        exporter={false}
        filters={<ScheduledExamsFilter />}
        pagination={<ExamsPagination {...controlledProps} />}
        bulkActionButtons={<div />}
        {...controlledProps}
      >
        <Datagrid classes={{ row: classes.row }}>
          <TextField
            label="Уровень"
            source="level.name"
            sortBy="levelId"
          />
          <TextField
            source="name"
            label="Название группы"
          />
          <TextField
            label="Модуль"
            source="modules[0].name"
          />
          <FunctionField
            label="Дата экзамена"
            sortBy="examDate"
            render={({ examDate, examTimeStart }) => {
              const date = examDate ? formatDate(examDate, 'dd.MM.yyyy') : ''
              const time = examTimeStart ? `в ${examTimeStart.slice(0, -3)}` : ''

              return <span>{`${date} ${time}`}</span>
            }}
          />
          <TextField
            label="Город"
            source="city.name"
            sortBy="cityName"
          />
          <FunctionField
            label="Помещение"
            sortBy="locationId"
            render={({ location }) => {
              const address = location?.address ? location.address : ''
              const room = location?.room ? `, ауд. ${location.room}` : ''

              return <span>{`${address}${room}`}</span>
            }}
          />
          <FunctionField
            label=""
            render={record => (
              <IconButton onClick={() => handleInfoClick(record)}>
                <InfoOutlinedIcon color="primary" />
              </IconButton>
            )}
          />
        </Datagrid>
      </ListView>
      <div className={classes.buttonWrapper}>
        <Button
          onClick={validateSelectedItems}
          color="primary"
          disabled={selectedIds.length === 0}
          variant="contained"
        >
          Сформировать экзаменационную группу
        </Button>
      </div>
      {formDialog && (
        <NewGroupFormPopup
          onClose={() => setOpenDialog(false)}
          open={formDialog}
          exams={selectedIds}
        />
      )}
      {validationError && (
        <ValidationErrorPopup open={validationError} onClose={() => setValidationError(false)} />
      )}
      {infoDialog && (
        <ScheduledExamInfo open={infoDialog} onClose={() => setInfoDialog(false)} record={currentRecord} />
      )}
    </>
  )
}

export default withPermission(['admin', 'manager'])(ScheduledExamsList)
