import React from 'react'

import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    padding: 24
  },
  wrap: {
    display: 'flex',

  },
  columnInfo: {
    width: '50%'
  },
  columnMap: {
    width: '50%',
    height: 272,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  date: {
    marginRight: 20
  },
  textfield: {
    width: '45%',
    marginBottom: 16
  },
  location: {
    width: '95%'
  },
  room: {
    width: '30%'
  }
})

const ApplicationsExamCreate = ({
  examDate,
  examTimeEnd,
  examTimeStart,
  location: {
    image,
    place,
    room,
    address,
    city: {
      name: cityName
    }
  }
}) => {
  const classes = useStyles()

  const examTimes = examTimeEnd && examTimeStart ? `${examTimeEnd} - ${examTimeStart}` : 'Не определено'

  return (
    <>
      <Typography
        variant="h3"
        className={classes.title}
      >
        Запись на экзамен
      </Typography>
      <Paper className={classes.paper}>
        <div className={classes.columnInfo}>
          <div className={classes.wrap}>
            <TextField
              variant="filled"
              value={format(new Date(examDate), 'dd MMMM yyyy', { locale: ru })}
              InputProps={{
                readOnly: true,
              }}
              label="Дата проведения"
              className={`${classes.date} ${classes.textfield}`}
            />
            <TextField
              variant="filled"
              value={examTimes}
              InputProps={{
                readOnly: true,
              }}
              label="Время проведения"
              className={`${classes.time} ${classes.textfield}`}
            />
          </div>
          <TextField
            variant="filled"
            value={cityName}
            InputProps={{
              readOnly: true,
            }}
            label="Город"
            className={classes.textfield}
          />
          <TextField
            variant="filled"
            value={address}
            InputProps={{
              readOnly: true,
            }}
            label="Адрес"
            className={`${classes.textfield} ${classes.location}`}
            multiline
          />
          <TextField
            variant="filled"
            value={room}
            InputProps={{
              readOnly: true,
            }}
            label="Аудитория"
            className={`${classes.textfield} ${classes.room}`}
          />
        </div>
        <div className={classes.columnMap}>
          <img src={image} width="auto" height="100%" title="Карта" alt="Карта" />
        </div>
      </Paper>
    </>
  )
}

export default ApplicationsExamCreate
