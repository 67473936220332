import React from 'react'
import {
  Datagrid,
  FunctionField,
  List,
  ShowButton,
  TextField,
  useRedirect,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {
  Empty, PageSubtitle, Status, FormattedDateField
} from 'common'

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 26,
  },
  row: {
    height: 60,
  },
})

const RecentApplicationsTable = ({ filter, RAListProps }) => {
  const classes = useStyles()
  const redirectTo = useRedirect()

  return (
    <>
      <div className={classes.wrap}>
        <PageSubtitle title="Последние заявки" />
        <Button
          color="primary"
          onClick={() => redirectTo('applicant/applications')}
        >
          Показать все заявки
        </Button>
      </div>
      <List
        {...RAListProps}
        actions={null}
        basePath="applicant/applications"
        bulkActionButtons={false}
        empty={<Empty title="Заявки отсутствуют" />}
        filter={filter}
        hasCreate
        hasEdit={false}
        hasList={false}
        hasShow={false}
        pagination={null}
        perPage={5}
        resource="applicant/applications"
        title=" "
      >
        <Datagrid classes={{ row: classes.row }}>
          <TextField
            label="Наименование"
            sortBy="typeName"
            source="type.name"
          />
          <FunctionField
            label="Дата"
            render={({ createdAt }) => <FormattedDateField date={createdAt} formatting="dd MMMM yyyy" />}
          />
          <FunctionField
            label="Статус"
            render={record => <Status status={record.status.name} />}
            sortBy="statusId"
            source="status.name"
          />
          <ShowButton
            disableRipple
            icon={<InfoOutlinedIcon />}
            label=""
          />
        </Datagrid>
      </List>
    </>
  )
}


export default RecentApplicationsTable
