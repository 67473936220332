import React from 'react'

import { usePermissions, Loading, NotFound } from 'react-admin'

const withPermission = availableRoleList => Wrapped => props => {
  const { loaded, permissions } = usePermissions()

  return loaded ? (
    <>
      {availableRoleList.includes(permissions.role) ? <Wrapped {...props} permissions={permissions} /> : <NotFound />}
    </>
  ) : <Loading />
}

export default withPermission
