import React from 'react'
import * as Yup from 'yup'
import { Form, Field } from 'react-final-form'
import { CustomTextInput } from 'common'
import validate from 'utils/validate'
import IconButton from '@material-ui/core/IconButton'
import DoneIcon from '@material-ui/icons/Done'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import Tooltip from '@material-ui/core/Tooltip'
import { useMutation, useNotify } from 'react-admin'

const useStyles = makeStyles({
  form: {
    height: 76
  },
  password: {
    marginRight: 20,
    width: 236
  }
})

const schema = Yup.object().shape({
  password: Yup.string()
    .required('Обязательное поле'),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать'),
})

const PasswordChange = ({ cb }) => {
  const classes = useStyles()
  const notify = useNotify()
  const [mutate] = useMutation()

  const onSubmit = value => {
    mutate({
      type: 'update',
      resource: 'applicant/update',
      payload: { data: value }
    },
      {
        onSuccess: () => {
          notify('Пароль успешно изменен')
          cb()
        },
        onFailure: error => notify(`Ошибка: ${error.message}`, 'warning'),
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => validate(values, schema)}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <Field
            name="password"
            component={CustomTextInput}
            label="Пароль"
            type="password"
            className={classes.password}
            variant="filled"
          />
          <Field
            name="passwordRepeat"
            component={CustomTextInput}
            label="Повторите пароль"
            type="password"
            className={classes.password}
            variant="filled"
          />
          <Tooltip title="Сменить пароль">
            <IconButton type="submit">
              <DoneIcon style={{ fill: 'green' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Отмена">
            <IconButton onClick={cb}>
              <ClearIcon style={{ fill: 'red' }} />
            </IconButton>
          </Tooltip>
        </form>
      )}
    />
  )
}

export default PasswordChange
