import React from 'react'

import { useQueryWithStore, usePermissions } from 'react-admin'

import CustomTextField from 'common/MuiCustomTextField'
import getById from 'utils/getById'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { PageTitle } from 'common'

const useStyles = makeStyles({
  paper: {
    paddingTop: '15px',
    paddingLeft: '20px'
  },
})

const ApplicationsExam = ({
  title,
  level: { name: levelName },
  modules,
  createdAt,
  cityId: examCityId,
  cancelReason,
  statusId,
  group = false
}) => {
  const { permissions: { role } = {} } = usePermissions()
  const isUser = role === 'user'
  const classes = useStyles()
  const { data: cities } = useQueryWithStore({
    type: 'getList',
    resource: 'cities',
    payload: {
      filter: {
        id: examCityId
      }
    }
  })

  const [module] = modules
  const statusCancel = statusId === 3 // статус заявки: отклонена
  const labelProps = { focused: false, shrink: true }

  const getValue = check => check || 'Не определено'

  return (
    <>
      {!isUser && (
        <PageTitle title={title} />
      )}
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              value={levelName}
              label="Уровень"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              value={module && module.name}
              label="Модуль"
              fullWidth
              multiline
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            {cities && getById(cities, examCityId) && (
              <CustomTextField
                value={cities && getById(cities, examCityId).name}
                label="Город"
                fullWidth
                InputLabelProps={labelProps}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              value={createdAt}
              label="Дата заявки"
              fullWidth
            />
          </Grid>
        </Grid>

        {group && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <CustomTextField
                  value={getValue(group?.examDate)}
                  label="Дата проведения"
                  fullWidth
                  InputLabelProps={labelProps}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  value={getValue(group?.location?.address)}
                  label="Адрес"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  value={getValue(group?.location?.place)}
                  label="Место проведения"
                  fullWidth
                  InputLabelProps={labelProps}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <CustomTextField
                  value={getValue(group?.location?.room)}
                  label="Аудитория"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  value={group.examTimeStart ? group.examTimeStart.slice(0, -3) : 'Не определено'}
                  label="Начало экзамена"
                  fullWidth
                  InputLabelProps={labelProps}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  value={group.examTimeEnd ? group.examTimeEnd.slice(0, -3) : 'Не определено'}
                  label="Окончание экзамена"
                  fullWidth
                  InputLabelProps={labelProps}
                />
              </Grid>
            </Grid>
          </>
        )}

        {cancelReason && statusCancel && (
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <CustomTextField
                value={cancelReason}
                label="Причина отклонения заявки"
                fullWidth
                multiline
                InputLabelProps={labelProps}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  )
}

export default ApplicationsExam
