import React from 'react'

import {
  TextInput,
  SelectInput,
  required,
  ReferenceInput,
  BooleanInput,
  regex
} from 'react-admin'

import { MaskedPhoneInput } from 'common'

import Grid from '@material-ui/core/Grid'

const validatePhone = [required('Введите номер телефон'), regex(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11}(\s*)?$/, 'Номер телефона должен состоять из 10 цифр')]

const ApplicantFormInfo = ({ checkOldRegNumber }) => (
  <>
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <TextInput fullWidth label="Фамилия" source="surname" validate={required('Введите фамилию')} />
      </Grid>
      <Grid item xs={4}>
        <TextInput fullWidth label="Имя" source="name" validate={required('Введите Имя')} />
      </Grid>
      <Grid item xs={4}>
        <TextInput fullWidth label="Отчество" source="middleName" validate={required('Введите отчество')} />
      </Grid>
      <Grid item xs={4}>
        <ReferenceInput
          source="regionId"
          reference="regions"
          label="Место проживания"
          sort={{ field: 'name', order: 'ASC' }}
          fullWidth
        >
          <SelectInput optionText="name" validate={required('Выберите место проживания')} />
        </ReferenceInput>
      </Grid>
      <Grid item xs={4}>
        <TextInput
          fullWidth
          label="Телефон"
          source="phone"
          validate={validatePhone}
          InputProps={{
            inputComponent: MaskedPhoneInput
          }}
        />
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <BooleanInput label="У меня есть старый регистрационный номер." source="checkOldRegNumber" />
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={4}>
        {checkOldRegNumber && <TextInput fullWidth label="Старый рег. номер" source="oldRegNumber" validate={required('Введите Старый рег. номер')} />}
      </Grid>
    </Grid>
  </>
)

export default ApplicantFormInfo
