import React, {useState} from 'react'
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  EditButton,
  FunctionField, useDataProvider
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import GroupsFilter from 'components/groups-list/GroupsFilter'
import { PageTitle } from 'common'
import formatDate from 'utils/formatDate'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Link } from 'react-router-dom'
import withPermission from '../common/withPermission'
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles({
  row: {
    height: '60px'
  },
  module: {
    fontWeight: 500
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40
  },
  button: {
    textDecoration: 'none',
    fontWeight: 500
  },
  moduleName: {
    position: 'relative',
    display: 'block',
    padding: '15px 0',
    fontWeight: 500,
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      bottom: 1,
      left: 0
    },
    '&:last-child::after': {
      display: 'none'
    }
  }
})

const CheckboxAll = () => {
  return (
      <Checkbox
          color="primary"
      />
  )
}

const GroupsPagination = props => <Pagination rowsPerPageOptions={[20, 50, 100]} {...props} />

const GroupsList = props => {
  const classes = useStyles()

  const dataProvider = useDataProvider()

  const [selectedList, setSelectedList] = useState([])
  const [sending, setSending] = useState(false)

  /**
   * Выбирает/удаляет группу из списка выбранных.
   * @param event
   * @param id
   */
  const toggleSelectedList = (event, id) => {
    const checked = event.target.checked

    if (checked) {
      setSelectedList([...selectedList, id])
    } else {
      const index = selectedList.indexOf(id)
      selectedList.splice(index, 1)
      setSelectedList([...selectedList])
    }
  }

  /**
   * Отправляет запрос на скачивание протокола.
   * @return {Promise<void>}
   */
  const createReport = async () => {
    setSending(true)

    const response = await dataProvider.create('exam-groups/print-all', {
      data: {
        groups: selectedList
      }
    })

    setSending(false)
    window.open(response.data.url, '_blank')
  }

  return (
    <>
      <PageTitle title="Список групп" />
      <List
        exporter={false}
        bulkActionButtons={false}
        perPage={20}
        pagination={<GroupsPagination />}
        filters={<GroupsFilter context="form" />}
        sort={{ field: 'createdAt', order: 'DESC' }}
        {...props}
      >
        <Datagrid classes={{ row: classes.row }}>
          <FunctionField
            render={({id}) => (
                <Checkbox
                    color="primary"
                    checked={selectedList.includes(id)}
                    onChange={e => toggleSelectedList(e, id)}
                />
            )}
          />
          <TextField
            source="name"
            label="Название группы"
            sortBy="name"
          />
          <TextField
            source="exam.level.name"
            label="Уровень"
            sortBy="levelId"
          />
          <FunctionField
            label="Модуль"
            className={classes.module}
            render={({ modules }) => (
              modules && modules.map(({ name }) => (<div className={classes.moduleName}>{name}</div>))
            )}
          />
          <FunctionField
            label="Дата экзамена"
            sortBy="examDate"
            render={({ exam }) => (exam?.examDate ? formatDate(exam.examDate, 'dd.MM.yyyy') : '')}
          />
          <TextField
            source="exam.city.name"
            label="Город"
            sortBy="cityId"
          />
          <FunctionField
            label="Помещение"
            render={({ exam }) => {
              const address = exam?.location?.address ? exam.location.address : ''
              const room = exam?.location?.room ? `, ауд. ${exam.location.room}` : ''
              return `${address}${room}`
            }}
          />
          <FunctionField
            label="Супервайзер"
            render={({ supervisor }) => (supervisor ? `${supervisor.surname} ${supervisor.name} ${supervisor.middleName}` : '')}
          />
          <EditButton
            disableRipple
            icon={<InfoOutlinedIcon />}
            label=""
          />
        </Datagrid>
      </List>
      <div className={classes.buttonWrapper}>
        <Button
            variant="outlined"
            color="primary"
            disabled={selectedList.length === 0 || sending}
            onClick={() => createReport()}>
          Сформировать протокол
        </Button>
        <Link to="/scheduled-exams" className={classes.button}>
          <Button variant="contained" color="primary">
            Создать новую группу
          </Button>
        </Link>
      </div>
    </>
  )
}

export default withPermission(['admin', 'manager'])(GroupsList)
