import React, { useState } from 'react'
import { useNotify, useMutation } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import FormEditTrainings from '../profile-editing/FormEditTrainings'
import FormShowTrainings from './FormShowTrainings'

const useStyles = makeStyles({
  paper: {
    padding: 24,
    position: 'relative',
  },
  title: {
    padding: 24
  },
})

const ProfileTrainings = ({ trainings, editable, id }) => {
  const classes = useStyles({})
  const notify = useNotify()
  const [mutate] = useMutation()
  const [isEditable, setIsEditable] = useState(false)
  const [trainingsData, setTrainingsData] = useState(trainings)

  const onSubmit = async values => {
    mutate({
      type: 'update',
      resource: `applicant/update-account/${id}`,
      payload: { data: { ...values } }
    },
      {
        onSuccess: ({ data: { applicant: { trainings: responseTrainings } } }) => {
          setTrainingsData(responseTrainings)
          notify('Данные сохранены')
          setIsEditable(false)
        },
        onFailure: error => notify(`Ошибка: ${error.message}`, 'warning'),
      })
  }


  return (
    <>
      <Typography
        variant="h6"
        className={classes.title}
      >
        Сведения о подготовке к квалификационному экзамену
      </Typography>
      <Paper className={classes.paper}>
        {isEditable
          ? <FormEditTrainings trainings={trainingsData} setIsEditable={setIsEditable} onSubmit={onSubmit} />
          : <FormShowTrainings trainings={trainingsData} editable={editable} setIsEditable={setIsEditable} />}
      </Paper>
    </>
  )
}

export default ProfileTrainings
