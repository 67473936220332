import React, { useState } from 'react'
import { Edit } from 'react-admin'
import { PageTitle } from 'common'
import { makeStyles } from '@material-ui/core/styles'
import LocationEdit from '../components/locations/LocationEdit'
import LocationShow from '../components/locations/LocationShow'

const useStyles = makeStyles({
  title: {
    paddingBottom: 0
  },
})

const LocationsShow = props => {
  const classes = useStyles()
  const [isEditable, setIsEditable] = useState(false)

  return (
    <>
      <PageTitle title="Помещение" className={classes.title} />
      <Edit
        {...props}
        title=" "
        component="div"
      >
        {isEditable
          ? <LocationEdit setIsEditable={setIsEditable} />
          : <LocationShow setIsEditable={setIsEditable} />}
      </Edit>
    </>
  )
}

export default LocationsShow
