import React from 'react'
import { Form } from 'react-final-form'
import {
  SelectInput, TextInput, BooleanInput, usePermissions
} from 'react-admin'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import validate from 'utils/validate'
import { MaskedPhoneInput } from 'common'
import setPhoneMask from 'utils/setPhoneMask'
import ButtonBlock from './ButtonBlock'

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    minHeight: 80
  },
  field: {
    width: 283,
    paddingRight: 20,
  },
  title: {
    paddingBottom: 24
  },
})

const FormEditProfile = ({
  data: {
    regionId,
    email,
    middleName,
    name,
    phone,
    role: roleUser,
    surname,
    managerId,
    privilege,
    checkOldRegNumber,
    oldRegNumber
  } = {},
  regions,
  managers,
  onSubmit,
  roles,
  setIsEditable,
  isAdding = false
}) => {
  const maskedPhone = setPhoneMask(phone || '')
  const classes = useStyles()
  const { loaded, permissions: { role } = {} } = usePermissions()

  const rolesChoiceForManagerEAK = [
    {
      id: 'managerTraining',
      description: 'Менеджер по персоналу'
    },
    {
      id: 'user',
      description: 'Претендент'
    },
    {
      id: 'supervisor',
      description: 'Супервайзер'
    }
  ]

  const isAdmin = role === 'admin'
  const isManagerEAK = role === 'manager'
  const isUser = role === 'user'

  const required = Yup.string().required('Обязательное поле')
  const roleValidation = isAdmin || isManagerEAK ? required : null

  const ManagerName = ({ record }) => (`${record.surname} ${record.name} ${record.middleName}`)

  const schema = Yup.object().shape({
    name: required,
    middleName: required,
    surname: required,
    regionId: required,
    role: roleValidation,
    email: Yup.string()
      .email('Некорректный адрес электронной почты'),
    phone: Yup.string()
      .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11}(\s*)?$/, 'Номер телефона должен состоять из 10 цифр')
  })

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={values => validate(values, schema)}
        render={({ handleSubmit, values: { checkOldRegNumber: test } }) => (
          <form
            onSubmit={handleSubmit}
          >
            {!isAdding && (
              <Typography
                variant="h6"
                className={classes.title}
              >
                Редактирование персональных данных
              </Typography>
            )}
            <div className={classes.wrap}>
              <TextInput
                className={classes.field}
                defaultValue={surname}
                label="Фамилия"
                source="surname"
              />
              <TextInput
                className={classes.field}
                defaultValue={name}
                label="Имя"
                source="name"
              />
              <TextInput
                className={classes.field}
                defaultValue={middleName}
                label="Отчество"
                source="middleName"
              />
            </div>
            <div className={classes.wrap}>
              <SelectInput
                choices={regions}
                className={classes.field}
                defaultValue={regionId}
                label="Место проживания (Регион)"
                source="regionId"
              />
            </div>
            <div className={classes.wrap}>
              <TextInput
                className={classes.field}
                defaultValue={email}
                label="Электронная почта"
                source="email"
              />
              <TextInput
                className={classes.field}
                defaultValue={maskedPhone}
                label="Телефон"
                source="maskedPhone"
                name="phone"
                InputProps={{
                  inputComponent: MaskedPhoneInput
                }}
              />
            </div>
            <div className={classes.wrap}>
              {loaded && (isAdmin || isManagerEAK) && (
                <SelectInput
                  choices={isManagerEAK ? rolesChoiceForManagerEAK : roles}
                  className={classes.field}
                  optionText="description"
                  defaultValue={roleUser}
                  label="Роль"
                  source="role"
                />
              )}
              {!isUser && (
                <SelectInput
                  choices={managers}
                  className={classes.field}
                  defaultValue={managerId}
                  optionText={<ManagerName />}
                  label="Менеджер по обучению"
                  source="managerId"
                />
              )}
            </div>
            <div className={classes.wrap}>
              <TextInput
                className={classes.field}
                defaultValue={oldRegNumber}
                label="Старый рег. номер"
                source="oldRegNumber"
                disabled={!test}
              />
              <BooleanInput label="Есть старый рег. номер" source="checkOldRegNumber" defaultValue={checkOldRegNumber} />
            </div>
            <div className={classes.wrap}>
              <BooleanInput label="Льготный пользователь" source="privilege" defaultValue={privilege} />
            </div>
            <ButtonBlock handleClickAccept={handleSubmit} handleClickCancel={() => setIsEditable(false)} />
          </form>
        )}
      />
    </>
  )
}

export default FormEditProfile
