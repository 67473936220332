import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  main: {
    padding: 24
  }
})
const PageTitle = ({ title, className }) => {
  const classes = useStyles()

  return (
    <Typography
      variant="h3"
      className={`${classes.main} ${className}`}
    >
      {title}
    </Typography>
  )
}

export default PageTitle
