import React, { useEffect, useState } from 'react'
import {
  useAuthenticated,
  FileInput,
  FileField,
  useQueryWithStore,
  useDataProvider,
  useNotify,
  useRedirect
} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Link } from 'react-router-dom'

import { Form } from 'react-final-form'
import update from 'immutability-helper'


const useStyles = makeStyles({
  checkbox: {
    '& svg': {
      fill: '#c62828'
    }
  },
  title: {
    padding: 24
  },
  subtitle: {
    paddingBottom: 8
  },
  upload: {
    width: 223,
    marginTop: 10
  },
  paper: {
    padding: 24
  },
  listItem: {
    padding: 8
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30
  }
})

const AutoPassPage = () => {
  useAuthenticated()
  const classes = useStyles()
  const notify = useNotify()
  const [stateModules, setStateModules] = useState()
  const [file, setFile] = useState(false)
  const [loading, setLoading] = useState(false)
  const dataProvider = useDataProvider()
  const baseLevelId = 1
  const redirectTo = useRedirect()
  const saveFile = async (url, upload, name) => {
    const fileObj = {}
    const response = await dataProvider.uploadFile(url, { payload: upload })

    fileObj[name] = response.data
    return fileObj
  }

  const saveData = async () => {
    setLoading(true)
    const checkedModulesIdList = stateModules.reduce((acc, { checked, id }) => {
      if (checked) acc.push(id)
      return acc
    }, [])

    let fileLInk = false
    let allFormValues = {
      modules: checkedModulesIdList
    }

    if (file) fileLInk = await saveFile('upload/file', file, 'dockLink')

    allFormValues = {
      ...allFormValues,
      documentLink: fileLInk.dockLink
    }

    await dataProvider.create('applicant/auto-test', { data: allFormValues })
      .then(() => {
        setLoading(false)
        notify('Заявка отправлена')
        setTimeout(() => redirectTo('record-book'), 4000)
      })
  }

  const { data: modules } = useQueryWithStore({
    type: 'getList',
    resource: 'modules',
    payload: {
      filter: {
        levelId: baseLevelId
      }
    }
  })

  useEffect(() => {
    if (modules) {
      setStateModules(modules.map(({ name, id }) => ({
        name,
        id,
        checked: true
      })))
    }
  }, [modules])

  const toggleModules = (ev, moduleId) => {
    const {
      target: {
        checked
      }
    } = ev
    const index = stateModules.findIndex(x => x.id === moduleId)

    setStateModules(update(stateModules, { [index]: { checked: { $set: checked } } }))
  }

  return (
    <Form
      initialValues={{ stateModules }}
      onSubmit={saveData}
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <>
          <form onSubmit={handleSubmit}>
            <>
              <Typography
                variant="h3"
                className={classes.title}
              >
                Заявка на зачет
              </Typography>
              <Paper elevation={3} className={classes.paper}>
                <Typography
                  variant="h5"
                  className={classes.subtitle}
                >
                  Выбрать модуль базового уровня для зачета:
                </Typography>
                <FormGroup>
                  {stateModules && stateModules.map(item => (
                    <FormControlLabel
                      key={item.id}
                      control={(
                        <Checkbox
                          color="primary"
                          className={classes.checkbox}
                          onChange={e => toggleModules(e, item.id)}
                          checked={item.checked}
                        />
                      )}
                      label={item.name}
                      className={classes.listItem}
                    />
                  ))}
                  <FileInput
                    source="dockLink"
                    onChange={upload => setFile(upload)}
                    label={false}
                    accept=".pdf,.doc,.docx,.xls,.xlsx"
                    placeholder={<p> Загрузить документ</p>}
                    margin="normal"
                  >
                    <FileField source="src" title="title" />
                  </FileInput>
                </FormGroup>
              </Paper>
              <div className={classes.buttonsWrapper}>
                <Link to="/record-book" style={{ textDecoration: 'none' }}>
                  <Button
                    onClick
                    variant="outlined"
                    color="primary"
                  >
                    Отменить
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveData}
                  disabled={loading}
                >
                  Отправить Заявку
                </Button>
              </div>
            </>
          </form>
        </>
      )}
    />

  )
}

export default AutoPassPage
