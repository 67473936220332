const validate = (values, schema) => new Promise(resolve => {
  schema.validate(values, { abortEarly: false })
    .then(() => {
      resolve()
    })
    .catch(errors => {
      const rErrors = {}

      errors.inner.forEach(error => {
        rErrors[error.path] = error.message
      })
      resolve(rErrors)
    })
})

export default validate
