import React, {Component, useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {required, SimpleForm, TextInput, SaveButton, Toolbar} from 'react-admin'
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel"
import request from "../../providers/request";
import Alert from "../Alert";
import getFullName from "../../utils/getFullName";

const CertificateModal = ({showModal, setShowModal}) => {
    const styles = makeStyles({
        row: {
            display: 'flex',
            width: '100%',
        },
        rowBottom: {
            display: 'flex',
            width: '100%',
            marginBottom: '25px',
        },
        col4: {
            width: '25%',
            marginRight: '15px',
        },
        col6: {
            width: '50%',
            marginRight: '30px'
        },
        col8: {
            width: '75%',
            marginLeft: '15px',
            marginRight: '30px'
        },
        col12: {
            width: '100%'
        },
        w100: {
            width: '100%'
        },
        error: {
            color: '#f44336',
            fontSize: '0.80rem'
        },
        title: {
            marginBottom: '5px'
        },
        small: {
            fontSize: '0.75rem'
        },
        mt2: {
            marginTop: '5px'
        },
        textSuccess: {
            color: '#28a745'
        }
    })
    const classes = styles()
    const [selectedModules, setSelectedModules] = useState([])
    const [level1All, setLevel1All] = useState(false)
    const [level2All, setLevel2All] = useState(false)
    const [accept, setAccept] = useState(true)
    const [info, setInfo] = useState(null)
    const [sending, setSending] = useState(false)
    const [result, setResult] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    /**
     * Закрывает модалку.
     */
    const closeModal = () => {
        setShowModal(false)
    }

    /**
     * Отправляет данные.
     */
    const sendData = (values) => {
        if (sending) {
            return
        }

        if (selectedModules.length === 0 && !level1All && !level2All) {
            setErrorMessage('Выберите один из модулей или свидетельство.')
            return
        }

        if (accept) {
            setSending(true)
            request.send('POST', '/api/app/applicant/certificate', {
                postCode: values.postCode,
                address: values.address,
                modules: selectedModules,
                level1All: level1All,
                level2All: level2All,
                comment: values.comment
            })
                .then(response => {
                    setSending(false)
                    setResult(response.data)
                    setTimeout(() => {window.location.reload()}, 3000)
                })
                .catch(error => {
                    setSending(false)
                    setErrorMessage('Возникли ошибки при отправки.')
                })
        }
    }

    /**
     * Выбирает указанный модуль.
     * @param e
     * @param id
     */
    const selectModule = (e, id) => {
        if (e.target.checked) {
            setSelectedModules([...selectedModules, id])
        } else {
            const index = selectedModules.indexOf(id)
            selectedModules.splice(index, 1)
            setSelectedModules([...selectedModules])
        }
    }

    /**
     * Выбирает сертификат на весь уровень.
     * @param e
     * @param levelId
     */
    const selectLevelAll = (e, levelId) => {
        if (levelId === 1) {
            setLevel1All(e.target.checked)
        } else {
            setLevel2All(e.target.checked)
        }
    }

    /**
     * Подтвердение на обработку данных.
     * @param e
     */
    const setAcceptCheck = (e) => {
        setAccept(e.target.checked)
    }

    /**
     * ToolBar для формы.
     * @param props
     * @return {JSX.Element}
     * @constructor
     */
    const SendToolBar = props => (
        <Toolbar {...props} >
            <SaveButton
                label="Отправить"
                redirect="show"
                disabled={sending}
                submitOnEnter={true}
            />
        </Toolbar>
    )

    const acceptLabel = (<span>Я ознакомился и согласен с <a href="https://eak-rus.ru/files/2021/reglament_vid_sert.pdf" target="_blank">Регламентом выдачи сертификатов и свидетельств АНО ЕАК</a> и <a href="https://eak-rus.ru/files/2022/prikaz_24_230322.pdf" target="_blank">Приказом АНО ЕАК об установлении платы за выдачу сертификатов и свидетельств</a></span>)

    useEffect(() => {
        request.send('GET', '/api/app/applicant/modules-info')
            .then(response => {
                setInfo(response.data)
            })
    }, [])

    return (
        <Dialog open={showModal} onClose={closeModal} maxWidth="md" fullWidth={true}>
            <DialogTitle>Заявка на сертификат/свидетельство</DialogTitle>
            <DialogContent>
                {result !== null && (
                    <p>Вы успешно отправили заявку на сертификат.</p>
                )}
                {(info !== null && result === null) && (
                    <SimpleForm save={sendData} toolbar={<SendToolBar />}>
                        <div className={classes.row}>
                            <div className={classes.col12}>
                                <h3 className={classes.title}>1. Контактная информация.</h3>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.col6}>
                                <TextInput
                                    fullWidth
                                    disabled
                                    source="fio"
                                    label="ФИО"
                                    defaultValue={getFullName(info.applicant.user.surname, info.applicant.user.name, info.applicant.user.middleName)}
                                />
                            </div>
                            <div className={classes.col6}>
                                <TextInput
                                    fullWidth
                                    disabled
                                    source="phone"
                                    label="Телефон"
                                    defaultValue={info.applicant.phone}
                                />
                            </div>
                        </div>
                        <div className={classes.rowBottom}>
                            <div className={classes.col6}>
                                <TextInput
                                    fullWidth
                                    disabled
                                    source="regNumber"
                                    label="Регистрационный номер"
                                    defaultValue={info.applicant.regNumber}
                                />
                            </div>
                            <div className={classes.col6}>
                                <TextInput
                                    fullWidth
                                    disabled
                                    source="email"
                                    label="Email"
                                    defaultValue={info.applicant.user.email}
                                />
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.col12}>
                                <h3 className={classes.title}>2. Укажите почтовый адрес для получения сертификата/свидетельства.</h3>
                            </div>
                        </div>
                        <div className={classes.rowBottom}>
                            <div className={classes.col4}>
                                <TextInput
                                    fullWidth
                                    source="postCode"
                                    label="Почтовый индекс"
                                    validate={required()}
                                />
                            </div>
                            <div className={classes.col8}>
                                <TextInput
                                    fullWidth
                                    source="address"
                                    label="Почтовый адрес"
                                    validate={required()}
                                />
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.col12}>
                                <h3 className={classes.title}>3. Отметьте сертификаты/свидетельства, которые Вы хотите получить.</h3>
                            </div>
                        </div>
                        {info.info.map(item => (
                            <div className={classes.row}>
                                {item.modules.length > 0 && (
                                    <div className={classes.col12}>
                                        <h4 className={classes.title}><em>{item.name}</em></h4>
                                        {item.checkAll && (
                                            <div className={classes.group}>
                                                <FormControlLabel
                                                    className={classes.textSuccess}
                                                    label={item.levelId === 1 ? 'Свидетельство (Базовый уровень)' : 'Свидетельство (Основной уровень)'}
                                                    control={(
                                                        <Checkbox
                                                            color="primary"
                                                            checked={item.levelId === 1 ? level1All : level2All}
                                                            onChange={e => selectLevelAll(e, item.levelId)}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )}
                                        {item.modules.map(module => (
                                            <FormControlLabel
                                                className={classes.w100}
                                                label={'Сертификат «' + module.name + '»'}
                                                control={(
                                                    <Checkbox
                                                        color="primary"
                                                        checked={selectedModules.includes(module.id)}
                                                        onChange={e => selectModule(e, module.id)}
                                                    />
                                                )}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                        <div className={classes.row}>
                            <div className={classes.col12}>
                                <br />
                                <h3 className={classes.title}>4. Дополнительная информация.</h3>
                            </div>
                        </div>
                        <div className={classes.rowBottom}>
                            <div className={classes.col12}>
                                <TextInput
                                    className={classes.col6}
                                    source="comment"
                                    label="Дополнительная информация"
                                    multiline
                                    rows={4}
                                />
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.col12}>
                                <FormControlLabel
                                    label={acceptLabel}
                                    control={(
                                        <Checkbox
                                            checked={accept}
                                            color="primary"
                                            onChange={e => setAcceptCheck(e)}
                                        />
                                    )}
                                />
                                <div className={classes.error}>{!accept ? 'Необходимо дать согласие' : ''}</div>
                            </div>
                        </div>
                    </SimpleForm>
                )}
                {errorMessage && (
                    <Alert
                        type="error"
                        message={errorMessage}
                        handleClose={() => setErrorMessage('')}
                    />
                )}
            </DialogContent>
        </Dialog>
    )
}

export default CertificateModal