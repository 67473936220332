import React, { useState } from 'react'
import FormWrapper from 'components/FormWrapper'
import LoginForm from 'components/LoginForm'
import PasswordRecoveryForm from 'components/PasswordRecoveryForm'

const LoginPage = () => {
  const [passFormVisible, setPassFormVisible] = useState(false)
  const title = passFormVisible ? 'Восстановление пароля' : 'Вход'

  return (
    <FormWrapper title={title}>
      {!passFormVisible ? (
        <LoginForm setPassFormVisible={setPassFormVisible} />
      ) : (
        <PasswordRecoveryForm setPassFormVisible={setPassFormVisible} />
      )}
    </FormWrapper>
  )
}

export default LoginPage
