import React from 'react'
import {
  Filter, DateInput, SelectInput, ReferenceInput, AutocompleteInput
} from 'react-admin'


const GroupsFilter = props => (
  <Filter {...props}>
    <ReferenceInput label="Уровень" source="levelId" reference="exam-levels">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Модуль" source="moduleId" reference="modules" allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <DateInput source="examDate" label="Дата экзамена" />
    <ReferenceInput label="Город" source="cityId" reference="cities" filterToQuery={searchText => ({ name: searchText })}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Помещение" source="locationId" reference="locations">
      <SelectInput optionText="place" />
    </ReferenceInput>
  </Filter>
)

export default GroupsFilter
