import React from 'react'
import {
  useDataProvider,
  useRedirect,
  TextInput,
  useNotify
} from 'react-admin'
import { Form } from 'react-final-form'
import validate from 'utils/validate'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import FormWrapper from 'components/FormWrapper'


const useStyles = makeStyles({
  inputField: {
    marginBottom: 20,
    height: 56,
  },
  hiddenField: {
    visibility: 'hidden',
    height: 0,
  },
  form: {
    width: 312,
    height: 325,
  },
  button: {
    padding: '11px 0',
    height: 44,
    marginTop: 35,
  },
  wrap: {
    marginTop: 50,
  },
})

const schema = Yup.object().shape({
  password: Yup.string()
    .oneOf([Yup.ref('passwordRepeat'), null], 'Пароли не совпадают')
    .required('Обязательное поле'),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Пароли не совпадают')
    .required('Обязательное поле'),
  token: Yup.string()
})

const ResetPassword = (...props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const redirectTo = useRedirect()
  const notify = useNotify()
  const { 0: { location: { search } } } = props

  const onSubmit = async data => {
    try {
      await dataProvider.create('site/reset-password', { data })
      notify('Пароль изменен')
      return setTimeout(() => redirectTo('/login'), 3000)
    } catch ({ response }) {
      if (response && response.status === 422) {
        const { message } = response.data[0]
        return notify(message, 'warning')
      }
      return notify('Упс, что-то пошло не так, повторите запрос.', 'warning')
    }
  }

  return (
    <FormWrapper title="Восстановление пароля">
      <Form
        onSubmit={onSubmit}
        validate={values => validate(values, schema)}
        render={({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className={classes.form}
          >
            <div className={classes.wrap}>
              <TextInput
                className={classes.inputField}
                fullWidth
                label="Новый пароль"
                name="password"
                source="password"
                type="password"
              />
              <TextInput
                name="passwordRepeat"
                className={classes.inputField}
                fullWidth
                label="Повторите новый пароль"
                source="passwordRepeat"
                type="password"
              />
              <TextInput
                name="token"
                source="token"
                defaultValue={search.substring(7)}
                className={classes.hiddenField}
              />
            </div>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              color="primary"
              className={classes.button}
            >
              Создать пароль
            </Button>
          </form>
        )}
      />
    </FormWrapper>
  )
}

export default ResetPassword
