import { stringify } from 'query-string'
import request from './request'

export default {
  getList: async (resource, params) => {
    const endPoint = resource == 'scheduled-exams' ? 'groups' : resource
    const {
      pagination: {
        page = 1,
        perPage = 10
      } = {},
      sort: { field, order } = {},
      filter,
      data = {}
    } = params || {}
    const query = {
      page,
      'per-page': perPage,
      filter: JSON.stringify(filter),
      ...data
    }

    if (order && field) {
      const prefix = order === 'ASC' ? '' : '-'
      query.sort = `${prefix}${field}`
    }

    const response = await request.send('GET', `/api/app/${endPoint}?${stringify(query)}`)

    return Promise.resolve({
      data: response.data.models || response.data,
      total: response.data.total || 0
    })
  },

  getOne: async (resource, params = '') => {
    const { id, data: reqData } = params
    const url = id ? `/${id}` : ''
    let requestUrl = `/api/app/${resource}${url}`

    if (reqData) {
      requestUrl = requestUrl + `?${stringify(reqData)}`
    }

    const response = await request.send('GET', requestUrl)
    const {
      data,
    } = response


    return Promise.resolve({
      data
    })
  },

  delete: async (resource, params) => {
    const { id, data } = params
    const url = id ? `/${id}` : ''
    const response = await request.send('DELETE', `/api/app/${resource}${url}`, data)
    return response
  },

  create: async (resource, params) => {
    const { data } = params
    const response = await request.send('POST', `/api/app/${resource}`, data)

    return Promise.resolve(response)
  },

  update: async (resource, params) => {
    console.log(resource, 'resource')
    console.log(params, 'params')
    const { id, data: update } = params
    const url = id ? `/${id}` : ''
    const response = await request.send('PUT', `/api/app/${resource}${url}`, update)
    const {
      data
    } = response

    return Promise.resolve({
      data
    })
  },

  getMany: async (resource, params) => {
    const { ids } = params
    const idsArray = ids.join(',')
    const response = await request.send('GET', `/api/app/${resource}?id=${idsArray}`)

    return Promise.resolve({
      data: response.data.models || response.data,
      total: response.data.total || null
    })
  },

  uploadFile: async (resource, params) => {
    const { payload } = params
    const data = new FormData()
    data.append('file', payload)
    const response = await request.send('POST', `/api/app/${resource}`, data, { 'Content-Type': 'multipart/form-data' })

    return Promise.resolve(response)
  }
}
