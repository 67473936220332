import React from 'react'
import { useSelector } from 'react-redux'
import { MenuItemLink, usePermissions } from 'react-admin'
import { withRouter } from 'react-router-dom'
import GroupIcon from '@material-ui/icons/Group'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import AppsIcon from '@material-ui/icons/Apps'
import ApartmentIcon from '@material-ui/icons/Apartment'
import TodayIcon from '@material-ui/icons/Today'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import logoImage from 'assets/images/logo.svg'

const CustomMenu = ({ onMenuClick }) => {
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const { loaded, permissions: { role, applicant, applicantStatus } = {} } = usePermissions()
  const newApplicant = applicantStatus === 'new'
  const isAdmin = role === 'admin' || role === 'manager'
  const isManager = role === 'managerTraining'
  const isUser = role === 'user'
  const isViewer = role === 'viewer'

  const dashboard = (
    <MenuItemLink
      to="/"
      primaryText="Домашняя страница"
      leftIcon={<AppsIcon />}
      onClick={onMenuClick}
      sidebarIsOpen={open}
    />
  )

  const profile = (
    <MenuItemLink
      to="/profile"
      primaryText="Профиль"
      leftIcon={<AssignmentIndIcon />}
      onClick={onMenuClick}
      sidebarIsOpen={open}
    />
  )

  const recordBook = (
    <MenuItemLink
      to="/record-book"
      primaryText="Зачетная книжка"
      leftIcon={<AssignmentIcon />}
      onClick={onMenuClick}
      sidebarIsOpen={open}
    />
  )

  const examSchedule = (
    <MenuItemLink
      to="/exam-schedule"
      primaryText="Расписание экзаменов"
      leftIcon={<TodayIcon />}
      onClick={onMenuClick}
      sidebarIsOpen={open}
    />
  )

  const usersList = (
    <MenuItemLink
      to="/users"
      primaryText="Пользователи"
      leftIcon={<AssignmentIndIcon />}
      onClick={onMenuClick}
      sidebarIsOpen={open}
    />
  )

  const applications = (
    <MenuItemLink
      to={(isAdmin || isManager || isViewer) ? '/applications' : '/applicant/applications'}
      primaryText="Заявки"
      leftIcon={<LibraryBooksIcon />}
      onClick={onMenuClick}
      sidebarIsOpen={open}
    />
  )

  const locations = (
    <MenuItemLink
      to="/locations"
      primaryText="Помещения"
      leftIcon={<ApartmentIcon />}
      onClick={onMenuClick}
      sidebarIsOpen={open}
    />
  )

  const reports = (
    <MenuItemLink
      to="/reports"
      primaryText="Отчеты"
      leftIcon={<AssignmentIcon />}
      onClick={onMenuClick}
      sidebarIsOpen={open}
    />
  )

  const examGroups = (
    <MenuItemLink
      to="/exam-groups"
      primaryText="Список групп"
      leftIcon={<GroupIcon />}
      onClick={onMenuClick}
      sidebarIsOpen={open}
    />
  )

  const logoStyle = {
    padding: 20,
    opacity: open ? 1 : 0
  }

  return loaded ? (
    <>
      <div>
        <img style={logoStyle} src={logoImage} alt="Единая Аттестационная комиссия" />
      </div>
      <div>
        {isUser && !applicant && (
          <>
            {profile}
          </>
        )}
        {isUser && newApplicant && (
          <>
            {dashboard}
            {profile}
          </>
        )}
        {isUser && applicant && !newApplicant && (
          <>
            {dashboard}
            {profile}
            {recordBook}
            {applications}
            {examSchedule}
          </>
        )}
        {isAdmin && (
          <>
            {dashboard}
            {examSchedule}
            {examGroups}
            {usersList}
            {applications}
            {locations}
            {reports}
          </>
        )}
        {isManager && (
          <>
            {dashboard}
            {usersList}
            {applications}
            {examSchedule}
          </>
        )}
        {isViewer && (
          <>
            {dashboard}
            {examSchedule}
            {usersList}
            {applications}
            {locations}
          </>
        )}
      </div>
    </>
  ) : null
}

const Menu = withRouter(CustomMenu)

export default Menu
