import React, { useState } from 'react'
import {
  useDataProvider, FileInput, FileField, useNotify
} from 'react-admin'
import { PageTitle } from 'common'
import { Form } from 'react-final-form'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import downloadFromUrl from 'utils/downloadFromUrl'
import withPermission from '../common/withPermission'

import { format } from 'date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import RuLocalizedUtils from 'utils/dateFnsLocalization'
import ruLocale from 'date-fns/locale/ru'

const useStyles = makeStyles({
  paper: {
    padding: 25
  },
  wrapper: {
    marginBottom: 15
  },
  button: {
    display: 'block',
    marginBottom: 20
  },
  uploadButton: {
    marginTop: 10
  },
  dateWrap: {
    display: 'flex',
    marginBottom: 20,
    alignItems: 'baseline'
  },
  dateWrapItem: {
    display: 'block',
    marginRight: 20
  },
  row: {
    display: 'flex'
  },
  column: {
    width: '300px'
  }
})

const ReportsPage = () => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [date, setDate] = useState('')
  const [dateFrom, setDateFrom] = useState('2020-04-01')
  const [dateTo, setDateTo] = useState(format(new Date(), 'yyyy-MM-dd'))
  const [sending, setSending] = useState(false)

  const downloadReport = source => {
    dataProvider.getOne(source).then(({ data: { url } }) => downloadFromUrl(url))
  }

  /**
   * Скачать переводной файл для экзаменационных работ.
   */
  const downloadTicketsReport = () => {
    setSending(true)
    dataProvider.getOne('report/exams', { data: { dateFrom, dateTo } })
        .then(({ data: { url } }) => downloadFromUrl(url))
        .catch(({ message }) => notify(`Ошибка: ${message.data[0].message}`, 'warning'))
        .finally(() => setSending(false))
  }

  const downloadDateReport = () => {
    dataProvider.getOne('exam-groups/export-appears', { data: { date } })
        .then(({ data: { url } }) => downloadFromUrl(url))
        .catch(({ message }) => notify(`Ошибка: ${message.data[0].message}`, 'warning'))
  }

  const downloadExamResultReport = () => {
    dataProvider.getOne('report/member-results', { data: { date } })
      .then(({ data: { url } }) => downloadFromUrl(url))
      .catch(({ message }) => notify(`Ошибка: ${message.data[0].message}`, 'warning'))
  }

  const onSubmit = upload => {
    const { file: { rawFile } } = upload
    dataProvider.uploadFile('import/balance', { payload: rawFile })
        .then(() => notify('Файл загружен'))
        .catch(({ message }) => notify(`Ошибка: ${message.data[0].message}`, 'warning'))
  }

  /**
   * Отчёт по зарегистрированным претендентам
   */
  const downloadApplicantsReport = () => {
    setSending(true)
    dataProvider.getOne('applicant-reports/registrations', { data: { dateFrom, dateTo } })
        .then(({ data: { url } }) => downloadFromUrl(url))
        .catch(({ message }) => notify(`Ошибка: ${message.data[0].message}`, 'warning'))
        .finally(() => setSending(false))
  }

  /**
   * Отчёт по заявка на экзамен.
   */
  const downloadApplicationsReport = () => {
    setSending(true)
    dataProvider.getOne('applicant-reports/exam-applications', { data: { dateFrom, dateTo } })
        .then(({ data: { url } }) => downloadFromUrl(url))
        .catch(({ message }) => notify(`Ошибка: ${message.data[0].message}`, 'warning'))
        .finally(() => setSending(false))
  }
  /**
   * Скачать отчёт по регионам
   */
  const downloadRegionReport = (levelId = 1) => {
    setSending(true)
    dataProvider.getOne('applicant-reports/regions', { data: { dateFrom, dateTo, levelId } })
        .then(({ data: { url } }) => downloadFromUrl(url))
        .catch(({ message }) => notify(`Ошибка: ${message.data[0].message}`, 'warning'))
        .finally(() => setSending(false))
  }

  return (
      <>
        <PageTitle title="Отчеты" />
        <Paper className={classes.paper}>
          <h2>Отчёт по пришедшим на экзамен</h2>
          <div className={classes.wrapper}>
            <div className={classes.dateWrap}>
              <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale} alwaysOn>
                <DatePicker
                    className={classes.field}
                    inputVariant="filled"
                    format="dd.MM.yyyy"
                    cancelLabel="Отмена"
                    name='date'
                    onChange={e => {
                      const formatDate = format(e, 'yyyy-MM-dd')
                      setDate(formatDate)
                    }}
                    value={date === '' ? null : date}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <Button
                  className={classes.dateWrapItem}
                  color="primary"
                  variant="outlined"
                  disabled={!date || sending}
                  onClick={downloadDateReport}
              >
                Скачать отчёт
              </Button>
            </div>
          </div>
        </Paper>
        <br />

        <Paper className={classes.paper}>
          <h2>Отчёт о результатах сдачи экзаменов</h2>
          <div className={classes.wrapper}>
            <div className={classes.dateWrap}>
              <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale} alwaysOn>
                <DatePicker
                  className={classes.field}
                  inputVariant="filled"
                  format="dd.MM.yyyy"
                  cancelLabel="Отмена"
                  name='date'
                  onChange={e => {
                    const formatDate = format(e, 'yyyy-MM-dd')
                    setDate(formatDate)
                  }}
                  value={date === '' ? null : date}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <Button
                className={classes.dateWrapItem}
                color="primary"
                variant="outlined"
                disabled={!date || sending}
                onClick={downloadExamResultReport}
              >
                Скачать отчёт
              </Button>
            </div>
          </div>
        </Paper>
        <br />

        <Paper className={classes.paper}>
          <div className={classes.wrapper}>
            <Button
                className={classes.button}
                color="primary"
                variant="outlined"
                onClick={() => downloadReport('applicant/export')}
            >
              Скачать отчет для бухгалтерии
            </Button>
            <Button
                className={classes.button}
                color="primary"
                variant="outlined"
                onClick={() => downloadReport('applicant/export-trainings')}
            >
              Скачать отчет по УМЦ
            </Button>
          </div>
        </Paper>
        <br />
        <Paper className={classes.paper}>
          <h2>Загрузить балансы претендентов</h2>
          <div className={classes.wrapper}>
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, pristine, form }) => (
                    <form onSubmit={async e => {
                      await handleSubmit(e)
                      form.reset()
                    }}
                    >
                      <FileInput
                          accept=".txt"
                          label=" "
                          source="file"
                      >
                        <FileField source="src" title="title" />
                      </FileInput>
                      <Button
                          type="submit"
                          color="primary"
                          variant="outlined"
                          className={classes.uploadButton}
                          disabled={pristine}
                      >
                        Загрузить файл
                      </Button>
                    </form>
                )}
            />

          </div>
        </Paper>
        <br />
        <Paper className={classes.paper}>
          <h2>Отчёты по зарегистрированным претендентам/заявкам на экзамен</h2>
          <div className={classes.wrapper}>
            <div className={classes.dateWrap}>
              <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale} alwaysOn>
                <DatePicker
                    className={classes.field}
                    inputVariant="filled"
                    format="dd.MM.yyyy"
                    cancelLabel="Отмена"
                    name='dateFrom'
                    onChange={e => {
                      const formatDate = format(e, 'yyyy-MM-dd')
                      setDateFrom(formatDate)
                    }}
                    value={dateFrom === '' ? null : dateFrom}
                />
              </MuiPickersUtilsProvider>
              <div>&nbsp;</div>
              <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale} alwaysOn>
                <DatePicker
                    className={classes.field}
                    inputVariant="filled"
                    format="dd.MM.yyyy"
                    cancelLabel="Отмена"
                    name='dateTo'
                    onChange={e => {
                      const formatDate = format(e, 'yyyy-MM-dd')
                      setDateTo(formatDate)
                    }}
                    value={dateTo === '' ? null : dateTo}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className={classes.dateWrap}>
              <Button
                  className={classes.dateWrapItem}
                  color="primary"
                  variant="outlined"
                  disabled={!dateFrom || !dateTo || sending}
                  onClick={downloadApplicantsReport}
              >
                Скачать отчёт по зарегистрированным претендентам
              </Button>
              <Button
                  className={classes.dateWrapItem}
                  color="primary"
                  variant="outlined"
                  disabled={!dateFrom || !dateTo || sending}
                  onClick={downloadApplicationsReport}
              >
                Скачать отчёт по заявкам на экзамен
              </Button>
              <Button
                  className={classes.dateWrapItem}
                  color="primary"
                  variant="outlined"
                  disabled={!dateFrom || !dateTo || sending}
                  onClick={() => downloadRegionReport(1)}
              >
                Скачать отчёт по регионам (1 этап)
              </Button>
              <Button
                  className={classes.dateWrapItem}
                  color="primary"
                  variant="outlined"
                  disabled={!dateFrom || !dateTo || sending}
                  onClick={() => downloadRegionReport(2)}
              >
                Скачать отчёт по регионам (2 этап)
              </Button>
              <Button
                  className={classes.button}
                  color="primary"
                  variant="outlined"
                  disabled={!dateFrom || !dateTo || sending}
                  onClick={() => downloadTicketsReport()}
              >
                Скачать переводной файл для экз. работ
              </Button>
            </div>
          </div>
        </Paper>
      </>
  )
}

export default withPermission(['admin', 'manager'])(ReportsPage)
