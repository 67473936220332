import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logoImage from 'assets/images/logo.svg'

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 16px'
  },
  contacts: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 204
  },
  phone: {
    display: 'block',
    color: '#000',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'Roboto',
    textDecoration: 'none'
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logoImage: {
    width: 150,
    height: 'auto'
  },
  copyright: {
    fontSize: '10px',
    lineHeight: '14px',
    fontFamily: 'Roboto',
  }
})

const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <div className={classes.contacts}>
        <div className={classes.phoneNumbers}>
          <a className={classes.phone} href="tel:+74956230129"> + 7 (495) 623-01-29</a>
          <a className={classes.phone} href="tel:+74952213921"> + 7 (495) 221-39-21</a>
        </div>
        <a className={classes.link} href="mailto:support@eak-rus.ru">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM16.5 21H12C7.032 21 3 16.968 3 12C3 7.032 7.032 3 12 3C16.968 3 21 7.032 21 12V13.287C21 15.06 19.623 16.5 17.85 16.5C16.779 16.5 15.771 15.978 15.186 15.177C14.376 15.996 13.242 16.5 12 16.5C9.516 16.5 7.5 14.484 7.5 12C7.5 9.516 9.516 7.5 12 7.5C14.484 7.5 16.5 9.516 16.5 12V13.287C16.5 13.998 17.139 14.7 17.85 14.7C18.561 14.7 19.2 13.998 19.2 13.287V12C19.2 8.094 15.906 4.8 12 4.8C8.094 4.8 4.8 8.094 4.8 12C4.8 15.906 8.094 19.2 12 19.2H16.5V21ZM9.3 12C9.3 13.494 10.506 14.7 12 14.7C13.494 14.7 14.7 13.494 14.7 12C14.7 10.506 13.494 9.3 12 9.3C10.506 9.3 9.3 10.506 9.3 12Z" fill="#7E9AB3" />
          </svg>
        </a>
        <a className={classes.link} href="https://twitter.com/eak_news" target="_blank" rel="noopener noreferrer">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 7.53125C19.4167 7.79167 18.7865 7.96875 18.1094 8.0625C18.8177 7.64583 19.3021 7.03646 19.5625 6.23438C18.8854 6.63021 18.1875 6.89583 17.4688 7.03125C16.8333 6.34375 16.0365 6 15.0781 6C14.1719 6 13.3984 6.32031 12.7578 6.96094C12.1172 7.60156 11.7969 8.375 11.7969 9.28125C11.7969 9.58333 11.8229 9.83333 11.875 10.0312C10.5312 9.95833 9.27083 9.61979 8.09375 9.01562C6.91667 8.41146 5.91667 7.60417 5.09375 6.59375C4.79167 7.11458 4.64062 7.66667 4.64062 8.25C4.64062 9.4375 5.11458 10.349 6.0625 10.9844C5.57292 10.974 5.05208 10.8385 4.5 10.5781V10.6094C4.5 11.3906 4.76042 12.0859 5.28125 12.6953C5.80208 13.3047 6.44271 13.6823 7.20312 13.8281C6.90104 13.9115 6.63542 13.9531 6.40625 13.9531C6.27083 13.9531 6.06771 13.9323 5.79688 13.8906C6.01562 14.5469 6.40365 15.0885 6.96094 15.5156C7.51823 15.9427 8.15104 16.1615 8.85938 16.1719C7.65104 17.1094 6.29167 17.5781 4.78125 17.5781C4.51042 17.5781 4.25 17.5625 4 17.5312C5.54167 18.5104 7.21875 19 9.03125 19C10.1979 19 11.2917 18.8151 12.3125 18.4453C13.3333 18.0755 14.2083 17.5807 14.9375 16.9609C15.6667 16.3411 16.2943 15.6276 16.8203 14.8203C17.3464 14.013 17.737 13.1693 17.9922 12.2891C18.2474 11.4089 18.375 10.5312 18.375 9.65625C18.375 9.46875 18.3698 9.32812 18.3594 9.23438C19.0156 8.76562 19.5625 8.19792 20 7.53125ZM24 4.5V19.5C24 20.7396 23.5599 21.7995 22.6797 22.6797C21.7995 23.5599 20.7396 24 19.5 24H4.5C3.26042 24 2.20052 23.5599 1.32031 22.6797C0.440104 21.7995 0 20.7396 0 19.5V4.5C0 3.26042 0.440104 2.20052 1.32031 1.32031C2.20052 0.440104 3.26042 0 4.5 0H19.5C20.7396 0 21.7995 0.440104 22.6797 1.32031C23.5599 2.20052 24 3.26042 24 4.5Z" fill="#7E9AB3" />
          </svg>
        </a>
        <a className={classes.link} href="https://www.facebook.com/EAKnews" target="_blank" rel="noopener noreferrer">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.38226 0C1.95468 0 0 1.95468 0 4.38226V19.6178C0 22.0453 1.95468 24 4.38226 24H12.6398V14.6175H10.1588V11.2395H12.6398V8.35351C12.6398 6.08611 14.1057 4.00426 17.4825 4.00426C18.8497 4.00426 19.8608 4.13551 19.8608 4.13551L19.7813 7.29002C19.7813 7.29002 18.7501 7.28028 17.625 7.28028C16.4073 7.28028 16.212 7.84135 16.212 8.77279V11.2395H19.878L19.7183 14.6175H16.212V24H19.6177C22.0453 24 24 22.0454 24 19.6178V4.38228C24 1.9547 22.0453 2.4e-05 19.6177 2.4e-05H4.38223L4.38226 0Z" fill="#7E9AB3" />
          </svg>
        </a>
      </div>
      <div className={classes.logo}>
        <a href="https://eak-rus.ru/">
          <img className={classes.logoImage} src={logoImage} alt="Единая Аттестационная комиссия" />
        </a>
      </div>
      <p className={classes.copyright}>© ЕАК, 2020. Все права защищены</p>
    </footer>
  )
}

export default Footer
