import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  buttonEdit: {
    marginTop: 20,
  },
  button: {
    marginTop: 20,
    marginLeft: 20,
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between'
  },
})

const ExamTableControl = ({
  isEditable,
  isMove,
  handleClickCancel,
  handleClickEdit,
  handleMoveGroup,
  handleFormAGroup,
  setIsMove,
  membersCount,
  moveMemberListCount
}) => {
  const classes = useStyles()

  return (
    <>
      {(isEditable || isMove) ? (
        <div className={classes.wrap}>
          <Button
            variant="outlined"
            className={classes.buttonEdit}
            color="primary"
            onClick={handleClickCancel}
          >
            Отменить
          </Button>

          {isEditable && (
            <Button
              variant="contained"
              className={classes.buttonEdit}
              color="primary"
              onClick={handleFormAGroup}
            >
              Сформировать группу
            </Button>
          )}

          {isMove && (
            <Button
              variant="contained"
              className={classes.buttonEdit}
              color="primary"
              onClick={handleMoveGroup}
              disabled={moveMemberListCount === 0}
            >
              Перенести в другую группу
            </Button>
          )}
        </div>
      ) : (
          <>
            <Button
              variant="outlined"
              className={classes.buttonEdit}
              color="primary"
              onClick={handleClickEdit}
            >
              Редактирование группы
            </Button>
            <Button
              variant="outlined"
              className={classes.button}
              color="primary"
              onClick={setIsMove}
              disabled={membersCount === 0}
            >
              Перенести в другую группу
            </Button>
          </>
        )}
    </>
  )
}

export default ExamTableControl
