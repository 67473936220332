import React, { useState } from 'react'
import {
  Edit, useNotify, useRedirect, useMutation
} from 'react-admin'
import { PageTitle } from 'common'
import { makeStyles } from '@material-ui/core/styles'
import PageSubtitle from 'common/PageSubtitle'
import GroupMainInfo from 'components/group/GroupMainInfo'
import GroupApplicants from 'components/group/GroupApplicants'
import GroupMessageForm from 'components/group/GroupMessageForm'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import withPermission from '../common/withPermission'


const useStyles = makeStyles({
  subtitle: {
    padding: 24
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  deleteButton: {
    alignSelf: 'flex-end'
  },
  btnsWrap: {
    display: 'flex',
    marginTop: 20,
  },
  btnBottom: {
    width: 'auto', 
    marginRight: 10,
    '&:last-child': {
      marginRight: 0
    }
  }
})

const GroupEdit = props => {
  const classes = useStyles()
  const redirect = useRedirect()
  const notify = useNotify()
  const [mutate] = useMutation()
  const [isShowMoveDialog, setIsShowMoveDialog] = useState(false)
  const [aplicantsList, setAplicantsList] = useState([])
  const [isShowFormMessage, setIsShowFormMessage] = useState(false)
  const [loadingSendMessage, setLoadingSendMessage] = useState(false)

  const { id: groupId } = props

  const deleteGroup = () => {
    mutate({
      type: 'delete',
      resource: 'exam-groups',
      payload: { id: groupId }
    }, {
      onSuccess: () => {
        notify('Группа удалена')
        redirect('/exam-groups')
      },
      onFailure: err => {
        notify(err.message.data[0].message, 'warning')
      }
    })
  }

  const toggleVisibleMessageForm = () => setIsShowFormMessage(!isShowFormMessage)

  return (
    <>
      <div className={classes.titleWrapper}>
        <PageTitle title="Информация о группе" />
        <Button
          onClick={deleteGroup}
          variant="text"
          color="primary"
          startIcon={<DeleteIcon />}
          className={classes.deleteButton}
        >
          Удалить группу
        </Button>
      </div>
      <Edit
        actions={null}
        {...props}
      >
        <GroupMainInfo
          isShowMoveDialog={isShowMoveDialog}
          setIsShowMoveDialog={setIsShowMoveDialog}
          aplicantsList={aplicantsList}
        />
      </Edit>
      <PageSubtitle title="Претенденты на экзамен" className={classes.subtitle} />
      <GroupApplicants
        groupId={groupId}
        aplicantsList={aplicantsList}
        setAplicantsList={setAplicantsList}
      />
      <div className={classes.btnsWrap}>
        <Button className={classes.btnBottom} variant="outlined" color="primary" onClick={() => { setIsShowMoveDialog(true) }}>
          Перенести
        </Button>
        <Button 
          className={classes.btnBottom} 
          variant="outlined" 
          color="primary"
          onClick={toggleVisibleMessageForm}
          disabled={loadingSendMessage}
        >
          { isShowFormMessage ? 'Скрыть форму' : 'Отправить сообщение' }
        </Button>
      </div>
      
      { isShowFormMessage && 
        <GroupMessageForm 
          aplicantsList={aplicantsList}
          setAplicantsList={setAplicantsList}
          loadingSendMessage={loadingSendMessage}
          setLoadingSendMessage={setLoadingSendMessage}
        /> 
      }
    </>
  )
}

export default withPermission(['admin', 'manager'])(GroupEdit)

