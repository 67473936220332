import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

const useStyles = makeStyles({
  snackbar: {
    width: 630,
  }
})

const Alert = ({
  type, message, handleClose, duration = 5000, backgroundColor = ''
}) => {
  const classes = useStyles()

  return (
    <Snackbar className={classes.snackbar} open autoHideDuration={duration} onClose={() => handleClose(type)}>
      <MuiAlert style={{ backgroundColor, alignItems: 'center' }} severity={type} variant="filled" onClose={() => handleClose(type)}>
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

export default Alert
