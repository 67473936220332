import React from 'react'
import { useQueryWithStore, Loading, Error } from 'react-admin'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExamApplicationNotificationItem from './ExamApplicationNotificationItem'

const ExamApplicationNotification = ({ moduleId, userId }) => {
  const { loaded, error, data: notificationsList } = useQueryWithStore({
    type: 'getList',
    resource: 'applicant/notifications',
    payload: {
      filter: {
        moduleId,
        userId
      }
    }
  })

  if (!loaded) return <Loading />
  if (error) return <Error />

  return (
    <ExpansionPanelDetails>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Наименование</TableCell>
            <TableCell align="right">Дата</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notificationsList.map(({ id, ...notificationData }) => (
            <ExamApplicationNotificationItem key={id} {...notificationData} />
          ))}
        </TableBody>
      </Table>
    </ExpansionPanelDetails>
  )
}

export default ExamApplicationNotification
