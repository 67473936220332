import React from 'react'

import { useParams } from 'react-router-dom'

import { useAuthenticated } from 'react-admin'

import { PageTitle } from 'common'
import ApplicantForm from 'components/ApplicantForm'

const ApplicantEdit = () => {
  useAuthenticated()
  const { applicantId } = useParams()

  return (
    <>
      <PageTitle title="Анкета претедента" />
      <ApplicantForm id={applicantId} />
    </>
  )
}

export default ApplicantEdit
