import React from 'react'
import { useQueryWithStore, useNotify, useCreate } from 'react-admin'
import FormEditProfile from 'components/profile-editing/FormEditProfile'

const AdminActionButtons = ({ setIsOpenDialog, handleCloseDialog }) => {
  const [create] = useCreate()
  const notify = useNotify()

  const { data: roles } = useQueryWithStore({
    type: 'getList',
    resource: 'roles'
  })

  const { data: managers = [] } = useQueryWithStore({
    type: 'getList',
    resource: 'users',
    payload: {
      pagination: {
        perPage: 1000
      },
      filter: { role: 'managerTraining' }
    }
  })

  const { data: regions } = useQueryWithStore({
    type: 'getList',
    resource: 'regions'
  })


  const onSubmit = async values => {
    create({
      type: 'create',
      resource: 'applicant/invite-user',
      payload: { data: values }
    },
      {
        onSuccess: () => {
          handleCloseDialog()
          notify('Данные сохранены')
        },
        onFailure: ({ message }) => {
          notify(`Ошибка: ${message.data[0].message}`, 'warning')
        }
      })
  }

  return (
    <FormEditProfile
      managers={managers}
      onSubmit={onSubmit}
      roles={roles}
      setIsEditable={setIsOpenDialog}
      isAdding
      regions={regions}
    />
  )
}

export default AdminActionButtons
