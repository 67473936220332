import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Field } from 'react-final-form'
import { useLogin, useDataProvider, useNotify } from 'react-admin'
import { CustomTextInput } from 'common'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import * as Yup from 'yup'
import validate from 'utils/validate'
import Typography from '@material-ui/core/Typography'
import Alert from './Alert'

const schema = Yup.object().shape({
  password: Yup.string()
    .required('Обязательное поле'),
  email: Yup.string()
    .email('Некорректный адрес электронной почты')
    .required('Обязательное поле'),
})

const useStyles = makeStyles({
  inputField: {
    display: 'block',
    marginBottom: 5,
    '&:first-of-type': {
      marginBottom: '40px'
    },
    height: 60
  },
  form: {
    width: 290,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 35
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: 15,
    marginBottom: 15
  },
  button: {
    padding: '16px 0'
  },
  forgotPassword: {
    padding: '0',
    textTransform: 'none',
    color: '#2196f3',
    font: 'inherit',
    marginBottom: '3px',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    }
  },
  registerWrapper: {
    position: 'relative',
    paddingTop: 25,
    textAlign: 'center',
    width: '100%',
    '&:before': {
      position: 'absolute',
      content: "''",
      width: 248,
      height: 1,
      left: '50%',

      transform: 'translateX(-50%)',
      top: 0,
      backgroundColor: 'black'
    }
  },
  noAcc: {
    marginBottom: 10
  },
  messageText: {
    display: 'inline'
  },
  sendLink: {
    width: 161,
    margin: '0 auto',
    textTransform: 'none',
    color: 'white',
    '& span': {
      fontFamily: 'inherit'
    }
  }
})

const LoginForm = ({ setPassFormVisible }) => {
  const classes = useStyles()
  const login = useLogin()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [errorMessage, setErrorMessage] = useState('')
  const [emailConfirmMessage, setEmailConfirmMessage] = useState(null)

  let userEmail

  const onSubmit = ({ email, password }) => {
    userEmail = email
    login({ email, password })
      .catch(error => {
        const { message, field } = error.message.data[0]
        if (field === 'email') {
          setEmailConfirmMessage(warningMessage)
        } else if (field === 'password') {
          setErrorMessage(message)
        } else {
          setErrorMessage('Что-то пошло не так, повторите запрос.')
        }
      })
  }

  const handleSendClick = () => {
    dataProvider.create('site/send-verify', { data: { email: userEmail } })
    setEmailConfirmMessage(null)
    notify('Письмо отправлено', 'info')
  }

  const warningMessage = (
    <>
      <Typography className={classes.messageText}>
        Вы не подтвердили свою почту.
        <br />
      Не пришло письмо со ссылкой для подтверждения?
      </Typography>
      <Button className={classes.sendLink} onClick={handleSendClick}>Отправить ещё раз</Button>
    </>
  )

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={values => validate(values, schema)}
        render={({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className={classes.form}
          >
            <Field
              name="email"
              component={CustomTextInput}
              label="Электронная почта"
              className={classes.inputField}
              variant="outlined"
              color="secondary"
              fullWidth
            />
            <Field
              name="password"
              type="password"
              component={CustomTextInput}
              label="Пароль"
              className={classes.inputField}
              variant="outlined"
              color="secondary"
              fullWidth
            />
            <div className={classes.wrapper}>
              <Button
                className={classes.forgotPassword}
                color="secondary"
                component="button"
                onClick={() => setPassFormVisible(true)}
              >
                Забыли пароль?
              </Button>
            </div>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              color="primary"
              className={classes.button}
            >
              Войти
            </Button>
          </form>
        )}
      />
      <div className={classes.registerWrapper}>
        <Typography className={classes.noAcc}>Нет аккаунта?</Typography>
        <Link
          href="/#/register"
          color="secondary"
        >
          Зарегистрируйтесь
        </Link>
      </div>

      {errorMessage && (
        <Alert
          type="error"
          message={errorMessage}
          handleClose={() => setErrorMessage('')}
        />
      )}
      {emailConfirmMessage && (
        <Alert
          type="warning"
          message={emailConfirmMessage}
          duration={null}
          handleClose={() => setEmailConfirmMessage(null)}
        />
      )}
    </>
  )
}

export default LoginForm
