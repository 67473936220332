import React from 'react'
import { useHistory } from 'react-router-dom'

import { useQueryWithStore, Loading, Error, } from 'react-admin'

import {
  FormattedDateField
} from 'common'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles({
  subtitle: {
    padding: 20
  },
  timeColumn: {
    width: 164
  },
  dateColumn: {
    width: 200
  },
  activeGroup: {
    background: '#f0edf3'
  }
})

const ApplicationsExamList = ({ moduleId, examId }) => {
  const classes = useStyles()
  const history = useHistory()

  const { loaded, error, data } = useQueryWithStore({
    type: 'getList',
    resource: 'groups',
    payload: {
      pagination: { perPage: 200 },
      filter: {
        id: examId,
        moduleId,
        statusId: 2 // published
      }
    }
  })

  const chageLocation = id => history.push(`/exam-booking/${id}`)

  const intExamId = parseInt(examId, 0)

  if (!loaded) { return <Loading /> }
  if (error) { return <Error /> }

  return (
    <>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.dateColumn}>Дата</TableCell>
              <TableCell>Город</TableCell>
              <TableCell align="left" className={classes.timeColumn}>Время начала</TableCell>
              <TableCell align="left">Область знаний</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map(({
              id,
              examDate,
              examTimeStart,
              city,
              modules: { 0: { name } }
            }) => (
                <TableRow style={{ cursor: 'pointer' }} className={intExamId === id ? classes.activeGroup : ''} key={id} onClick={() => chageLocation(id)}>
                  <TableCell className={classes.dateColumn} component="th" scope="row">
                    <FormattedDateField date={examDate} formatting="dd MMMM yyyy" />
                  </TableCell>
                  <TableCell align="left">{city ? city.name : 'Не определено'}</TableCell>
                  <TableCell align="left" className={classes.timeColumn}>
                    {examTimeStart ? examTimeStart.slice(0, -3) : 'Не определено'}
                  </TableCell>
                  <TableCell align="left">{name}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export default ApplicationsExamList
