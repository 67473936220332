import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FileInput,
  FileField,
  FunctionField,
  BooleanInput
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import ButtonBlock from './ButtonBlock'
import getArrayYears from '../../utils/getArrayYears'

const UploadPlaceholder = () => (
  <>
    <p>Кликните, чтобы загрузить файлы: скан документа об образовании; скан документа об иностранном образовании с переводом; в случае изменения фамилии — подтверждающий документ.</p>
    <p>Разрешены форматы .pdf, .jpeg, .png. Максимальный размер файла — 20мб.</p>
  </>
)

const useStyles = makeStyles({
  title: {
    paddingBottom: 24
  },
  fieldLong: {
    width: 600,
    marginRight: 20,
  },
  foreignNumber: {
    width: 779
  },
  field: {
    width: 290,
    marginRight: 20,
  },
  button: {
    height: 40,
    marginLeft: 20,
    marginTop: 12,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20
  },
  checkbox: {
  },
  img: {
    width: 'auto',
    height: 200,
    objectFit: 'cover'
  },
  buttonUpload: {
    width: 430,
    '& > div': {
      '& > div': {
        backgroundColor: '#fff',
        padding: 0,
      }
    }
  },
  fileLink: {
    textDecoration: 'none'
  },
  linkText: {
    marginLeft: 5,
    marginBottom: 5,
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  fileWrapper: {
    display: 'flex',
    alignItems: 'center'
  }
})

const FormEditEducations = ({
  educations,
  qualifications,
  setIsEditable,
  onSubmit,
  loading,
  setLoading
}) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()

  const UploadedFile = record => {
    const { file, rawFile } = record
    const fileName = file ? file.split('/').pop() : rawFile.name
    const source = rawFile ? 'src' : 'file'

    return (
      <FileField record={record} source={source} title={fileName} />
    )
  }


  const uploadFile = async (eduIndex, file) => {
    setLoading(true)
    const response = await dataProvider.uploadFile('site/attach', { payload: file })
    const { data: { filename } } = response
    return { eduIndex, file: filename }
  }

  const saveData = async values => {
    const promiseList = []

    // сделать один массив из всех файлов
    const inputFiles = values.educations.reduce((acc, { files }, eduIndex) => {
      if (files) {
        const filesArr = files.map(file => ({ file, eduIndex }))
        acc = [...acc, ...filesArr]
      }
      return acc
    }, [])

    // загрузить нужные файлы
    if (inputFiles.length > 0) {
      inputFiles.forEach(({ file, eduIndex }) => {
        // проверка, что у файла нет id, т.е. это новый файл.
        // у файлов, загруженных ранее, есть id и их не надо снова загружать
        if (!file.id) {
          const upload = file instanceof File ? file : file.rawFile
          promiseList.push(uploadFile(eduIndex, upload))
        }
      })
    }

    if (promiseList.length > 0) {
      Promise.all(promiseList).then(results => {
        // убрать raw-файлы, загруженные в форму и оставить только те объекты файлов, которые приехали с бека
        values.educations.forEach(edu => {
          if (edu.files && edu.files.length > 0) {
            const filteredFiles = edu.files.filter(file => !file.rawFile)
            edu.files = filteredFiles
          } else {
            edu.files = []
          }
        })
        results.forEach(({ eduIndex, file }) => {
          values.educations[eduIndex].files.push({ file })
        })
        onSubmit(values)
      }, error => {
        console.log(error, 'error')
      })
    } else {
      onSubmit(values)
    }
  }

  return (
    <>
      <Form
        initialValues={{
          educations
        }}
        onSubmit={saveData}
        mutators={{
          ...arrayMutators
        }}
        render={({
          handleSubmit,
          form: {
            mutators: { push }
          },
        }) => (
            <form onSubmit={handleSubmit}>
              <Typography
                variant="h6"
                className={classes.title}
              >
                Редактирование сведений о высшем образовании
            </Typography>
              <FieldArray name="educations">
                {({ fields }) => fields.map((name, index) => (
                  <div key={name}>
                    <div>
                      <BooleanInput label="Высшее образование получено в другом государстве" name={`${name}.foreignEducation`} />
                    </div>
                    {!!fields?.value[index]?.foreignEducation && (
                      <TextInput
                        className={classes.foreignNumber}
                        disabled={!fields.value[index].foreignEducation}
                        label="№ свидетельства «Минобрнауки России» о признании иностранного документа об образовании"
                        name={`${name}.foreignNumber`}
                      />
                    )}
                    <div>
                      <TextInput
                        className={classes.fieldLong}
                        name={`${name}.university`}
                        label="Наименование ВУЗа (как указано в дипломе)"
                        validate={required()}
                        multiline
                      />
                      <SelectInput
                        label="Год окончания"
                        name={`${name}.yearGraduation`}
                        choices={getArrayYears()}
                        validate={required()}
                      />
                    </div>
                    <div>
                      <TextInput
                        resource="locations"
                        className={classes.field}
                        name={`${name}.diploma`}
                        label="Серия и номер диплома"
                        validate={required()}
                      />
                      <TextInput
                        className={classes.field}
                        name={`${name}.numberRegistration`}
                        label="Регистрационный номер диплома"
                        validate={required()}
                      />
                    </div>
                    <div>
                      <SelectInput
                        className={classes.field}
                        label="Квалификация"
                        name={`${name}.qualificationId`}
                        choices={qualifications}
                        validate={required()}
                      />
                      <TextInput
                        className={classes.field}
                        name={`${name}.specializations`}
                        label="Специальность"
                        validate={required()}
                        multiline
                      />
                    </div>
                    <FileInput
                      accept="image/jpeg,image/png,application/pdf"
                      label=""
                      multiple
                      placeholder={<UploadPlaceholder />}
                      source={`${name}.files`}
                      className={classes.uploadField}
                    >
                      <FunctionField
                        source="src"
                        render={record => <UploadedFile {...record} />}
                      />
                    </FileInput>
                    <Divider className={classes.divider} />
                  </div>
                ))}
              </FieldArray>
              <Button
                color="primary"
                onClick={() => push('educations', undefined)}
              >
                Добавить высшее образование
            </Button>
              <ButtonBlock
                handleClickAccept={handleSubmit}
                handleClickCancel={() => setIsEditable(false)}
                disabled={loading}
              />
            </form>
          )}
      />
    </>
  )
}

export default FormEditEducations
