import React, { useState } from 'react'
import {
  ImageField,
  ImageInput,
  minValue,
  number,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useRedirect,
  AutocompleteInput
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Paper from '@material-ui/core/Paper'
import { PageSubtitle } from 'common'

const useStyles = makeStyles({
  subtitle: {
    width: 500,
    paddingLeft: 10,
  },
  paper: {
    width: 'auto',
    padding: 24
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent'
  },
  button: {
    marginRight: 20,
  },
  wrapper: {
    display: 'flex',
    '&:first-child': {
      marginBottom: 20,
    }
  },
  infoWrapper: {
    width: '55%',
    marginRight: 20
  },
  imageInput: {
    width: '40%',
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  city: {
    width: 228,
    display: 'flex'
  },
  place: {
    width: '100%'
  },
  address: {
    width: '100%'
  },
  room: {
    width: 200,
    marginRight: 20
  },
})

const FormToolbar = ({
  classes,
  disabled,
  setIsEditable,
  ...props
}) => (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton
        handleSubmitWithRedirect={() => setIsEditable(false)}
        icon={<ArrowBackIcon />}
        label="Назад"
        variant="outlined"
      />
      <SaveButton
        label="Сохранить"
      />
    </Toolbar>
  )

const LocationEdit = ({ record, save, setIsEditable }) => {
  const classes = useStyles()
  const {
    id, cityId, place, address, room, image, countPlaces,
  } = record

  const dataProvider = useDataProvider()
  const [newImage, setNewImage] = useState()
  const redirectTo = useRedirect()

  const saveImage = async () => {
    const response = await dataProvider.uploadFile('upload/image', { payload: newImage })
    const { data } = response
    return data
  }

  const saveData = async values => {
    let allFormValues = {
      ...values
    }

    if (newImage) {
      const url = await saveImage()
      allFormValues = { ...allFormValues, image: url }
    }

    save(allFormValues)
    setIsEditable(false)
    redirectTo(`/locations/${id}/show`)
  }

  const ButtonImage = () => (
    <div className={classes.imageWrapper}>
      {image
        ? <img src={image} alt="Карта" className={classes.img} />
        : 'Нажмите, чтобы загрузить изображение'}
    </div>
  )

  return (
    <SimpleForm
      toolbar={<FormToolbar classes={classes} setIsEditable={setIsEditable} />}
      save={saveData}
    >
      <PageSubtitle className={classes.subtitle} title="Редактирование помещения" />
      <Paper className={classes.paper}>
        <div className={classes.wrapper}>
          <div className={classes.infoWrapper}>
            <ReferenceInput
              resource="locations"
              source="cityId"
              reference="cities"
              defaultValue={cityId}
              label="Город"
              className={classes.city}
              filterToQuery={searchText => ({ name: searchText })}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <TextInput
              resource="locations"
              source="place"
              defaultValue={place}
              label="Место проведения"
              className={classes.place}
              margin="normal"
              validate={required()}
            />
            <TextInput
              defaultValue={address}
              resource="locations"
              source="address"
              label="Адрес"
              className={classes.address}
              margin="normal"
              validate={required()}
            />
            <TextInput
              defaultValue={room}
              resource="locations"
              source="room"
              label="Аудитория"
              className={classes.room}
              margin="normal"
              validate={required()}
            />
            <TextInput
              defaultValue={countPlaces}
              resource="locations"
              source="countPlaces"
              label="Количество мест"
              margin="normal"
              validate={[required(), number(), minValue(1)]}
            />
          </div>
          <ImageInput
            className={classes.imageInput}
            onChange={img => setNewImage(img)}
            source="image"
            label=" "
            accept="image/jpeg,image/png"
            maxSize={5242880}
            placeholder={<ButtonImage />}
          >
            <ImageField source="src" classes={classes} />
          </ImageInput>
        </div>
      </Paper>
    </SimpleForm>
  )
}

export default LocationEdit
