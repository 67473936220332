// https://material-ui-pickers.dev/localization/date-fns
import format from 'date-fns/format'
import DateFnsUtils from '@date-io/date-fns'

class RuLocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'LLLL', { locale: this.locale })
  }

  getDatePickerHeaderText(date) {
    return format(date, 'dd MMMM', { locale: this.locale })
  }
}

export default RuLocalizedUtils
