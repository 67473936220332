import React from 'react'
import NotificationsTable from './NotificationsTable'
import UserInfoManager from './UserInfoManager'
import UsersTable from './UsersTable'

const ManagerTrainingsPage = ({ profileData, RAListProps }) => {
  const { id } = profileData
  return (
    <>
      <UserInfoManager {...profileData} />
      <NotificationsTable RAListProps={RAListProps} />
      <UsersTable filter={{ managerId: id }} isManagerTraining RAListProps={RAListProps} />
    </>
  )
}

export default ManagerTrainingsPage
