
import React, { useState } from 'react'

import {
  Link
} from 'react-router-dom'

import {
  useQueryWithStore,
  useDataProvider,
  useNotify,
  Loading,
} from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CertiticateModal from "./CertiticateModal";

const useStyles = makeStyles({
  button: {
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)'
  },
  buttonApplicant: {
    marginLeft: '20px'
  }
})

const ExamApplicationApply = ({ passedExamsList, isOldRegNumber, applicantId }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const allPassedExam = passedExamsList.length > 0

  const { data: applications, loaded } = useQueryWithStore({
    type: 'getList',
    resource: applicantId ? 'applications' : 'applicant/applications',
    payload: {
      filter: {
        applicantId,
        typeId: 3,
        statusId: [1, 2, 4]
      },
    }
  })

  if (!loaded) return <Loading />

  const isDisableAutoPass = allPassedExam || !isOldRegNumber || applications.length

  const sendAutopass = e => {
    if (isDisableAutoPass) e.preventDefault()
  }

  const userId = localStorage.getItem('userId')

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        disabled={!allPassedExam || loading}
        onClick={() => {
          setShowModal(true)
        }}
      >
        Заявка на сертификат/свидетельство
      </Button>
      <CertiticateModal
          showModal={showModal}
          setShowModal={setShowModal}
      />
      <Link style={{ textDecoration: 'none', marginLeft: '20px' }} onClick={sendAutopass} to="/autopass">
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          disabled={isDisableAutoPass}
        >
          Заявка на зачет
        </Button>
      </Link>
    </div>
  )
}

export default ExamApplicationApply
