import React from 'react'
import {
  Datagrid,
  List,
  FunctionField,
  TextField
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Empty, PageSubtitle, FormattedDateField } from 'common'

const useStyles = makeStyles({
  row: {
    height: 60,
  },
  subtitle: {
    marginTop: 26,
  },
})


const NotificationsTable = ({ filter, RAListProps }) => {
  const classes = useStyles()

  return (
    <>
      <PageSubtitle title="Уведомления" className={classes.subtitle} />
      <List
        {...RAListProps}
        actions={null}
        basePath="notifications"
        bulkActionButtons={false}
        empty={<Empty title="Уведомления отсутствуют" />}
        filter={filter}
        hasCreate
        hasEdit={false}
        hasList={false}
        hasShow={false}
        pagination={null}
        perPage={5}
        resource="notifications"
        title=" "
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <Datagrid classes={{ row: classes.row }}>
          <TextField
            label="Наименование"
            source="name"
          />
          <FunctionField
            label="Дата"
            render={({ createdAt }) => <FormattedDateField date={createdAt} formatting="dd MMMM yyyy" />}
          />
        </Datagrid>
      </List>
    </>
  )
}

export default NotificationsTable
