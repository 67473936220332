import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'


import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import AdminActionButtons from './AdminActionButtons'
import TrainingManagerActionButtons from './TrainingManagerActionButtons'

const useStyles = makeStyles({
  button: {
    width: 245,
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20
  },
})

const ActionButtons = ({ isAdmin }) => {
  const classes = useStyles()
  const [isOpenDialog, setIsOpenDialog] = useState(false)

  const handleOpenDialog = () => setIsOpenDialog(true)

  const handleCloseDialog = () => setIsOpenDialog(false)

  return (

    <>
      <div className={classes.buttonWrap}>
        <Button
          className={classes.button}
          color="primary"
          onClick={handleOpenDialog}
          variant="contained"
        >
          {isAdmin ? 'Добавить пользователя' : 'Пригласить пользователя'}
        </Button>
      </div>
      {isOpenDialog && (
        <Dialog
          open={isOpenDialog}
          onClose={handleCloseDialog}
        >
          <DialogContent>
            <DialogTitle>{isAdmin ? 'Добавить пользователя' : 'Отправить приглашение пользователю'}</DialogTitle>
            <>
              {isAdmin ? (
                <AdminActionButtons
                  isOpenDialog={isOpenDialog}
                  setIsOpenDialog={setIsOpenDialog}
                  handleCloseDialog={handleCloseDialog}
                />
              ) : (
                  <TrainingManagerActionButtons
                    isOpenDialog={isOpenDialog}
                    setIsOpenDialog={setIsOpenDialog}
                    handleCloseDialog={handleCloseDialog}
                  />
                )}
            </>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default ActionButtons
