import React, { useState, useEffect } from 'react'

import { Empty, Status, FullName } from 'common'

import {
  useDataProvider
} from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import InfiniteScroll from "react-infinite-scroll-component";

const headCells = [
  { id: 'checkbox', label: '', disabledSort: true },
  { id: 'surname', label: 'ФИО', disabledSort: false },
  { id: 'registration', label: 'Зарегистрирован', disabledSort: true },
  { id: 'status', label: 'Статус оплаты', disabledSort: true },
  { id: 'regNumber', label: 'Рег. №' }
]

const useStyles = makeStyles({
  date: {
    fontWeight: 'bold',
  },
  editCell: {
    padding: 0,
  },
  textRed: {
    color: 'rgba(255, 0, 0, 0.87)',
  },
  textGray: {
    color: 'rgba(0, 0, 0, 0.56)',
  },
  buttonEdit: {
    marginTop: 20,
  },
  button: {
    marginTop: 20,
    marginLeft: 20,
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  loadMore: {
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 20,
    color: 'rgba(0, 0, 0, 0.5)'
  }
})

const ApplicantsList = ({
  addList, setAddList, managerId
}) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const [data, setData] = useState({})
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('examDate')
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(30)
  const [hasMore, setHasMore] = useState(true)

  const requestData = property => {
    const request = `applicant/users?filter={"status": "approve", "managerId":"${managerId}"}&per-page=${perPage}&sort=${order === 'asc' ? '' : '-'}${property || ''}`
    return dataProvider.getOne(request)
      .then(({ data: responseData }) => {
        setData(responseData)
      })
  }

  const loadMoreData = async (perPage = 30, page = 0, order, property) => {
    const { data: responseData } = await dataProvider.getOne(
      `applicant/users?filter={"status": "approve", "managerId":"${managerId}"}&per-page=${perPage}&page=${page + 1}&sort=${order === 'desc' ? '' : '-'}${property}`
    )

    return setData({
      ...data,
      models: [...data.models, ...responseData.models]
    })
  }

  useEffect(() => {
    requestData()
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setPerPage(30)

    if (!hasMore) setHasMore(true)
    
    requestData(property, isAsc)
  }

  const handleChangeMoreData = () => {
    const pageCount = page + 1
    const remainingData = data.total - data.models.length
    
    let numberPage = perPage

    if (remainingData < perPage) {
      setHasMore(false)
    }

    setPage(pageCount)
    loadMoreData(numberPage, pageCount, order, orderBy)
  }

  const formationGroup = (e, id) => {
    const {
      target: {
        checked
      }
    } = e

    if (checked) {
      setAddList([...addList, id])
    } else {
      const index = addList.indexOf(id)
      addList.splice(index, 1)
      setAddList([...addList])
    }
  }

  const EnhancedTableHead = () => {
    const createSortHandler = property => event => {
      handleRequestSort(event, property)
    }

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              className={classes.textGray}
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                disabled={headCell.disabledSort}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  const getRows = () => {
    const { models } = data

    return models.map((row, index) => {
      const {
        applicant: { id, regNumber } = {},
        createdAt,
        status
      } = row || {}

      return (
        <TableRow key={id} data-index={index}>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              onChange={e => formationGroup(e, id)}
              checked={addList.includes(id)}
            />
          </TableCell>
          <TableCell><FullName data={row} /></TableCell>
          <TableCell>{createdAt}</TableCell>
          <TableCell><Status status={status} /></TableCell>
          <TableCell>{regNumber}</TableCell>
        </TableRow>
      )
    })
  }

  return (
    <>
      <Paper>
        {data.models && data.models.length ? (
          <>
            <TableContainer>
              <InfiniteScroll
                dataLength={data.models.length}
                next={handleChangeMoreData}
                hasMore={hasMore}
                loader={<div className={classes.loadMore}>Загрузка...</div>}
                scrollThreshold={0.3}
              >
                <Table>
                  <EnhancedTableHead />
                  <TableBody>
                    {getRows()}
                  </TableBody>
                </Table>
              </InfiniteScroll>
            </TableContainer>
          </>
        ) : (<Empty title="Претенденты отсутствуют" />)}
      </Paper>
    </>
  )
}

export default ApplicantsList
