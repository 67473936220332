import React, { useState } from 'react'
import { Form } from 'react-final-form'
import {
  useDataProvider, useQueryWithStore, Loading, Error, SelectInput, TextInput, useNotify, useRefresh,
} from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'

import getById from 'utils/getById'

const useStyles = makeStyles({
  paper: {
    position: 'relative',
    padding: 24
  },
  wrap: {
    display: 'flex',
  },
  wrapInput: {
    display: 'flex',
    marginTop: '8px',
    marginBottom: '4px'
  },
  fieldLong: {
    width: 200,
    marginRight: 20,
  },
  fieldMiddle: {
    width: 300,
    marginRight: 20,
  },
  fieldShort: {
    width: 200,
    marginRight: 20,
  },
  buttonCreate: {
    position: 'absolute',
    right: 20,
    bottom: 18,
  },
  buttonDelete: {
    position: 'absolute',
    right: 20,
    top: 34,
  },
  dialog: {
    width: '550px',
    overflowY: 'hidden'
  },
})

const ExamMoveDialog = ({
  moduleId,
  originalExamId,
  moveMemberIdList,
  isShowMoveDialog,
  closeMoveDialog,
  requestMembers,
  isGroupMove,
}) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const classes = useStyles()
  const [currentExamId, setCurrentExamId] = useState(originalExamId)
  const refresh = useRefresh()
  let filter = {}

  if (!isGroupMove) {
    filter = {
      moduleId
    }
  }

  const { loaded, error, data } = useQueryWithStore({
    type: 'getList',
    resource: isGroupMove ? 'exam-groups' : 'groups',
    payload: {
      filter,
      pagination: {
        perPage: 0
      }
    }
  })

  if (!loaded) { return <Loading /> }
  if (error) { return <Error /> }

  console.log(currentExamId, 'currentExamId')
  console.log(data, 'data')

  const currentExam = getById(data, currentExamId)

  console.log(currentExam, 'currentExam')

  const {
    examDate,
    examTimeStart,
    examTimeEnd,
    modules: { 0: module },
    city: { name: cityName },
    location,
    level: {
      name: levelName
    },
  } = currentExam.exam || currentExam

  const moveExam = async () => {
    try {
      let response
      if (isGroupMove) {
        response = await dataProvider.create('exam-groups/move',
          {
            data: {
              fromId: originalExamId,
              toId: currentExamId,
              members: moveMemberIdList
            }
          })
      } else {
        response = await dataProvider.create('group-member/move',
          {
            data: {
              fromId: originalExamId,
              toId: currentExamId,
              applications: moveMemberIdList
            }
          })
      }

      if (response) {
        window.location.reload(true)
        if (requestMembers) requestMembers(originalExamId)
        closeMoveDialog()
        notify('Претенденты перенесены на другой экзамен')
      }
    } catch (err) {
      notify('Не удалось перенести', 'warning')
    }
  }

  return (
    <Dialog
      open={isShowMoveDialog}
      onClose={closeMoveDialog}
    >
      <DialogContent className={classes.dialog}>
        <DialogTitle>
          Название группы
        </DialogTitle>
        <Form
          onSubmit={() => { }}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className={classes.form}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SelectInput
                    source="id"
                    choices={data.length && data}
                    label="Название группы"
                    defaultValue={originalExamId}
                    onChange={elem => setCurrentExamId(elem.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    label="Уровень"
                    source="name"
                    value={levelName}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextInput
                    label="Модуль"
                    source="module"
                    value={module.name}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextInput
                    label="Дата экзамена"
                    source="examDate"
                    value={examDate}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    label="Время начала"
                    source="examTimeStart"
                    value={examTimeStart || ''}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    label="Время окончания"
                    source="Время окончания"
                    value={examTimeEnd || ''}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <TextInput
                    label="Город"
                    source="Время окончания"
                    value={cityName}
                    disabled
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextInput
                    label="Город"
                    source="Время окончания"
                    value={(location && location.address) || ''}
                    disabled
                  />
                </Grid>
              </Grid>
            </form>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeMoveDialog}>
          Отмена
        </Button>
        <Button color="primary" onClick={moveExam}>
          Перенести
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExamMoveDialog
