import React from 'react'
import TextField from '@material-ui/core/TextField'

const CustomTextInput = ({
  meta: { touched, error },
  input,
  ...props
}) => (
  <TextField
    {...input}
    {...props}
    error={!!(touched && error)}
    helperText={touched && error}
    onChange={value => input.onChange(value)}
  />
)


export default CustomTextInput
