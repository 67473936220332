import React from 'react'

import {
  Filter,
  ReferenceInput,
  SelectInput,
  AutocompleteInput
} from 'react-admin'

import examStatusList from 'utils/examStatusList'

const ExamGroupFilter = ({ isAdmin, levelId, ...props }) => (
  <Filter {...props}>
    <ReferenceInput filter={{ levelId: levelId }} label="По модулю" source="moduleId" reference="modules">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="По Городу" source="cityId" reference="cities" filterToQuery={searchText => ({ name: searchText })}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    {isAdmin && (
      <SelectInput
        source="statusId"
        choices={examStatusList}
        label="По статусу"
      />
    )}
  </Filter>
)

export default ExamGroupFilter
