import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  main: {
    padding: '0 0 16px 24px',
  }
})
const PageSubtitle = ({ title, className }) => {
  const classes = useStyles()

  return (
    <Typography
      variant="h4"
      className={`${classes.main} ${className}`}
    >
      {title}
    </Typography>
  )
}

export default PageSubtitle
