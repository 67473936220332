import React from 'react'
import { Show, useQueryWithStore } from 'react-admin'
import { PageTitle } from 'common'

import ApplicationsMain from 'components/applications/ApplicationsMain'

import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

import getById from 'utils/getById'

const useStyles = makeStyles({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '16px 0'
  }
})

const ApplicationsView = ({ record }) => {
  const {
    statusId,
    type: {
      name,
    }
  } = record

  const classes = useStyles()

  const { data: statuses } = useQueryWithStore({
    type: 'getList',
    resource: 'application-statuses'
  })

  const {
    id: currentStatusId,
    name: currentStatusName
  } = statuses ? getById(statuses, statusId) : {}

  const getName = title => {
    let nameForm
    switch (title) {
      case 'Регистрация':
        nameForm = 'регистрацию'
        break
      default:
        nameForm = title
    }
    return nameForm
  }


  return (
    <>
      <div className={classes.buttonsWrapper}>
        <PageTitle title={`Заявка на ${getName(name)} `} />
        <Chip
          label={currentStatusName}
        />
      </div>
      <ApplicationsMain
        record={record}
        currentStatusId={currentStatusId}
      />
    </>
  )
}

const ApplicationsShow = props => (
  <>
    <Show
      component="div"
      title=" "
      {...props}
    >
      <ApplicationsView />
    </Show>
  </>
)

export default ApplicationsShow
