import React from 'react'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

const FormattedDateField = ({ date, formatting }) => {
  const changedDate = new Date(date.replace(/-/g, '/')) // для safari, чтобы был правильный формат даты
  const formattedDate = format(changedDate, formatting, { locale: ru })
  return (
    <span>{formattedDate}</span>
  )
}

export default FormattedDateField
