import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles({
  divider: {
    marginTop: 20,
    marginBottom: 20
  },
  buttonsWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const ButtonBlock = ({ handleClickAccept, handleClickCancel, disabled }) => {
  const classes = useStyles()

  return (
    <>
      <Divider className={classes.divider} />
      <div className={classes.buttonsWrap}>
        <Button
          color="primary"
          onClick={handleClickCancel}
          variant="outlined"
        >
          Назад
        </Button>
        <Button
          color="primary"
          onClick={handleClickAccept}
          variant="contained"
          disabled={disabled}
        >
          Сохранить
        </Button>
      </div>
    </>
  )
}

export default ButtonBlock
