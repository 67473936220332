import React, { useState } from 'react'
import { useQueryWithStore, useNotify, useMutation } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import FormShowEducations from './FormShowEducations'
import FormEditEducations from '../profile-editing/FormEditEducations'

const useStyles = makeStyles({
  paper: {
    padding: 24,
    position: 'relative',
  },
  eduTitle: {
    paddingLeft: 34,
    paddingBottom: 20
  },
})

const ProfileEducations = ({
  editable,
  educations,
  id,
}) => {
  const classes = useStyles()
  const notify = useNotify()
  const [mutate] = useMutation()
  const [educationsData, setEducationsData] = useState(educations)
  const [isEditable, setIsEditable] = useState(false)
  const [loading, setLoading] = useState(false)

  const { data: qualifications } = useQueryWithStore({
    type: 'getList',
    resource: 'qualifications'
  })

  const onSubmit = async values => {
    setLoading(true)
    mutate({
      type: 'update',
      resource: `applicant/update-account/${id}`,
      payload: { data: values }
    },
      {
        onSuccess: ({
          data: {
            applicant: {
              educations: responseEducations
            }
          }
        }) => {
          setLoading(false)
          setEducationsData(responseEducations)
          notify('Данные сохранены')
          setIsEditable(false)
        },
        onFailure: error => {
          setLoading(false)
          notify(`Ошибка: ${error.message}`, 'warning')
        }
      })
  }

  return (
    <>
      <Typography
        variant="h6"
        className={classes.eduTitle}
      >
        Сведения о высшем профессиональном образовании (как указано в дипломе)
      </Typography>
      <Paper className={classes.paper}>
        {!educationsData.length && (<p>Сведения отсутствуют</p>)}
        {isEditable
          ? (
            <FormEditEducations
              educations={educationsData}
              onSubmit={onSubmit}
              qualifications={qualifications}
              setIsEditable={setIsEditable}
              loading={loading}
              setLoading={setLoading}
            />
          )
          : (
            <FormShowEducations
              editable={editable}
              educations={educationsData}
              qualifications={qualifications}
              setIsEditable={setIsEditable}
            />
          )}
      </Paper>
    </>
  )
}

export default ProfileEducations
