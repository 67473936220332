import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { MuiCustomTextField as TextField } from 'common'
import formatDate from 'utils/formatDate'

const useStyles = makeStyles({
  content: {
    width: 700,
    paddingBottom: 20
  },
  textfield: {
    display: 'block',
    marginBottom: 20,
    marginRight: 20
  },
  row: {
    display: 'flex',
    width: '100%'
  },
  name: {
    width: 450
  },
  module: {
    marginRight: 0,
    flexGrow: 1
  },
  level: {
    width: 330
  },
  time: {
    width: 150
  },
  location: {
    width: 400
  },
  divider: {
    marginTop: 10,
    marginBottom: 25,
    border: 0,
    borderTop: '1px solid #E0E0E0'
  }
})

const ScheduledExamInfo = ({ open, onClose, record }) => {
  const classes = useStyles()
  const {
    name, examDate, examTimeStart, examTimeEnd, level, city, location, modules
  } = record

  const address = location?.address ? location.address : ''
  const room = location?.room ? `, ауд. ${location.room}` : ''

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogTitle>Информация об экзамене</DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          value={name}
          label="Название группы"
          className={`${classes.textfield} ${classes.name}`}
        />
        <div className={classes.row}>
          <TextField
            value={level.name}
            label="Уровень"
            className={`${classes.textfield} ${classes.level}`}
          />
          <TextField
            value={modules[0].name}
            label="Модуль"
            className={`${classes.textfield} ${classes.module}`}
            fullWidth
            multiline
          />
        </div>
        <div className={classes.row}>
          <TextField
            value={examDate ? formatDate(examDate, 'dd MMMM yyyy') : ''}
            label="Дата экзамена"
            className={`${classes.textfield} ${classes.time}`}
          />
          <TextField
            value={examTimeStart ? examTimeStart.slice(0, -3) : 'Не определено'}
            label="Время начала"
            className={`${classes.textfield} ${classes.time}`}
          />
          <TextField
            value={examTimeEnd ? examTimeEnd.slice(0, -3) : 'Не определено'}
            label="Время окончания"
            className={`${classes.textfield} ${classes.time}`}
          />
        </div>
        <div className={classes.row}>
          <TextField
            value={city.name}
            label="Город"
            className={`${classes.textfield} ${classes.city}`}
          />
          <TextField
            value={`${address}${room}`}
            label="Помещение"
            className={`${classes.textfield} ${classes.location}`}
          />
        </div>
        <hr className={classes.divider} />
        <Button
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          Закрыть
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default ScheduledExamInfo
