import React from 'react'

import {
  usePermissions, List, Datagrid, TextField, FunctionField, useAuthenticated, Pagination
} from 'react-admin'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

import { makeStyles } from '@material-ui/core/styles'
import CreateIcon from '@material-ui/icons/Create'
import IconButton from '@material-ui/core/IconButton'

import { Empty } from 'common'

import examStatusList from 'utils/examStatusList'
import ExamGroupFilter from './ExamGroupFilter'


const useStyles = makeStyles({
  textBold: {
    fontWeight: 'bold',
  },
  textGreen: {
    color: 'rgba(56, 173, 89, 0.87)',
  },
  textRed: {
    color: 'rgba(255, 0, 0, 0.87)',
  },
  textGray: {
    color: 'rgba(0, 0, 0, 0.56)',
  },
  editCell: {
    padding: 0,
  },
  tableHeader: {
    paddingTop: 20,
    paddingBottom: 20,
    color: 'rgba(0, 0, 0, 0.56)'
  },
  tableRow: {
    paddingTop: 16,
    paddingBottom: 16,
  }
})

const ExamGroupTable = ({ levelId, ...props }) => {
  useAuthenticated()
  const history = useHistory()
  const { permissions: { role } = {} } = usePermissions()
  const isAdmin = role === 'admin' || role === 'manager'
  const classes = useStyles()

  const handleEditExam = id => {
    let url = ' '

    if (isAdmin) {
      url = `/exam-creation/${id}`
    } else {
      url = `/exam-booking/${id}`
    }

    history.push({
      pathname: url
    })
  }

  const getDateTime = (examDate, examTimeStart) => {
    const date = format(new Date(examDate), 'dd.MM.yyyy', { locale: ru })
    if (examTimeStart) {
      const time = examTimeStart && examTimeStart.slice(0, -3)
      return `${date} в ${examTimeStart && time}`
    }
    return date
  }

  const GroupPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;

  return (
    <List
      {...props}
      pagination={<GroupPagination />}
      bulkActionButtons={false}
      exporter={false}
      perPage={50}
      filters={<ExamGroupFilter isAdmin={isAdmin} levelId={levelId} />}
      title="Список групп"
      empty={<Empty title="Экзамены отсутствуют" />}
    >
      <Datagrid classes={{ headerCell: classes.tableHeader, rowCell: classes.tableRow }}>
        <TextField
          source="modules[0].name"
          label="Модуль"
          sortBy="moduleId"
        />
        <FunctionField
          source="members"
          label="Количество мест"
          render={({ countMembers, location }) => {
            let countPlaces = 'Не определено'
            if (location) countPlaces = location.countPlaces
            return (<>{`${countMembers} / ${countPlaces}`}</>)
          }}
          sortable={false}
        />
        <FunctionField
          source="examDate"
          label="Дата экзамена"
          render={({ examDate, examTimeStart }) => getDateTime(examDate, examTimeStart)}
        />
        <TextField
          source="city.name"
          label="Город"
        />
        <TextField
          source={isAdmin ? 'location.place' : 'location.address'}
          label="Помещение"
          sortable={false}
        />
        {isAdmin && (
          <FunctionField
            source="statusId"
            label="Статус"
            render={({ statusId }) => {
              const { 0: { name } } = examStatusList.filter(({ id }) => id === statusId)
              return name
            }}
          />
        )}
        <FunctionField
          source={false}
          label=""
          render={({ id }) => (
            role !== 'viewer' && (
              <IconButton onClick={() => handleEditExam(id)}>
                <CreateIcon color="primary" />
              </IconButton>
            )
          )}
          sortable={false}
        />
      </Datagrid>
    </List>

  )
}

export default ExamGroupTable
