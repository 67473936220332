import React from 'react'

const blue = 'rgba(0, 56, 255, 0.87)'
const gray = 'rgba(0, 0, 0, 0.87)'
const green = 'rgba(53, 167, 0, 0.87)'
const red = 'rgba(255, 0, 0, 0.87)'

const Status = ({ status }) => {
  let color
  let text

  switch (status) {
    case 'active':
      text = 'Активный'
      color = green
      break
    case 'inactive':
      text = 'Не активный'
      color = red
      break
    case 'verify':
      text = 'Не подтвержденный'
      color = blue
      break
    case 'new':
      text = 'Новый'
      color = gray
      break
    case 'approve':
      text = 'принятое'
      color = green
      break
    case 'cancel':
      text = 'отклоненное'
      color = red
      break
    case 'exam':
      text = 'назначен экзамен'
      color = blue
      break
    case 'Подтверждена':
      text = 'Подтверждена'
      color = green
      break
    case 'Не подтверждена':
      text = 'Не подтверждена'
      color = blue
      break
    case 'Отклонена':
      text = 'Отклонена'
      color = red
      break
    case 'На оплату':
      text = 'На оплату'
      color = blue
      break
    default: return null
  }

  return <span style={{ color: `${color}` }}>{text}</span>
}

export default Status
