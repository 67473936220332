import React from 'react'
import { Notification } from 'react-admin'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { makeStyles, createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import eakTheme from 'assets/styles/eakTheme'
import logoImage from 'assets/images/logo.svg'

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FAFAFA'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 378,
    height: 535,
  },
  title: {
    marginBottom: 20,
    color: '#333',
    fontSize: '36px',
    lineHeight: '36px',
    width: '100%',
    paddingLeft: 82
  },
  logo: {
    margin: '0 0 22px -175px',
  },
})

const FormWrapper = ({ title, children }) => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={createMuiTheme(eakTheme)}>
      <div className={classes.main}>
        <img className={classes.logo} src={logoImage} alt="logo" />
        <Card className={classes.card}>
          <Typography
            align="left"
            variant="h4"
            className={classes.title}
          >
            {title}
          </Typography>
          {children}
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  )
}

export default FormWrapper
