import React, { useState } from 'react'
import {
  maxLength,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin'
import { Form, Field } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import ruLocale from 'date-fns/locale/ru'
import validate from 'utils/validate'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import Paper from '@material-ui/core/Paper'
import { CustomTimePicker, CustomDatePicker } from 'common'
import examStatusList from 'utils/examStatusList'
import ExamDeleteDialog from './ExamDeleteDialog'
import RuLocalizedUtils from 'utils/dateFnsLocalization'

const useStyles = makeStyles({
  paper: {
    position: 'relative',
    padding: 24
  },
  wrap: {
    display: 'flex',
  },
  wrapInput: {
    display: 'flex',
    marginTop: '8px',
    marginBottom: '4px'
  },
  fieldLong: {
    width: 600,
    marginRight: 20,
  },
  fieldMiddle: {
    width: 300,
    marginRight: 20,
  },
  fieldShort: {
    width: 200,
    marginRight: 20,
  },
  buttonCreate: {
    position: 'absolute',
    right: 20,
    bottom: 18,
  },
  buttonDelete: {
    position: 'absolute',
    right: 20,
    top: 34,
  },
  dialog: {
    width: '300px'
  },
})

const ExamCreationForm = ({
  examData: {
    id,
    name,
    levelId,
    moduleId,
    examDate,
    examTimeStart,
    examTimeEnd,
    cityId,
    locationId,
    statusId
  } = {},
  onSubmitForm,
}) => {
  const classes = useStyles()
  const notify = useNotify()
  const history = useHistory()
  const dataProvider = useDataProvider()
  const [isOpenDialog, setIsOpenDialog] = useState(false)

  const handleOpenDialog = () => setIsOpenDialog(true)

  const handleCloseDialog = () => setIsOpenDialog(false)

  const deleteExam = async () => {
    await dataProvider.delete(`groups/${id}`, { data: null })
    history.goBack()
  }

  const required = Yup.string().required('Обязательное поле')
  const nullable = Yup.object().nullable()

  const schema = Yup.object().shape({
    name: nullable,
    levelId: required,
    moduleId: required,
    cityId: required,
    locationId: nullable,
    examDate: required,
    examTimeStart: nullable,
    examTimeEnd: nullable,
  })

  const onSubmit = values => onSubmitForm(values)

  const handleClickLocations = currentCityId => {
    if (!currentCityId) {
      notify('Выберите Город', 'warning')
    }
  }

  const handleClickModules = currentLevelId => {
    if (!currentLevelId) {
      notify('Сначала выберите Уровень', 'warning')
    }
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={values => validate(values, schema)}
        mutators={{
          clearModule: (args, state, utils) => {
            utils.changeValue(state, 'moduleId', () => '')
          },
          clearLocation: (args, state, utils) => {
            utils.changeValue(state, 'locationId', () => '')
          },
        }}
        render={({
          form,
          handleSubmit,
          values: {
            levelId: currentLevelId,
            cityId: currentCityId
          }
        }) => (
            <form onSubmit={handleSubmit} >
              <Paper className={classes.paper}>
                <TextInput
                  className={classes.fieldLong}
                  label="Название группы"
                  source="name"
                  validate={maxLength(128, 'Максимальная длина 128 символов')}
                  defaultValue={name}
                  autoComplete="off"
                />
                <div className={classes.wrap}>
                  <ReferenceInput
                    className={classes.fieldMiddle}
                    defaultValue={levelId}
                    label="Уровень"
                    reference="exam-levels"
                    sort={{ field: 'id', order: 'ASC' }}
                    source="levelId"
                    onChange={() => {
                      form.mutators.clearModule()
                    }}
                  >
                    <SelectInput />
                  </ReferenceInput>
                  <ReferenceInput
                    className={classes.fieldLong}
                    defaultValue={moduleId}
                    label="Модуль"
                    reference="modules"
                    source="moduleId"
                    filter={{ levelId: currentLevelId }}
                    disabled={!currentLevelId}
                    onClick={() => handleClickModules(currentLevelId)}
                  >
                    <SelectInput />
                  </ReferenceInput>
                </div>
                <div className={classes.wrapInput}>
                  <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
                    <Field
                      className={classes.fieldShort}
                      label="Дата экзамена"
                      name="examDate"
                      defaultValue={examDate}
                      component={CustomDatePicker}
                    />
                    <Field
                      className={classes.fieldShort}
                      label="Время начала"
                      name="examTimeStart"
                      component={CustomTimePicker}
                      defaultValue={examTimeStart}
                    />
                    <Field
                      className={classes.fieldShort}
                      label="Время окончания"
                      name="examTimeEnd"
                      component={CustomTimePicker}
                      defaultValue={examTimeEnd}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className={classes.wrap}>
                  <div className={classes.fieldMiddle}>
                    <ReferenceInput
                      className={classes.fieldMiddle}
                      defaultValue={cityId}
                      label="Город"
                      reference="cities"
                      filter={{ id: cityId }}
                      source="cityId"
                      filterToQuery={searchText => ({ name: searchText })}
                      onChange={() => {
                        form.mutators.clearLocation()
                      }}
                    >
                      <AutocompleteInput className={classes.fieldMiddle} optionText="name" />
                    </ReferenceInput>
                  </div>
                  <ReferenceInput
                    className={classes.fieldMiddle}
                    defaultValue={locationId}
                    label="Помещение"
                    reference="locations"
                    source="locationId"
                    filter={{ cityId: currentCityId }}
                    disabled={!currentCityId}
                    onClick={() => handleClickLocations(currentCityId)}
                  >
                    <SelectInput optionText="place" />
                  </ReferenceInput>
                  <SelectInput
                    className={classes.fieldShort}
                    source="statusId"
                    choices={examStatusList}
                    label="Статус"
                    defaultValue={statusId}
                  />
                </div>
                <Button
                  className={classes.buttonCreate}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {id ? 'Сохранить' : 'Создать экзамен'}
                </Button>
                {id && (
                  <Button
                    className={classes.buttonDelete}
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={handleOpenDialog}
                  >
                    Удалить экзамен
                  </Button>
                )}
              </Paper>
            </form>
          )}
      />
      {isOpenDialog && (
        <ExamDeleteDialog
          isOpenDialog={isOpenDialog}
          handleCloseDialog={handleCloseDialog}
          className={classes.dialog}
          deleteExam={deleteExam}
        />
      )}
    </>
  )
}

export default ExamCreationForm
