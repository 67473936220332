import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

import Typography from '@material-ui/core/Typography'

import ExamApplicantsTable from './ExamApplicantsTable'
import ExamMembersTable from './ExamMembersTable'
import ExamTableControl from './ExamTableControl'
import ExamMoveDialog from './ExamMoveDialog'


const useStyles = makeStyles({
  delete: {
    cursor: 'pointer',
  },
  textBold: {
    fontWeight: 'bold',
  },
  textBoldDisabled: {
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.36)'
  },
  title: {
    margin: '15px 0 0 24px',
  },
  subtitle: {
    margin: '8px 0 8px 24px',
  },
  buttonEdit: {
    marginTop: 20,
  },
  button: {
    marginTop: 20,
    marginLeft: 20,
  },
  textRed: {
    color: '#F44336',
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between'
  },
})

const ExamTable = ({
  applicants,
  countPlaces,
  examId,
  members,
  totalMembers,
  requestChangeMembers,
  requestMembersSort,
  requestApplicantsSort,
  requestMembers,
  moduleId
}) => {
  const classes = useStyles()

  const [moveMemberIdList, setMoveMemberIdList] = useState([])
  const [isMove, setIsMove] = useState(false)
  const [isShowMoveDialog, setIsShowMoveDialogg] = useState(false)

  const [isEditable, setIsEditable] = useState(false)
  const [deleteList, setDeleteList] = useState([])
  const [addList, setAddList] = useState([])
  const membersCount = members.length
  const moveMemberListCount = moveMemberIdList.length

  const handleClickEdit = () => setIsEditable(true)
  const showMoveGroup = () => setIsShowMoveDialogg(true)
  const closeMoveDialog = () => setIsShowMoveDialogg(false)

  const showMove = () => setIsMove(true)

  const handleClickCancel = () => {
    setAddList([])
    setDeleteList([])
    setIsEditable(false)
    setIsMove(false)
  }

  const handleFormAGroup = async () => {
    if (addList.length) {
      await requestChangeMembers(addList, 'create')
    }
    if (deleteList.length) {
      await requestChangeMembers(deleteList, 'delete')
    }
    setAddList([])
    setDeleteList([])
    return setIsEditable(false)
  }

  const getFreePlace = () => {
    if (countPlaces) {
      const freePlace = countPlaces - members.length
      return `Свободных мест ${freePlace} из ${countPlaces}`
    }
    return null
  }

  return (
    <div>
      <Typography className={classes.title} variant="h4">
        Претенденты на экзамен
      </Typography>
      <Typography className={classes.subtitle} gutterBottom>
        {getFreePlace()}
      </Typography>
      <Paper>
        {isEditable ? (
          <ExamApplicantsTable
            applicants={applicants}
            requestApplicantsSort={requestApplicantsSort}
            totalMembers={totalMembers}
            members={members}
            deleteList={deleteList}
            addList={addList}
            setDeleteList={setDeleteList}
            setAddList={setAddList}
            isEditable={isEditable}
          />
        ) : (
            <ExamMembersTable
              applicants={applicants}
              members={members}
              totalMembers={totalMembers}
              examId={examId}
              isMove={isMove}
              moveMemberIdList={moveMemberIdList}
              setMoveMemberIdList={setMoveMemberIdList}
              requestMembersSort={requestMembersSort}
            />
          )}
      </Paper>

      {isShowMoveDialog && (
        <ExamMoveDialog
          isShowMoveDialog={isShowMoveDialog}
          moduleId={moduleId}
          originalExamId={examId}
          closeMoveDialog={closeMoveDialog}
          moveMemberIdList={moveMemberIdList}
          requestMembers={requestMembers}
        />
      )}

      <ExamTableControl
        isEditable={isEditable}
        isMove={isMove}
        handleClickCancel={handleClickCancel}
        handleFormAGroup={handleFormAGroup}
        handleMoveGroup={showMoveGroup}
        handleClickEdit={handleClickEdit}
        setIsMove={showMove}
        membersCount={membersCount}
        moveMemberListCount={moveMemberListCount}
      />
    </div>
  )
}

export default ExamTable
