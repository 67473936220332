import React from 'react'
import { Route } from 'react-router-dom'

import {
  ApplicantPage,
  AutoPassPage,
  GroupApplicationPage,
  ExamBookingPage,
  ExamCreationPage,
  ExamSchedulePage,
  ProfileEditingPage,
  ProfilePage,
  RecordBookPage,
  RegisterPage,
  ResetPassword,
  ReportsPage
} from './pages'

export default [
  <Route
    exact
    path="/register"
    component={RegisterPage}
    noLayout
  />,
  <Route
    path="/profile"
    component={ProfilePage}
  />,
  <Route
    path="/applicant-form/:applicantId?"
    component={ApplicantPage}
  />,
  <Route
    exact
    path="/record-book/:userId?"
    component={RecordBookPage}
  />,
  <Route
    path="/autopass"
    component={AutoPassPage}
  />,
  <Route
    exact
    path="/exam-schedule"
    component={ExamSchedulePage}
  />,
  <Route
    exact
    path="/group-application/:groupId"
    component={GroupApplicationPage}
  />,
  <Route
    path="/exam-booking/:examId"
    component={ExamBookingPage}
  />,
  <Route
    path="/exam-creation/:examId?"
    component={ExamCreationPage}
  />,
  <Route
    path="/reset-password"
    component={ResetPassword}
    noLayout
  />,
  <Route
    path="/user/:id"
    component={ProfileEditingPage}
  />,
  <Route
    path="/reports"
    component={ReportsPage}
  />
]
