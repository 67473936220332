import React from 'react'
import Chip from '@material-ui/core/Chip'

const statusTypes = {
  new: {
    name: 'На рассмотрении',
    color: 'rgba(255, 230, 0, 0.3)'
  },
  cancel: {
    name: 'Отклонена',
    color: 'rgba(228, 81, 61, 0.4)'
  },
  approve: {
    name: 'Подтверждена',
    color: 'rgba(56, 173, 89, 0.3)'
  },
  'not-payment': {
    name: 'Ожидается оплата',
    color: 'rgba(255, 230, 0, 0.3)'
  },
  empty: {
    name: 'Доступен',
    color: 'rgba(255, 230, 0, 0.3)'
  },
  success: {
    name: 'Сдан',
    color: 'rgba(56, 173, 89, 0.3)'
  },
  fail: {
    name: 'Не сдан',
    color: 'rgba(228, 81, 61, 0.4)'
  },
  exam: {
    name: 'Экзамен',
    color: 'rgba(56, 173, 89, 0.3)'
  },
  disable: {
    name: 'Не доступен',
    color: 'rgba(160, 160, 160, 0.4)'
  },
  payment: {
    name: 'Оплачено',
    color: 'rgba(52, 204, 235, 0.4)'
  },
  'not-appear': {
    name: 'Не явка',
    color: 'rgba(52, 204, 235, 0.4)'
  }
}

const StatusChip = ({ status, data = false }) => {
  const currentStatus = statusTypes[status]

  if (!currentStatus) return null

  return (
    <Chip
      label={`${currentStatus.name} ${data || ''}`}
      style={{
        backgroundColor: currentStatus.color,
        fontSize: '14px',
        lineHeight: '20px'
      }}
    />
  )
}

export default StatusChip
