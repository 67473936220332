import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  title: {
    padding: '16px 0 16px 24px',
  },
})

const Empty = ({ title }) => {
  const classes = useStyles()

  return (
    <Paper>
      <Typography
        className={classes.title}
        variant="h6"
      >
        {title || 'Данные отсутствуют'}
      </Typography>
    </Paper>
  )
}

export default Empty
