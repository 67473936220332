import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles({
  root: {
    display: 'block',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.09)'
    }
  },
  textField: {
    marginBottom: 20
  },
})

const MuiCustomTextField = ({
  value, label, className, ...props
}) => {
  const classes = useStyles()

  return (
    <TextField
      label={label}
      value={value || ''}
      className={`${classes.textField} ${className}`}
      variant="filled"
      InputProps={{
        disableUnderline: true,
        readOnly: true,
        classes: { root: classes.root },
      }}
      InputLabelProps={{ focused: false }}
      {...props}
    />
  )
}

export default MuiCustomTextField
