import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  textBold: {
    fontWeight: 'bold',
  },
})

const FullName = ({ data }) => {
  const classes = useStyles()

  if (data.applicant) {
    const {
      applicant: {
        user: {
          surname,
          name,
          middleName
        }
      }
    } = data

    return <span className={classes.textBold}>{`${surname} ${name} ${middleName}`}</span>
  }

  return <span>Нет данных</span>
}

export default FullName
