import React from 'react'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'

const useStyles = makeStyles({
  field: {
    marginBottom: 4,
    '& > label': {
      top: -3,
    }
  },
  input: {
    height: 48,
  }
})

const CustomDatePicker = props => {
  const classes = useStyles()

  const {
    input: {
      name,
      onChange,
      value,
    },
    onChangeField,
    meta,
    ...rest
  } = props
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched

  const handleOnChange = e => {
    const formatDate = format(e, 'yyyy-MM-dd hh:mm:ss')
    onChange(formatDate)
    // onChangeField()
  }

  return (
    <DatePicker
      className={classes.field}
      inputVariant="filled"
      {...rest}
      format="dd.MM.yyyy"
      cancelLabel="Отмена"
      name={name}
      helperText={showError ? meta.error || meta.submitError : undefined}
      error={showError}
      onChange={e => handleOnChange(e)}
      value={value === '' ? null : value}
      InputProps={{ className: classes.input }}
    />
  )
}

export default CustomDatePicker
