import request from './request'

const authProvider = {
  login: async ({ email, password }) => {
    try {
      const response = await request.send('POST', '/api/app/site/login', {
        email,
        password,
      })

      const {
        data: {
          id,
          accessToken,
          role,
          status,
          applicant,
          name,
          middleName,
          surname
        }
      } = response
      localStorage.setItem('userId', id)
      localStorage.setItem('token', accessToken)
      localStorage.setItem('role', role)
      localStorage.setItem('status', status)
      localStorage.setItem('applicant', !!applicant)
      localStorage.setItem('userName', `${name} ${middleName} ${surname}`)
      if (applicant) {
        const { status: applicantStatus } = applicant
        localStorage.setItem('applicantStatus', applicantStatus)
      }

      request.setToken(accessToken)

      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  },
  logout: () => {
    request.removeToken()
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      request.removeToken()
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const check = !!localStorage.getItem('token')
    return check ? Promise.resolve() : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = localStorage.getItem('role')
    const userId = localStorage.getItem('userId')
    const status = localStorage.getItem('status')
    const applicant = localStorage.getItem('applicant') === 'true'
    const applicantStatus = localStorage.getItem('applicantStatus')

    return Promise.resolve({
      role, status, applicant, applicantStatus, userId
    })
  }
}

export default authProvider
