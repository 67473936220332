import React, { useState } from 'react'

import {
  useMutation,
  ReferenceInput,
  TextInput,
  SelectInput,
  useQueryWithStore,
  required,
  useNotify,
  useDataProvider,
  AutocompleteInput,
} from 'react-admin'

import Paper from '@material-ui/core/Paper'
import { MuiCustomTextField as TextField } from 'common'
import { makeStyles } from '@material-ui/core/styles'
import formatDate from 'utils/formatDate'
import { Form } from 'react-final-form'
import EditIcon from '@material-ui/icons/Edit'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import ExamMoveDialog from '../exam-creation/ExamMoveDialog'

const useStyles = makeStyles({
  paper: {
    padding: 20
  },
  textfield: {
    display: 'block',
    marginBottom: 20,
    marginRight: 20
  },
  subtitle: {
    padding: 24
  },
  groupTitle: {
    width: 429
  },
  row: {
    display: 'flex',
    alignItems: 'start'
  },
  name: {
    width: 409,
    marginTop: 0,
  },
  nameInput: {
    paddingBottom: 10
  },
  module: {
    width: 475
  },
  level: {
    marginRight: 20,
    minWidth: 409
  },
  details: {
    justifyContent: 'space-between'
  },
  address: {
    minWidth: 250
  },
  time: {
    width: 195
  },
  date: {
    width: 195
  },
  buttonRow: {
    alignItems: 'flex-end'
  },
  select: {
    width: 409,
    marginRight: 285,
    marginTop: 0,
    marginBottom: 0
  },
  buttonWrap: {
    marginTop: '20px'
  },
  modalWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none'
  },
  modal: {
    position: 'absolute',
    width: 400,
    margin: 'auto',
    border: '2px solid #000',
  },
})

const useStyles2 = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none'
  },
}))

const GroupMainInfo = ({
  record,
  isShowMoveDialog,
  setIsShowMoveDialog,
  aplicantsList,
}) => {
  const {
    name, exam, modules, supervisorId, id
  } = record
  const classes = useStyles()
  const classes2 = useStyles2()
  const [mutate, { loading }] = useMutation()
  const notify = useNotify()
  const [isShowClone, setIsShowClone] = useState(false)
  const [formValues, setFormValues] = useState({
    name,
    supervisorId
  })
  const dataProvider = useDataProvider()
  const examTimes = exam?.examTimeStart && exam?.examTimeEnd ? `${exam.examTimeStart.slice(0, -3)} - ${exam.examTimeEnd.slice(0, -3)}` : 'Не определено'
  const modulesNames = modules ? modules.map(item => (item.name)).join('\n') : ''
  const address = exam?.location?.address ? exam.location.address : ''
  const room = exam?.location?.room ? `, ауд. ${exam.location.room}` : ''

  const { data: supervisors } = useQueryWithStore({
    type: 'getList',
    resource: 'users',
    payload: {
      filter: { role: 'supervisor' },
      pagination: { perPage: 150 }
    }
  })

  const editGroup = values => {
    mutate({
      type: 'update',
      resource: 'exam-groups',
      payload: { id, data: { ...values } }
    },
      {
        onSuccess: () => {
          notify('Данные сохранены')
          setFormValues(values)
        },
        onFailure: err => {
          notify(err.message.data[0].message, 'warning')
        }
      })
  }

  const handleReport = async () => {
    const response = await dataProvider.create(`exam-groups/${id}/print`, {
      data: {}
    })
    window.open(response.data.url, '_blank')
  }

  const reportExam = async () => {
    const response = await dataProvider.create(`exam-groups/${id}/print`, {
      data: {
        type: 'result'
      }
    })
    window.open(response.data.url, '_blank')
  }

  const publishResult = async () => {
    try {
      const response = await dataProvider.create(`exam-groups/${id}/public-marks`, {
        data: {}
      })
      notify('Результат опубликован', 'info')
    } catch (e) {
      notify('Ошибка', 'error')
    }
  }

  return (
    <Paper className={classes.paper}>

      {isShowMoveDialog && (
        <ExamMoveDialog
          isShowMoveDialog={isShowMoveDialog}
          moduleId={exam.moduleId}
          originalExamId={id}
          closeMoveDialog={() => { setIsShowMoveDialog(false) }}
          moveMemberIdList={aplicantsList}
          isGroupMove
        />
      )}
      {isShowClone && (
        <Modal
          open={isShowClone}
          className={classes2.modal}
        >
          <div className={classes2.paper}>
            <DialogTitle>Клонирование группы</DialogTitle>
            <Form
              onSubmit={values => {
                let data = {}
                data.name = values.name
                data.locationId = values.locationId

                mutate({
                  type: 'create',
                  resource: `exam-groups/${id}/clone`,
                  payload: { data }
                },
                  {
                    onSuccess: () => {
                      setIsShowClone(false)
                    },
                  })
              }}

              render={({ handleSubmit }) => (
                <form onSubmit={() => { }}>
                  <DialogContent>
                    <div style={{ display: 'flex' }}>
                      <TextInput
                        source="name"
                        initialValue={name}
                        style={{ marginRight: 20 }}
                      />
                      <ReferenceInput
                        label="Помещение"
                        reference="locations"
                        source="locationId"
                        perPage={10}
                      >
                        <AutocompleteInput className={classes.fieldMiddle} optionText="place" />
                      </ReferenceInput>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={() => setIsShowClone(false)}
                    >
                      {"Отмена"}
                    </Button>
                    <Button
                      color="primary"
                      onClick={handleSubmit}
                    >
                      {"Отправить"}
                    </Button>
                  </DialogActions>
                </form>
              )}
            />
          </div>
        </Modal>
      )}

      <Form
        onSubmit={editGroup}
        initialValues={formValues}
        render={({ handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.row}>
              <div className={classes.groupTitle}>
                <TextInput
                  source="name"
                  initialValue={name}
                  label="Название группы"
                  className={`${classes.textfield} ${classes.name}`}
                  InputProps={{ endAdornment: <EditIcon /> }}
                  margin="normal"
                  validate={required()}
                  fullWidth
                />
                <TextField
                  value={exam ? exam.level.name : ''}
                  label="Уровень"
                  className={`${classes.textfield} ${classes.level}`}
                />
              </div>
              <TextField
                value={modulesNames}
                label="Модуль"
                className={`${classes.textfield} ${classes.module}`}
                multiline
              />
            </div>
            <div className={classes.row}>
              <TextField
                value={exam ? formatDate(exam.examDate, 'dd MMMM yyyy') : ''}
                label="Дата экзамена"
                className={`${classes.textfield} ${classes.date}`}
              />
              <TextField
                value={examTimes}
                label="Время экзамена"
                className={`${classes.textfield} ${classes.time}`}
              />
              <TextField
                value={exam ? exam.city.name : ''}
                label="Город"
                className={`${classes.textfield} ${classes.city}`}
              />
              <TextField
                value={`${address}${room}`}
                label="Помещение"
                className={`${classes.textfield} ${classes.address}`}
                multiline
              />
            </div>
            <div className={`${classes.row} ${classes.buttonRow}`}>
              <SelectInput
                className={classes.select}
                emptyText="Нет"
                allowEmpty
                emptyValue={null}
                initialValue={supervisorId}
                source="supervisorId"
                label="Супервайзер"
                optionValue="id"
                choices={supervisors}
                optionText={choice => `${choice.surname} ${choice.name} ${choice.middleName}`}
                margin="dense"
              />
            </div>
            <div className={classes.buttonWrap}>
              <Button
                variant="outlined"
                color="primary"
                onClick={reportExam}
                style={{ marginLeft: 10, marginBottom: 10 }}
              >
                ПРОТОКОЛ РЕЗУЛЬТАТОВ
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={publishResult}
                style={{ marginLeft: 10, marginBottom: 10 }}
              >
                Опубликовать результаты
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={handleReport}
                style={{ marginLeft: 10, marginBottom: 10 }}
              >
                отчет по допущенным на экзамен
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={handleSubmit}
                disabled={pristine || loading}
                style={{ marginLeft: 10, marginBottom: 10 }}
              >
                Сохранить
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsShowClone(true)}
                style={{ marginLeft: 10, marginBottom: 10 }}
              >
                Клонировать группу
              </Button>
            </div>
          </form>
        )}
      />
    </Paper >
  )
}

export default GroupMainInfo
