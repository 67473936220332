import React from 'react'
import FormWrapper from 'components/FormWrapper'
import RegisterForm from 'components/RegisterForm'

const RegisterPage = () => (
  <FormWrapper title="Регистрация">
    <RegisterForm />
  </FormWrapper>
)

export default RegisterPage
