import { createMuiTheme } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import { ruRU } from '@material-ui/core/locale'

const eakTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#c62828',
      light: '#d15353',
      dark: '#8a1c1c'
    },
    secondary: {
      main: '#2196f3',
      light: '#4dabf5',
      dark: '#1769a'
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.1,
  }
}, ruRU)

export default eakTheme
